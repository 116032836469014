<template>
<ComponentsLayoutPage1>
  <v-row align="center" justify="center" class="mx-auto px-5" :style="{ maxWidth: '720px', paddingTop: '30px', paddingBottom: '50px' }" no-gutters>
    <v-col cols=12>
      <ComponentsLayoutHeading1 text="パスワードの再発行"></ComponentsLayoutHeading1>
    </v-col>
    <v-col cols=12>
      <v-row align="center" justify="center" :style="{ paddingTop: '30px' }" no-gutters>
        <v-col cols=12>
          <span>再設定するパスワードをご入力下さい</span>
        </v-col>
        <v-col cols="12" class="py-5">
          <ComponentsLayoutSection1>
            <v-row align="center" justify="center">
              <v-col cols=12>
                <ComponentsLayoutHeading3 text="パスワード" required></ComponentsLayoutHeading3>
              </v-col>
              <v-col cols="12">
                <ComponentsItemsTextFieldTypePassword
                  name="password"
                  :value.sync="password"
                  :loading="isProcessLoading"
                  autocomplete="current-password"
                  ref="password"
                ></ComponentsItemsTextFieldTypePassword>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutHeading3 text="パスワード(確認)" required></ComponentsLayoutHeading3>
              </v-col>
              <v-col cols="12">
                <ComponentsItemsTextFieldTypePassword
                  name="password"
                  :value.sync="re_password"
                  :loading="isProcessLoading"
                  autocomplete="current-password"
                  ref="re_password"
                ></ComponentsItemsTextFieldTypePassword>
              </v-col>
            </v-row>
          </ComponentsLayoutSection1>
        </v-col>
        <v-col cols="12" class="text-center py-5">
          <ComponentsItemsButton
            block
            text="再設定してログイン画面に戻る"
            @click="ReSetPassword()"
          >
          </ComponentsItemsButton>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</ComponentsLayoutPage1>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutPage1 from '@/components/layout/Page1'
import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsLayoutHeading3 from '@/components/layout/Heading3'

import ComponentsItemsTextFieldTypePassword from '@/components/items/TextFieldTypePassword'
import ComponentsItemsButton from '@/components/items/Button'

export default {

  name: 'ForgetPasswordResetPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false,
    password: '',
    re_password: ''
  }),

  computed: {
    verifyCode: {
      get () {
        return this.$route.query.code
      }
    }
  },

  mounted () {
    if (this.isSignIn) {
      this.pageTransition('/')
    }
  },

  methods: {
    ReSetPassword: function () {
      (async () => {
        let error = false
        const forms = [
          'password',
          're_password'
        ]
        for (const name of forms) {
          if (this.$refs[name]) {
            await this.$refs[name].confirm()
            if (this.$refs[name].error) {
              error = true
            }
          }
        }
        if (error) {
          return false
        }
        if (this.password !== this.re_password) {
          this.$confirm({ title: 'Error', message: 'パスワードが一致していません', button: { yes: 'OK' } })
          return false
        }
        this.isProcessLoading = true
        await APILib.postDealingsResetPassword(this.verifyCode, this.password)
        this.$confirm({ title: 'Success', message: 'パスワードを変更しました', button: { yes: 'OK' } })
        this.pageTransition('/login')
        this.isProcessLoading = false
      })().catch((e) => {
        this.isProcessLoading = false
        this.$confirm({ title: 'Error', message: e, button: { yes: 'OK' } })
      })
    }
  },

  components: {
    ComponentsLayoutPage1,
    ComponentsLayoutSection1,
    ComponentsLayoutHeading1,
    ComponentsLayoutHeading3,
    ComponentsItemsTextFieldTypePassword,
    ComponentsItemsButton
  }
}
</script>
