<template>
<ComponentsLayoutPage1>
  <v-row align="center" justify="center" class="py-5 mx-auto" :style="{ maxWidth: '640px', paddingBottom: '100px' }">

    <v-col cols=12 class="pb-1 font-weight-bold ec-font-size16 text-center R2MainColorLight--text" :style="{ paddingTop: '50px' }">
      <span>ご注文ありがとうございました</span>
    </v-col>

    <v-col cols=12>
      <ComponentsLayoutSection1>
        <v-row align="center" justify="center" no-gutters>
          <v-col cols="12">
            <ComponentsLayoutSection2>
              <v-row align="center" justify="center" no-gutters>
                <v-col cols="12" class="pb-1 font-weight-bold ec-font-size14 text-center">
                  <span>注文番号</span>
                </v-col>
                <v-col cols="12" class="pb-1 font-weight-bold ec-font-size18 text-center R2MainColorLight--text">
                  <span v-text="OrderID"></span>
                </v-col>
              </v-row>
            </ComponentsLayoutSection2>
          </v-col>
          <v-col cols=12 class="pb-3 text-center" :style="{ paddingTop: '30px' }">
            <span>ただいま、ご注文の確認メールをお送りさせていただきました。<br />万一、ご確認メールが届かない場合は、トラブルの可能性もありますので大変お手数ではございますがお問い合わせくださいますようお願いいたします。</span>
          </v-col>
          <v-col cols=12 class="py-3 text-center">
            <v-icon color="R2MainColorLight" left>mdi-phone-in-talk</v-icon><span class="R2MainColorLight--text ec-font-size18 font-weight-black" v-text="Company.tel"></span><span class="caption pl-5" v-text="`FAX: ${Company.fax}`"></span><br />
            <span class="caption">お電話受付：10:00～18:00(土日祝を除く)</span><br />
            <span class="caption">ウェブからのご注文は営業日時外も承っております</span>
          </v-col>
        </v-row>
      </ComponentsLayoutSection1>
    </v-col>

    <v-col cols="8" class="py-5">
      <ComponentsItemsButton
        block
        :loading="isProcessLoading"
        text="TOPに戻る"
        @click="pageTransition('/')"
      >
      </ComponentsItemsButton>
    </v-col>
  </v-row>
</ComponentsLayoutPage1>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutPage1 from '@/components/layout/Page1'
import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsLayoutSection2 from '@/components/layout/Section2'
// import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ShoppingCompletePage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false
  }),

  computed: {
    OrderID: {
      get () {
        return this.$store.getters['modShopping/CommitOrderID']
      }
    },
    Company: {
      get () {
        return this.$store.getters['modStorage/Company']
      }
    }
  },

  mounted: function () {
    if (!this.isSignIn) {
      this.pageTransition('/')
    }
  },

  methods: {
  },

  components: {
    ComponentsLayoutPage1,
    ComponentsLayoutSection1,
    ComponentsLayoutSection2,
    // ComponentsLayoutHeading1,
    ComponentsItemsButton
  }
}
</script>
