<template>
<ComponentsLayoutPage1>
  <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '680px', paddingTop: '30px', paddingBottom: '50px' }">
    <v-col cols=12 >
      <ComponentsLayoutSection1>
        <v-row align="center" justify="center" class="pb-3">
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <v-chip x-small label color="grey lighten-1" class="mr-2 white--text">販売業者</v-chip>
          </v-col>
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <span class="ec-font-size14" v-text="Tradelaw.company_name"></span>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row align="center" justify="center" class="pb-3 pt-5">
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <v-chip x-small label color="grey lighten-1" class="mr-2 white--text">運営責任者</v-chip>
          </v-col>
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <span class="ec-font-size12" v-text="Tradelaw.director_name"></span>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row align="center" justify="center" class="pb-3 pt-5">
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <v-chip x-small label color="grey lighten-1" class="mr-2 white--text">法人所在地</v-chip>
          </v-col>
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <span class="ec-font-size12" v-text="'〒 ' + Tradelaw.post_no"></span><br />
            <span class="ec-font-size12" v-text="getPrefectureName(Tradelaw.prefecture_id)"></span>
            <span class="ec-font-size12" v-text="Tradelaw.address"></span>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row align="center" justify="center" class="pb-3 pt-5">
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <v-chip x-small label color="grey lighten-1" class="mr-2 white--text">電話番号</v-chip>
          </v-col>
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <span class="ec-font-size12" v-text="Tradelaw.tel"></span><br />
            <span class="ec-font-size12" v-html="Tradelaw.business_hours"></span>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row align="center" justify="center" class="pb-3 pt-5">
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <v-chip x-small label color="grey lighten-1" class="mr-2 white--text">メールアドレス（お客さま窓口）</v-chip>
          </v-col>
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <span class="ec-font-size12" v-text="Tradelaw.mail_address"></span>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row align="center" justify="center" class="pb-3 pt-5">
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <v-chip x-small label color="grey lighten-1" class="mr-2 white--text">商品以外の料金</v-chip>
          </v-col>
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <span class="ec-font-size12" v-html="Tradelaw.other_fee"></span>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row align="center" justify="center" class="pb-3 pt-5">
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <v-chip x-small label color="grey lighten-1" class="mr-2 white--text">返品、交換</v-chip>
          </v-col>
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <span class="ec-font-size12" v-html="Tradelaw.exchange"></span>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row align="center" justify="center" class="pb-3 pt-5">
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <v-chip x-small label color="grey lighten-1" class="mr-2 white--text">お客様のご都合による返品・交換</v-chip>
          </v-col>
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <span class="ec-font-size12" v-html="Tradelaw.customer_exchange"></span>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row align="center" justify="center" class="pb-3 pt-5">
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <v-chip x-small label color="grey lighten-1" class="mr-2 white--text">販売数量</v-chip>
          </v-col>
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <span class="ec-font-size12" v-html="Tradelaw.sale_volume"></span>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row align="center" justify="center" class="pb-3 pt-5">
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <v-chip x-small label color="grey lighten-1" class="mr-2 white--text">引き渡し時期</v-chip>
          </v-col>
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <span class="ec-font-size12" v-html="Tradelaw.delivery_time"></span>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row align="center" justify="center" class="pb-3 pt-5">
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <v-chip x-small label color="grey lighten-1" class="mr-2 white--text">個人情報</v-chip>
          </v-col>
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <span class="ec-font-size12" v-html="Tradelaw.personal_info"></span>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row align="center" justify="center" class="pb-3 pt-5">
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <v-chip x-small label color="grey lighten-1" class="mr-2 white--text">個人情報管理量</v-chip>
          </v-col>
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <span class="ec-font-size12" v-html="Tradelaw.personal_info_manage"></span>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row align="center" justify="center" class="pb-3 pt-5">
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <v-chip x-small label color="grey lighten-1" class="mr-2 white--text">免責事項</v-chip>
          </v-col>
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <span class="ec-font-size12" v-html="Tradelaw.disclaimer"></span>
          </v-col>
        </v-row>
      </ComponentsLayoutSection1>
    </v-col>
  </v-row>
</ComponentsLayoutPage1>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutPage1 from '@/components/layout/Page1'
import ComponentsLayoutSection1 from '@/components/layout/Section2'

export default {
  name: 'InformationTradelawPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true
  }),

  computed: {
    Tradelaw: {
      get () {
        return this.$store.getters['modStorage/Tradelaw']
      }
    }
  },

  components: {
    ComponentsLayoutPage1,
    ComponentsLayoutSection1
  }
}
</script>
