<template>
<v-card outlined>
  <v-card-text>
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12" class="py-3 text-center">
        <span v-text="warningMessage"></span>
      </v-col>
      <v-col cols=12 sm=6 md=5 lg=6 xl=6 :class="(windowXsSize) ? 'py-1 text-center' : 'py-1 pr-5 text-right'">
        <ComponentsItemsButton
          large
          :block="(windowSpSize)"
          width="200"
          color="R2MainColorLight"
          icon="mdi-account-plus"
          text="新規取引申請"
          @click="pageTransition('/entry')"
        >
        </ComponentsItemsButton>
      </v-col>
      <v-col cols=12 sm=6 md=5 lg=6 xl=6 :class="(windowXsSize) ? 'py-1 text-center' : 'py-1 pl-5 text-left'">
        <ComponentsItemsButton
          large
          :block="(windowSpSize)"
          width="200"
          color="R2InfoColor"
          icon="mdi-help-circle"
          text="ショッピングガイド"
          @click="pageTransition('/guide')"
        >
        </ComponentsItemsButton>
      </v-col>
      <v-col cols=12 class="py-3 text-center">
        <v-icon color="R2MainColorLight" left>mdi-phone-in-talk</v-icon><span class="R2MainColorLight--text ec-font-size18 font-weight-black" v-text="companyProfile.tel"></span><span class="caption pl-5" v-text="`FAX: ${companyProfile.fax}`"></span><br />
        <span class="caption" v-text="companyProfile.comment1"></span><br />
        <span class="caption" v-text="companyProfile.comment2"></span>
      </v-col>
    </v-row>
  </v-card-text>
</v-card>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ComponentsViewFormsProductsApply',

  mixins: [MixinLib],

  data: () => ({
    companyProfile: {
      tel: '03-6416-1919',
      fax: '03-4335-0619',
      comment1: 'お電話受付：10:00～18:00(土日祝を除く)',
      comment2: 'ウェブからのご注文は営業日時外も承っております'
    }
  }),

  methods: {
  },

  components: {
    ComponentsItemsButton
  },

  props: {
    warningMessage: String
  }
}
</script>
