<template>
<v-card flat color="grey lighten-2" class="pa-0 ma-0 mx-auto" :width="parseInt(widthSize)" :height="parseInt(widthSize)" @click="$emit('click')">
  <v-img :src="imgSrc" class="mx-auto" style="background-position:center">
    <template v-slot:placeholder>
      <v-row
        class="fill-height ma-0"
        align="center"
        justify="center"
      >
        <v-progress-circular
          indeterminate
          color="grey lighten-5"
        ></v-progress-circular>
      </v-row>
    </template>
    <v-row
      class="ma-1"
      align="start"
      justify="center"
      no-gutters
    >
      <v-col cols="12">
        <v-chip x-small class="mr-1" dark label><span  v-text="label"></span></v-chip>
      </v-col>
      <v-col cols="12">
        <v-chip x-small class="mr-1" color="orange darken-4" dark label v-if="campaign">キャンペーン中</v-chip>
      </v-col>
    </v-row>
  </v-img>
</v-card>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

export default {
  name: 'ComponentsViewFormsSquareProductsImage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false
  }),

  computed: {
  },

  methods: {
  },

  components: {
  },

  props: {
    imgSrc: {
      type: String,
      require: false,
      default: () => ''
    },
    label: {
      type: String,
      require: false,
      default: () => ''
    },
    widthSize: {
      type: [String, Number],
      require: false,
      default: () => '140'
    },
    campaign: {
      type: Boolean,
      require: false,
      default: () => false
    }
  }
}
</script>
