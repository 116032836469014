<template>
<ComponentsLayoutPage1>
  <v-row align="center" justify="center" class="mx-auto" :style="{ paddingBottom: '100px' }">

    <v-col cols=12 class="py-5">
      <ComponentsLayoutSection1>
        <v-row no-gutters>
          <v-col cols=12 class="ec-font-size14 font-weight-bold">
            <span>＜このお問合せフォームについて＞</span>
          </v-col>
          <v-col cols=12>
            <span>こちらのフォームは株式会社R2へのお問合せフォームとなり、新規のお取引に関するお問合せをお受けしております。<br>取扱商品に関するお問合せは、各商品のメーカー、もしくはお取扱店舗へのご連絡をお願い致します。</span>
          </v-col>
          <v-col cols=12 class="ec-font-size14 font-weight-bold pt-3">
            <span>＜お問い合わせについて＞</span>
          </v-col>
          <v-col cols=12>
            <span>お客様の個人情報、お寄せいただいた内容は厳重に取扱い、プライバシー保護に努めます。<br>お問い合わせには、営業時間内に対応させていただきます。<br>営業時間：10:00～18:00（土・日・祝日・年末年始・夏期休暇を除く）<br>お問い合わせ内容によっては、お返事を差し上げるまでにお時間をいただく場合がございます。あらかじめご了承ください。</span>
          </v-col>
          <v-col cols=12 class="ec-font-size14 font-weight-bold pt-3">
            <span>＜ご返事について＞</span>
          </v-col>
          <v-col cols=12>
            <span>システム障害や何らかの要因によりお返事できない場合がございますので、3営業日以内に返答のない場合はお電話にてその旨お問い合わせください。</span>
          </v-col>
          <v-col cols=12 class="ec-font-size14 font-weight-bold pt-3">
            <span>＜お取引について＞</span>
          </v-col>
          <v-col cols=12>
            <span>現在は二次卸については、新規のご契約をお断りさせて頂いております。予めご了承下さい。</span>
          </v-col>
        </v-row>
      </ComponentsLayoutSection1>
    </v-col>

    <v-col cols=12 v-for="(item, index) of forms" :key="index">
      <v-row align="start" justify="center">
        <v-col :cols="(windowMiddleSize) ? 12: 4">
          <span v-text="item.title"></span>
        </v-col>
        <v-col :cols="(windowMiddleSize) ? 12: 8">
          <v-row >
            <v-col cols=12 v-for="cols of item.columns" :key="cols.name">
              <v-row align="center" justify="center" no-gutters>
                <v-col cols=12 class="pb-3" v-if="isUndefined(cols.subtitle) === false">
                  <span v-text="cols.subtitle"></span>
                </v-col>
                <v-col cols=12 class="pb-3" v-if="isUndefined(cols.description) === false">
                  <span v-html="cols.description"></span>
                </v-col>
                <v-col cols=10 v-if="cols.type === 'text'">
                  <ComponentsItemsTextField
                    :name="cols.name"
                    :value.sync="data[cols.name]"
                    :loading="isProcessLoading"
                    :ref="cols.name"
                  >
                  </ComponentsItemsTextField>
                </v-col>
                <v-col cols=10 v-if="cols.type === 'text_area'">
                  <ComponentsItemsTextArea
                    :name="cols.name"
                    :value.sync="data[cols.name]"
                    :loading="isProcessLoading"
                    :ref="cols.name"
                  >
                  </ComponentsItemsTextArea>
                </v-col>
                <v-col cols=10 v-if="cols.type === 'select' && cols.multiple === true">
                  <ComponentsItemsCheckBoxGroup
                    :name="cols.name"
                    :items="cols.collection"
                    item-text="name"
                    item-value="code"
                    :value.sync="data[cols.name]"
                    :loading="isProcessLoading"
                    :ref="cols.name"
                  >
                  </ComponentsItemsCheckBoxGroup>
                </v-col>
                <v-col cols=10 v-if="cols.type === 'select' && cols.multiple === false">
                  <ComponentsItemsRadioGroup
                    :name="cols.name"
                    :items="cols.collection"
                    item-text="name"
                    item-value="code"
                    :value.sync="data[cols.name]"
                    :loading="isProcessLoading"
                    :ref="cols.name"
                  >
                  </ComponentsItemsRadioGroup>
                </v-col>
                <v-col cols=10 v-if="cols.type === 'list'">
                  <ComponentsItemsSelectField
                    :name="cols.name"
                    :items="cols.collection"
                    item-text="name"
                    item-value="code"
                    :value.sync="data[cols.name]"
                    :loading="isProcessLoading"
                    :ref="cols.name"
                  >
                  </ComponentsItemsSelectField>
                </v-col>
                <v-col cols=2>
                  <v-chip class="ml-3" color="error" x-small v-if="(cols.require)">必須</v-chip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols=12 v-if="forms.length > (index + 1)">
          <v-divider></v-divider>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols=12 :style="{ paddingTop: '50px' }">
      <v-row align="center" justify="center">
        <v-col cols=12 class="text-center ec-font-size12 red--text">
          <span>ご注文はWEBでの注文となります</span>
        </v-col>

        <v-col :cols="(windowSpSize) ? 12 : (windowMiddleSize) ? 6 : 4">
          <ComponentsItemsButton
            block
            text="お申込み画面へ進む"
            @click="goNext()"
          >
          </ComponentsItemsButton>
        </v-col>
      </v-row>
    </v-col>

  </v-row>
</ComponentsLayoutPage1>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
// import APILib from '@/libs/APILib'

import ComponentsLayoutPage1 from '@/components/layout/Page1'
import ComponentsLayoutSection1 from '@/components/layout/Section1'

import ComponentsItemsTextField from '@/components/items/TextField'
import ComponentsItemsTextArea from '@/components/items/TextArea'
import ComponentsItemsSelectField from '@/components/items/SelectField'
import ComponentsItemsCheckBoxGroup from '@/components/items/CheckBoxGroup'
import ComponentsItemsRadioGroup from '@/components/items/RadioGroup'

import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ConsoleEntryApplyPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false
  }),

  watch: {
    isSignIn () {
      if (this.isSignIn) {
        this.pageTransition('/')
      }
    }
  },

  mounted () {
    if (this.isSignIn) {
      this.pageTransition('/')
    }
  },

  computed: {
    forms: {
      get () {
        return this.$store.getters['modStorage/EntryFormItems']
      }
    },
    data: {
      get () {
        return this.$store.getters['modSession/entryData']
      },
      set (v) {
        this.$store.dispatch('modSession/entryData', v)
      }
    }
  },

  methods: {
    goNext () {
      (async () => {
        this.isProcessLoading = true
        let error = false
        for (const name of Object.keys(this.$refs)) {
          if (this.isArray(this.$refs[name])) {
            for (const form of this.$refs[name]) {
              await form.confirm()
              if (form.error) {
                error = true
              }
            }
          } else {
            await this.$refs[name].confirm()
            if (this.$refs[name].error) {
              error = true
            }
          }
        }
        if (error) {
          this.isProcessLoading = false
          this.$confirm({ title: 'Error', message: '入力内容を御確認ください', button: { yes: 'OK' } })
          return
        }
        this.pageTransition('/entry/confirm')
        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    }
  },

  components: {
    ComponentsLayoutPage1,
    ComponentsLayoutSection1,
    ComponentsItemsTextField,
    ComponentsItemsTextArea,
    ComponentsItemsSelectField,
    ComponentsItemsCheckBoxGroup,
    ComponentsItemsRadioGroup,
    ComponentsItemsButton
  }
}
</script>
