<template>
<v-row align="center" justify="center">

  <v-col cols=12>
    <ComponentsLayoutHeading1 text="クレジットカード登録"></ComponentsLayoutHeading1>
  </v-col>

  <v-col cols=12>
    <ComponentsLayoutSection1>
      <v-row align="center" justify="center" no-gutters>
        <v-col :cols="(windowMiddleSize) ? 12 : 4" class="py-1">
          <span>カード番号</span>
        </v-col>
        <v-col :cols="(windowMiddleSize) ? 12 : 8" class="py-1">
          <ComponentsItemsTextField
            name='credit_card_no'
            prepend-inner-icon="mdi-credit-card"
            :value.sync="cardObject.cardno"
            :loading="isProcessLoading"
            mask="####-####-####-####"
            max-length="19"
            :css-style="{ maxWidth: '280px' }"
            ref="credit_card_no"
          ></ComponentsItemsTextField>
        </v-col>
        <v-col :cols="(windowMiddleSize) ? 12 : 4" class="py-1">
          <span>有効期限</span>
        </v-col>
        <v-col :cols="(windowMiddleSize) ? 12 : 8" class="py-1">
          <ComponentsItemsTextField
            name='credit_expire'
            :value.sync="cardObject.expire"
            :loading="isProcessLoading"
            mask="##/##"
            max-length="5"
            :css-style="{ maxWidth: '100px' }"
            ref="credit_expire"
          ></ComponentsItemsTextField>
        </v-col>
        <v-col :cols="(windowMiddleSize) ? 12 : 4" class="py-1">
          <span>セキュリティコード</span>
        </v-col>
        <v-col :cols="(windowMiddleSize) ? 12 : 8" class="py-1">
          <ComponentsItemsTextField
            name='credit_security_code'
            :value.sync="cardObject.securitycode"
            :loading="isProcessLoading"
            mask="####"
            max-length="4"
            :css-style="{ maxWidth: '100px' }"
            ref="credit_security_code"
          ></ComponentsItemsTextField>
        </v-col>
        <v-col :cols="(windowMiddleSize) ? 12 : 4" class="py-1">
          <span>カード名義人</span>
        </v-col>
        <v-col :cols="(windowMiddleSize) ? 12 : 8" class="py-1">
          <ComponentsItemsTextField
            name='credit_holder_name'
            :value.sync="cardObject.holdername"
            :loading="isProcessLoading"
            max-length="50"
            :css-style="{ maxWidth: '240px' }"
            ref="credit_holder_name"
          ></ComponentsItemsTextField>
        </v-col>
      </v-row>
    </ComponentsLayoutSection1>
  </v-col>

  <v-col cols=12 class="text-center">
    <ComponentsItemsButton
      text="クレジットカードを登録する"
      color="R2MainColorLight"
      @click="commitCredit()"
    >
    </ComponentsItemsButton>
  </v-col>

  <v-col cols=12 class="text-center">
    <ComponentsItemsButton
      outlined
      text="一覧に戻る"
      @click="pageTransition('/myPage/credit')"
    >
    </ComponentsItemsButton>
  </v-col>

</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'

import ComponentsItemsTextField from '@/components/items/TextField'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'MyPageCreateCreditPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false,
    cardObject: {
      cardno: '',
      expire: '',
      securitycode: '',
      holdername: ''
    }
  }),

  mounted () {
    if (!this.isSignIn) {
      this.pageTransition('/login')
    }
  },

  methods: {
    commitCredit () {
      (async () => {
        this.isProcessLoading = true
        window.Multipayment.getToken(
          {
            cardno: this.cardObject.cardno.replaceAll('-', ''),
            expire: this.cardObject.expire.replaceAll('/', ''),
            securitycode: this.cardObject.securitycode,
            holdername: this.cardObject.holdername
          },
          (result) => {
            (async () => {
              if (result.resultCode === '000') {
                await APILib.postShoppingPaymentsCredit(result.tokenObject.token)
                this.$confirm({ title: 'SUCCESS', message: 'クレジットカードの登録を行いました', button: { yes: 'OK' } })
                this.pageTransition('/myPage/credit')
              } else {
                this.$confirm({ title: 'Error', message: 'CODE-' + result.resultCode, button: { yes: 'OK' } })
              }
              this.isProcessLoading = false
            })().catch((e) => {
              this.isProcessLoading = false
              this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
            })
          }
        )
      })().catch((e) => {
        this.isProcessLoading = false
      })
    }
  },

  components: {
    ComponentsLayoutSection1,
    ComponentsLayoutHeading1,
    ComponentsItemsTextField,
    ComponentsItemsButton
  }
}
</script>
