import UtilLib from '@/libs/UtilLib'

const state = {
  windowSize: {
    width: 0,
    height: 0
  },
  isInitialLoading: false,
  isLoading: true,
  isDataLoading: false,
  breadcrumbs: [],
  frame_common_header: false,
  frame_navigation: false,
  frame_common_footer: false,
  frame_extension_footer: false
}

const getters = {
  isInitialLoading: (state) => {
    return state.isInitialLoading
  },
  isLoading: (state) => {
    return state.isLoading
  },
  isDataLoading: (state) => {
    return state.isDataLoading
  },
  windowWidthSize: (state) => {
    return state.windowSize.width
  },
  windowHeightSize: (state) => {
    return state.windowSize.height
  },
  pageShowCommonHeader: (state) => {
    return state.frame_common_header
  },
  pageShowNavigation: (state) => {
    return state.frame_navigation
  },
  pageShowCommonFooter: (state) => {
    return state.frame_common_footer
  },
  pageShowExtensionFooter: (state) => {
    return state.frame_extension_footer
  },
  breadcrumbs: (state) => {
    return state.breadcrumbs
  }
}

const mutations = {
  initialize (state) {
    state = {
      windowSize: {
        width: 0,
        height: 0
      },
      breadcrumbs: [],
      frame_common_header: false,
      frame_navigation: false,
      frame_common_footer: false,
      frame_extension_footer: false
    }
  },
  setWindowWidthSize (state, size) {
    state.windowSize.width = size
  },
  setWindowHeightSize (state, size) {
    state.windowSize.height = size
  },
  setPageContents (state, contents) {
    state.frame_common_header = (UtilLib.getValue('frame_common_header', contents))
    state.frame_navigation = (UtilLib.getValue('frame_navigation', contents))
    state.frame_common_footer = (UtilLib.getValue('frame_common_footer', contents))
    state.frame_extension_footer = (UtilLib.getValue('frame_extension_footer', contents))
    state.breadcrumbs = UtilLib.getValue('breadcrumbs', contents)
  }
}

const actions = {
  isInitialLoading ({ commit, dispatch, state }, contents) {
    state.isInitialLoading = contents
  },
  isLoading ({ commit, dispatch, state }, contents) {
    state.isLoading = contents
  },
  isDataLoading ({ commit, dispatch, state }, contents) {
    state.isDataLoading = contents
  },
  reset ({ commit, dispatch, state }) {
    commit('initialize')
  },
  setWindowWidthSize ({ commit, dispatch, state }, size) {
    commit('setWindowWidthSize', size)
  },
  setWindowHeightSize ({ commit, dispatch, state }, size) {
    commit('setWindowHeightSize', size)
  },
  setPageContents ({ commit, dispatch, state }, contents) {
    commit('setPageContents', contents)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
