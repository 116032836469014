<template>
<ComponentsLayoutPage1>
  <v-row align="center" justify="center" class="mx-auto" :style="{ paddingBottom: '100px' }">
    <!--
    <v-col cols=12 class="pb-5">
      <ComponentsLayoutHeading1 text="取引申請"></ComponentsLayoutHeading1>
    </v-col>
    --->
    <v-col cols=12 class="text-center" :style="{ paddingTop: '50px' }">
      <v-row no-gutters>
        <v-col cols=12 class="pb-3">
          <span class="ec-font-size18 font-weight-bold" :style="{ color: imageColor }">＼新規取引店登録は無料！／</span>
        </v-col>
        <v-col cols=12>
          <span>ペットサロン／ペット用品小売店／その他ペットに関する事業を行っている方であれば登録できます。</span>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols=12 class="py-5">
      <v-row no-gutters>
        <v-col cols=12>
          <span class="font-weight-bold">登録後に出来ること</span>
        </v-col>
        <v-col cols=12>
          <ComponentsLayoutSection1>
            <ul>
              <li>当ショッピングサイトにて商品を卸価格にてお買い求め頂くことが出来ます。</li>
              <li>商品パッケージなどの販促画像をダウンロードすることが出来ます。</li>
              <li>パンフレット等のパンフレットの要望が行えるようになります。</li>
            </ul>
          </ComponentsLayoutSection1>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols=12>
      <v-row no-gutters>
        <v-col cols=12>
          <span class="font-weight-bold">新規会員登録までの流れ</span>
        </v-col>
        <v-col :cols="(windowSpSize) ? 12 : (windowMiddleSize) ? 6 : 3">
          <v-card outlined class="ma-1">
            <v-img :src="images[0]" alt="Step 1" height="168">
              <v-chip label dark small :color="imageColor">Step 1.</v-chip>
            </v-img>
            <v-card-text>
              <span>新規お取引ページの新規お取引申し込みフォームに、店舗・会社情報を入力してください。登録は無料です。</span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col :cols="(windowSpSize) ? 12 : (windowMiddleSize) ? 6 : 3">
          <v-card outlined class="ma-1">
            <v-img :src="images[1]" alt="Step 2" height="168">
              <v-chip label dark small :color="imageColor">Step 2.</v-chip>
            </v-img>
            <v-card-text>
              <span>弊社より新規取引登録用紙、お取引詳細案件をメールで送ります。新規取引登録用紙に必要事項を記入ください。</span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col :cols="(windowSpSize) ? 12 : (windowMiddleSize) ? 6 : 3">
          <v-card outlined class="ma-1">
            <v-img :src="images[2]" alt="Step 3" height="168">
              <v-chip label dark small :color="imageColor">Step 3.</v-chip>
            </v-img>
            <v-card-text>
              <span>申込書をメールまたはFAXでご返送ください。</span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col :cols="(windowSpSize) ? 12 : (windowMiddleSize) ? 6 : 3">
          <v-card outlined class="ma-1">
            <v-img :src="images[3]" alt="Step 4" height="168">
              <v-chip label dark small :color="imageColor">Step 4.</v-chip>
            </v-img>
            <v-card-text>
              <span>弊社で申込書を確認し、問題なければログイン情報をメールで送付致します。</span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols=12 :style="{ paddingTop: '50px' }">
      <v-row align="center" justify="center">
        <v-col :cols="(windowSpSize) ? 12 : (windowMiddleSize) ? 6 : 4">
          <ComponentsItemsButton
            block
            text="お申込み画面へ進む"
            @click="pageTransition('/entry/apply')"
          >
          </ComponentsItemsButton>
        </v-col>
      </v-row>
    </v-col>

  </v-row>
</ComponentsLayoutPage1>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutPage1 from '@/components/layout/Page1'
// import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ConsoleEntryPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false,
    images: [
      require('@/assets/image/entry/step-1.jpg'),
      require('@/assets/image/entry/step-2.jpg'),
      require('@/assets/image/entry/step-3.jpg'),
      require('@/assets/image/entry/step-4.jpg')
    ]
  }),

  watch: {
    isSignIn () {
      if (this.isSignIn) {
        this.pageTransition('/')
      }
    }
  },

  mounted () {
    if (this.isSignIn) {
      this.pageTransition('/')
    }
  },

  components: {
    ComponentsLayoutPage1,
    // ComponentsLayoutHeading1,
    ComponentsLayoutSection1,
    ComponentsItemsButton
  }
}
</script>
