<template>
<ComponentsLayoutPage1>
  <v-row align="center" justify="center">
    <v-col cols=11 v-for="banner in banners" :key="banner.src">
      <ComponentsItemsBanner
        :imgSrc="banner.imgSrc"
        :alt="banner.alt"
        :route="banner.page"
        width="1040"
        height="306"
        contain
      >
      </ComponentsItemsBanner>
    </v-col>
  </v-row>
</ComponentsLayoutPage1>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutPage1 from '@/components/layout/Page1'
import ComponentsItemsBanner from '@/components/items/Banner'

export default {
  name: 'CampaignPage',

  mixins: [MixinLib],

  data: () => ({
    banners: [
      {
        page: '/campaign/campaign/list',
        imgSrc: require('@/assets/banner/bnr_campaign.jpg'),
        alt: 'キャンペーン'
      },
      {
        page: '/campaign/limited/list',
        imgSrc: require('@/assets/banner/limited.jpg'),
        alt: 'R2限定'
      }
    ]
  }),

  mounted () {
    if (!this.isSignIn) {
      this.pageTransition('/login')
    }
  },

  methods: {
  },

  components: {
    ComponentsLayoutPage1,
    ComponentsItemsBanner
  }
}
</script>
