<template>
<v-row align="start" justify="start">
  <v-col :cols="(windowMiddleSize) ? 12 : 3">
    <ComponentsLayoutHeading1 text="販促物同梱希望" border-hidden></ComponentsLayoutHeading1>
  </v-col>
  <v-col :cols="(windowMiddleSize) ? 12 : 9" :class="(windowMiddleSize) ? 'pb-5' : ''">
    <v-card :outlined="(windowMiddleSize)" :flat="(!windowMiddleSize)" :class="(windowMiddleSize) ? 'px-2 py-3' : 'pb-3'">
      <v-row align="center" justify="center" no-gutters>
        <v-col cols=12 :class="(windowMiddleSize) ? 'py-1 px-2' : 'py-1'">
          <ComponentsItemsCheckBoxGroup
            name="shopping_pamphlet"
            :items="CartSelectablePamphlet"
            itemText="pamphlet_name"
            itemValue="pamphlet_id"
            :value.sync="CartPamphlet"
            :loading="loading || isProcessLoading"
          >
          </ComponentsItemsCheckBoxGroup>
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsItemsCheckBoxGroup from '@/components/items/CheckBoxGroup'

export default {
  name: 'ComponentsViewFormsShoppingPamphlet',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false
  }),

  computed: {
    CartPamphlet: {
      get () {
        return this.$store.getters['modShopping/CartPamphlet']
      },
      set (v) {
        this.$store.dispatch('modShopping/UpdateShoppingCartPamphlet', v)
      }
    },
    CartSelectablePamphlet: {
      get () {
        return this.$store.getters['modShopping/CartSelectablePamphlet']
      }
    }
  },

  components: {
    ComponentsLayoutHeading1,
    ComponentsItemsCheckBoxGroup
  },

  props: {
    loading: {
      type: Boolean,
      require: false,
      default: () => false
    }
  }
}
</script>
