<template>
<v-row v-if="isSignIn">
  <v-col cols="12">
    <ComponentsItemsButton
      block
      :large="!smallSize"
      :small="smallSize"
      :outlined="(isFavorites)"
      :color="(isFavorites) ? '' : 'R2MainColorLight'"
      icon="mdi-cart-outline"
      :text="(isFavorites) ? '購買リストから除く' : '購買リストに追加'"
      @click="(isFavorites) ? $emit('click-remove') : $emit('click-add')"
    >
    </ComponentsItemsButton>
  </v-col>
</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ComponentsViewFormsProductsSetOrderButton',

  mixins: [MixinLib],

  data: () => ({
  }),

  computed: {
    UsersFavorites: {
      get () {
        return this.$store.getters['modStorage/UsersFavorites']
      }
    },
    isFavorites: {
      get () {
        return this.UsersFavorites.some(item => item.products_id === this.productsId)
      }
    }
  },

  components: {
    ComponentsItemsButton
  },

  props: {
    smallSize: {
      type: Boolean,
      require: false,
      default: () => false
    },
    loading: {
      type: Boolean,
      require: false,
      default: () => false
    },
    productsId: {
      type: String,
      require: true,
      default: () => ''
    }
  }
}
</script>
