<template>
<ComponentsLayoutPage1>
  <v-row align="start" justify="start" class="mt-5" no-gutters>

    <v-col cols=12 class="py-5">
      <v-row align="center" justify="center">
        <v-col cols=12 md=3 lg=3 xl=3 class="py-1">
          <span>カテゴリ</span>
          <ComponentsItemsSelectField
            name='categories'
            :value.sync="SearchCategory"
            :items="[ { categories_id: null, categories_name: '' }, ...categories ]"
            itemText="categories_name"
            itemValue="categories_id"
            :loading="isProcessLoading"
            label="カテゴリ"
            ref="categories"
          ></ComponentsItemsSelectField>
          <!--
          <v-select
            v-model="SearchCategory"
            :items="categories"
            item-text="categories_name"
            item-value="categories_id"
            hide-details
            filled
            rounded
            dense
            single-line
            :loading="isLoading"
            label="カテゴリ"
            class="caption"
          ></v-select>
          --->
        </v-col>
        <v-col cols=12 md=3 lg=3 xl=3 class="py-1">
          <span>ブランド</span>
          <ComponentsItemsSelectField
            name='brands'
            :value.sync="SearchBrand"
            :items="[ { id: null, brand_name: '' }, ...brands ]"
            itemText="brand_name"
            itemValue="id"
            :loading="isProcessLoading"
            label="ブランド"
            ref="brands"
          ></ComponentsItemsSelectField>
          <!---
          <v-select
            v-model="SearchBrand"
            :items="brands"
            item-text="brand_name"
            item-value="id"
            hide-details
            filled
            rounded
            dense
            single-line
            :loading="isLoading"
            label="ブランド"
            class="caption"
          ></v-select>
          --->
        </v-col>
        <v-col cols=12 md=3 lg=3 xl=3 class="py-1">
          <span>キーワード</span>
          <ComponentsItemsTextField
            name='search_keyword'
            :value.sync="SearchKeyword"
            placeholder="キーワードを入力"
            :loading="isProcessLoading"
            ref="search_keyword"
          ></ComponentsItemsTextField>
          <!--
          <v-text-field
            v-model="SearchKeyword"
            filled
            rounded
            dense
            hide-details
            single-line
            :loading="isLoading"
            placeholder="キーワードを入力"
          ></v-text-field>
          --->
        </v-col>
        <v-col cols=12 md=3 lg=3 xl=3 class="pt-3 pb-5 text-right" :style=" (windowSpSize) ? '' : 'margin-top: 25px;'">
          <ComponentsItemsButton
            block
            text="再検索"
            :loading="isProcessLoading"
            @click="tapSearch()"
          >
          </ComponentsItemsButton>
        </v-col>
        <input id='hiddenSearchCategory' type="hidden">
        <input id='hiddenSearchBrand' type="hidden">
        <input id='hiddenSearchKeyword' type="hidden">
        <v-col cols=12 class="pt-5 pl-5 text-left ec-font-size14">
          <span class="font-weight-bold ec-font-size14">検索結果: {{ parseInt(count).toLocaleString() }}件が該当しました</span>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols=12 class="text-center" v-show="maxPage > 0">
      <v-pagination
        v-model="page"
        :length="maxPage"
        :total-visible="totalVisible"
        :color="buttonColor"
      >
      </v-pagination>
    </v-col>

    <v-col cols=12 class="py-5">
      <v-row align="start" justify="start" no-gutters>
        <v-col cols=6 md=4 lg=3 xl=3 class="pb-5" v-for="item in list" :key="item.products_id">
          <ComponentsViewFormsSquareProducts
            small-size
            :products-id="item.products_id"
            :img-src="(isUndefined(item.products_image)) ? '' : item.products_image[0].img_src"
            :label="getBrandName(item.products.brand_id)"
            :categories="item.category.map(item => getCategoriesName(item.categories_id))"
            :text="item.products.products_name"
            :width-size="(windowMiddleSize) ? 140 : 200"
            class="mx-auto"
            @click="pageMove('/products/' + item.products_id)"
          >
          </ComponentsViewFormsSquareProducts>
          <!--

          <ComponentsPieceProductsBlock
            :ledger="products"
            :width="imageWidthSize"
            :height="imageHeightSize"
            :favorites="(isLogin)"
            class="mx-auto"
          >
          </ComponentsPieceProductsBlock>
          --->
        </v-col>
      </v-row>
    </v-col>

    <v-col cols=12 class="text-center" v-show="maxPage > 0">
      <v-pagination
        v-model="page"
        :length="maxPage"
        :total-visible="totalVisible"
        :color="buttonColor"
      >
      </v-pagination>
    </v-col>

  </v-row>
</ComponentsLayoutPage1>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutPage1 from '@/components/layout/Page1'
import ComponentsViewFormsSquareProducts from '@/components/view-forms/SquareProducts'
// import ComponentsPieceProductsBlock from '@/components/piece/ProductsBlock'
import ComponentsItemsButton from '@/components/items/Button'
import ComponentsItemsTextField from '@/components/items/TextField'
import ComponentsItemsSelectField from '@/components/items/SelectField'

export default {
  name: 'ProductsListPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true,
    SearchCategory: '',
    SearchBrand: '',
    SearchKeyword: '',
    categories: [],
    brands: [],
    list: [],
    count: 0,
    page: 1,
    limit: 24,
    maxPage: 0,
    totalVisible: 8 // Page最大数
  }),

  computed: {
    // ヘッダーの入力ボックス
    QueryCategory: {
      get () {
        return this.$route.query.category
      }
    },
    QueryBrand: {
      get () {
        return this.$route.query.brand
      }
    },
    QueryKeyword: {
      get () {
        return this.$route.query.keyword
      }
    },
    isSearchValid: {
      get () {
        return this.$store.getters['modSearch/isSearchValid']
      },
      set (v) {
        this.$store.dispatch('modSearch/isSearchValid', v)
      }
    },
    isSaveValid: {
      get () {
        return this.$store.getters['modSearch/isSaveValid']
      },
      set (v) {
        this.$store.dispatch('modSearch/isSaveValid', v)
      }
    },
    Category: {
      get () {
        return this.$store.getters['modSearch/Category']
      },
      set (v) {
        this.$store.dispatch('modSearch/Category', v)
      }
    },
    Brand: {
      get () {
        return this.$store.getters['modSearch/Brand']
      },
      set (v) {
        this.$store.dispatch('modSearch/Brand', v)
      }
    },
    Keyword: {
      get () {
        return this.$store.getters['modSearch/Keyword']
      },
      set (v) {
        this.$store.dispatch('modSearch/Keyword', v)
      }
    }
  },

  watch: {
    page () {
      this.goLoading()
    },
    QueryKeyword () {
      this.SearchCategory = (this.Category !== undefined) ? this.Category : ''
      this.SearchBrand = (this.Brand !== undefined) ? this.Brand : ''
      this.SearchKeyword = (this.Keyword !== undefined) ? this.Keyword : this.QueryKeyword
      this.Category = ''
      this.Brand = ''
      this.Keyword = ''
      this.goSearch()
    }
  },

  mounted: function () {
    this.goLoading()
    if (this.isSearchValid === '') {
      if ((this.Keyword !== undefined)) {
        this.SearchKeyword = this.Keyword
      }
    }
  },

  methods: {

    // 検索
    goSearch: function () {
      (async () => {
        this.isProcessLoading = true
        const Query = {}
        if (this.isUndefined(this.SearchCategory) === false) {
          Query.categories = this.SearchCategory
          document.getElementById('hiddenSearchCategory').value = Query.categories
        }
        if (this.isUndefined(this.SearchBrand) === false) {
          Query.brand = this.SearchBrand
          document.getElementById('hiddenSearchBrand').value = Query.brand
        }
        if (this.isUndefined(this.SearchKeyword) === false) {
          Query.keyword = this.SearchKeyword
          document.getElementById('hiddenSearchKeyword').value = Query.keyword
        }
        // this.list = await APILib.getProductsSearch(Query)

        const resultRows = await APILib.getProductsSearch(Query, this.page, this.limit)
        this.count = this.getValue('count', resultRows) || 0
        this.maxPage = Math.ceil((this.getValue('count', resultRows) || 0) / this.limit)
        this.list = this.getValue('data', resultRows) || []

        this.isProcessLoading = false
      })().catch((e) => {
        this.isProcessLoading = false
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
      // this.pageTransition('/productsList', null, { category: this.SearchCategory, brand: this.SearchBrand, keyword: this.SearchKeyword })
    },
    tapSearch: function () {
      (async () => {
        if (this.isUndefined(this.SearchCategory) === false) {
          this.Category = this.SearchCategory
        } else {
          this.Category = ''
        }
        if (this.isUndefined(this.SearchBrand) === false) {
          this.Brand = this.SearchBrand
        } else {
          this.Brand = ''
        }
        if (this.isUndefined(this.SearchKeyword) === false) {
          this.Keyword = this.SearchKeyword
        } else {
          this.Keyword = ''
        }
        this.isSearchValid = ''
        this.isSaveValid = 'true'
        this.goSearch()
      })().catch((e) => {
        this.isProcessLoading = false
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
      // this.pageTransition('/productsList', null, { category: this.SearchCategory, brand: this.SearchBrand, keyword: this.SearchKeyword })
    },

    // 初期処理
    goLoading: function () {
      (async () => {
        this.categories = await APILib.getCategoriesList()

        const resultRows2 = await APILib.getBrandList()
        this.brands = resultRows2.filter(v => v.brand.status === '1').map(v => { return { ...v.brand, id: v.brand_id } })

        if (this.isUndefined(this.QueryCategory) === false) {
          this.SearchCategory = this.QueryCategory
        }
        if (this.isUndefined(this.QueryBrand) === false) {
          this.SearchBrand = this.QueryBrand
        }
        if (this.isUndefined(this.QueryKeyword) === false) {
          this.SearchKeyword = this.QueryKeyword
        }
        if (this.isSaveValid === 'true') {
          if (this.isSearchValid === '') {
            if ((this.Category !== undefined)) {
              this.SearchCategory = this.Category
            }
            if ((this.Brand !== undefined)) {
              this.SearchBrand = this.Brand
            }
            if ((this.Keyword !== undefined)) {
              this.SearchKeyword = this.Keyword
            }
          }
        }

        this.Category = ''
        this.Brand = ''
        this.Keyword = ''
        this.isSearchValid = 'true'
        this.isProcessLoading = true
        await this.goSearch()
      })().catch((e) => {
        this.isProcessLoading = false
      })
    },
    pageMove (v) {
      if (this.isUndefined(this.SearchCategory) === false) {
        this.Category = document.getElementById('hiddenSearchCategory').value
      } else {
        this.Category = ''
      }
      if (this.isUndefined(this.SearchBrand) === false) {
        this.Brand = document.getElementById('hiddenSearchBrand').value
      } else {
        this.Brand = ''
      }
      if (this.isUndefined(this.SearchKeyword) === false) {
        this.Keyword = document.getElementById('hiddenSearchKeyword').value
      } else {
        this.Keyword = ''
      }
      this.isSearchValid = ''
      this.pageTransition(v)
    }

  },

  components: {
    ComponentsLayoutPage1,
    ComponentsViewFormsSquareProducts,
    ComponentsItemsButton,
    ComponentsItemsTextField,
    ComponentsItemsSelectField

    // ComponentsPieceProductsBlock
  }
}
</script>
