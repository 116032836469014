<template>
<ComponentsLayoutPage1>
  <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '680px', paddingTop: '30px', paddingBottom: '50px' }" v-if="isUndefined(guide)">
    <v-col cols=12>
      <v-list dense color="transparent">
        <v-list-item-group>
          <v-list-item @click="pageTransition('/guide?id=' + guide.guide_id)" :key="'i-' + index" v-for="(guide, index) in list">
            <v-list-item-content>
              <v-list-item-title class="caption font-weight-bold"><span :style="{ color: buttonColor }" v-text="guide.contents.title"></span></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-col>
  </v-row>
  <v-row align="center" justify="center" class="mx-auto px-5" :style="{ maxWidth: '680px', paddingTop: '30px', paddingBottom: '50px' }" v-else>
    <v-col cols=12>
      <ComponentsLayoutHeading1 :text="guide.contents.title"></ComponentsLayoutHeading1>
    </v-col>
    <v-col cols=12 class="py-5">
      <pre class="ec-font-size12" :style="{ whiteSpace: 'pre-wrap', fontFamily: '\'Helvetica Neue\', Tabular, \'Hiragino Kaku Gothic ProN\', \'Hiragino Sans\', \'BIZ UDPGothic\', Meiryo, sans-serif !important' }" v-html="guide.contents.body"></pre>
    </v-col>
  </v-row>
</ComponentsLayoutPage1>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutPage1 from '@/components/layout/Page1'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'

export default {
  name: 'GuidePage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true,
    list: [],
    guide: {}
  }),

  watch: {
    GuideID () {
      this.goLoading()
    }
  },

  computed: {
    GuideID: {
      get () {
        return this.$route.query.id
      }
    }
  },

  mounted: function () {
    this.goLoading()
  },

  methods: {
    goLoading: function () {
      (async () => {
        this.isProcessLoading = true
        this.guide = (this.isUndefined(this.GuideID)) ? null : await APILib.getGuide(this.GuideID)
        this.list = (this.isUndefined(this.GuideID)) ? await APILib.getGuideList() : []
        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    }
  },

  components: {
    ComponentsLayoutPage1,
    ComponentsLayoutHeading1
  }
}
</script>
