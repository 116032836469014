<template>
<ComponentsLayoutPage1>
  <v-row align="start" justify="start" class="py-5" :style="(windowMiddleSize) ? 'margin-bottom: 40px;' : 'margin-bottom: 40px;'">
    <v-col cols=12>
      <ComponentsLayoutHeading1 text="お届け先の選択" border-hidden></ComponentsLayoutHeading1>
    </v-col>

    <v-col cols="12">
      <v-list two-line color="white">
        <template  v-for="(item, index) in UsersAddress">

          <v-divider v-if="index == 0" :key="'d1-' + item.dealings_address_id"></v-divider>

          <v-list-item :key="item.dealings_address_id" :class="(item.dealings_address_id === Cart.dealings_address_id) ? 'orange lighten-5' : ''"  @click="actionNext(item)">

            <v-list-item-content>
              <v-list-item-title class="ec-font-size16 py-2">
                <span v-text="item.dealings_address.name"></span>
              </v-list-item-title>
              <v-list-item-subtitle class="ec-font-size12">
                &#12306;&nbsp;<span v-text="item.dealings_address.post_no"></span>
              </v-list-item-subtitle>
              <v-list-item-subtitle class="ec-font-size12">
                <span v-text="getPrefectureName(item.dealings_address.prefecture_id)"></span><span v-text="item.dealings_address.address"></span>
              </v-list-item-subtitle>
              <v-list-item-subtitle class="ec-font-size12">
                <span v-text="item.dealings_address.tel"></span>
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon>
                <v-icon color="R2MainColorLight">mdi-arrow-right</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>

          <v-divider :key="'d2-' + item.dealings_address_id"></v-divider>
        </template>
      </v-list>
    </v-col>
  </v-row>
</ComponentsLayoutPage1>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutPage1 from '@/components/layout/Page1'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
// import ComponentsShippingListBlock from '@/components/shipping/ListBlock'

export default {
  name: 'ShoppingShippingPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true,
    list: []
  }),

  computed: {
    Cart: {
      get () {
        return this.$store.getters['modShopping/Cart']
      }
    },
    UsersAddress: {
      get () {
        return this.$store.getters['modStorage/UsersAddress']
      }
    }
  },

  mounted: function () {
    if (!this.isSignIn) {
      this.pageTransition('/')
    }
    this.goLoading()
  },

  methods: {
    // 初期処理
    goLoading: function () {
      (async () => {
        if (this.UsersAddress.length === 1) {
          return this.actionNext(this.UsersAddress[0])
        }
        this.isProcessLoading = false
      })().catch((e) => {
        this.isProcessLoading = false
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    },
    actionNext: function (item) {
      (async () => {
        this.isProcessLoading = true
        await this.$store.dispatch('modShopping/UpdateShoppingCart', { dealings_address_id: item.dealings_address_id })
        // this.$store.dispatch('modShopping/ShoppingAddress', item)
        this.isProcessLoading = false
        this.pageTransition('/shopping/confirm')
      })().catch((e) => {
        this.isProcessLoading = false
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    }
  },

  components: {
    ComponentsLayoutPage1,
    ComponentsLayoutHeading1
    // ComponentsShippingListBlock
  }
}
</script>
