<template>
  <ComponentsLayoutPage2
    @change="actionSearch($event)"
  >
    <v-row align="center" justify="center" class="py-5" :style="(windowMiddleSize) ? 'margin-bottom: 40px;' : 'margin-bottom: 40px;'" v-if="ShoppingFavorites.length === 0">
      <v-col cols=8 class="text-center" v-if="!isProcessLoading">
        <v-alert type="error">
          <span >現在購買リストに登録されている商品はありません</span>
        </v-alert>
      </v-col>
    </v-row>
    <v-row align="start" justify="start" class="py-5" :style="(windowMiddleSize) ? 'margin-bottom: 40px;' : 'margin-bottom: 40px;'" v-else>

      <!--
        <v-row align="start" justify="start" no-gutters>
          <v-col cols=12 class="pb-1">
            <v-icon color="primary" left>mdi-cart-outline</v-icon><span class="primary--text ec-font-size16 font-weight-bold">購買リスト</span>
          </v-col>
          <v-col cols="12" class="py-1">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" class="pb-5 ec-font-size10 text-left">
            <span>規格名をクリック（タップ）で賞味期限や小売価格が表示されます</span>
          </v-col>
        </v-row>
      --->
      <v-col cols=12 v-if="postageFreeLinePrice > 0">
        <v-alert dense text type="info">
          <span>送料無料まで &yen; </span><span v-text="postageFreeLinePrice.toLocaleString()"></span><span>(税込)</span>
        </v-alert>
      </v-col>

      <v-col cols=12 class="pb-5">
        <v-row align="start" justify="start" id="searchCurrent">
          <v-col cols=12 v-if="!windowSpSize">
            <v-row align="center" justify="center">
              <v-col cols=3 class="caption text-center">
                <span>商品</span>
              </v-col>
              <v-col :cols="(windowSpSize) ? 4 : 3" class="caption text-center">
                <span>規格</span><br />
                <span>JANコード</span><br />
                <span>賞味期限</span>
              </v-col>
              <v-col :cols="(windowSpSize) ? 4 : 3" class="caption text-center">
                <span>小売価格 (税込)</span><br />
                <span>卸価格 (税込)</span>
              </v-col>
              <v-col :cols="(windowSpSize) ? 4 : 3" class="caption text-center">
                <span>購入数</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols=12 class="py-1" v-if="!windowSpSize">
            <v-divider></v-divider>
          </v-col>
          <v-col cols=12 v-for="(products, products_index) in ShoppingFavorites" :key="products.products_id" >
            <v-row align="start" justify="center" :id="`tr_${products_index}`">
              <v-col cols=12 class="caption text-left" :style="{ paddingTop: '30px' }" v-if="windowSpSize">
                <ComponentsViewFormsSimpleProducts
                  :products-id="products.products_id"
                  :label="(isUndefined(getValue('brand_id', getValue('products', products) || {}))) ? '' : getBrandName(products.products.brand_id)"
                  :categories="(isUndefined(getValue('category', products))) ? [] : products.category.map(item => getCategoriesName(item.categories_id))"
                  :text="(isUndefined(getValue('products_name', getValue('products', products) || {}))) ? '' : products.products.products_name"
                  @click="pageTransition('/products/' + products.products_id)"
                  @reload="goLoading()"
                  v-if="isItemTypeProducts(products)"
                >
                </ComponentsViewFormsSimpleProducts>
                <ComponentsViewFormsLimitedSimpleProducts
                  :original-products-id="products.original_products_id"
                  :dealings-favorites-original-set-id="products.dealings_favorites_original_set_id"
                  :text="products.original_products.products_name"
                  @reload="goLoading()"
                  v-else
                >
                </ComponentsViewFormsLimitedSimpleProducts>
              </v-col>
              <v-col cols=3 class="caption text-left" v-else>
                <ComponentsViewFormsSquareProducts
                  small-size
                  :products-id="products.products_id"
                  :img-src="(isUndefined(products.products_image)) ? '' : products.products_image[0].img_src"
                  :label="(isUndefined(getValue('brand_id', getValue('products', products) || {}))) ? '' : getBrandName(products.products.brand_id)"
                  :categories="(isUndefined(getValue('category', products))) ? [] : products.category.map(item => getCategoriesName(item.categories_id))"
                  :text="(isUndefined(getValue('products_name', getValue('products', products) || {}))) ? '' : products.products.products_name"
                  @click="pageTransition('/products/' + products.products_id)"
                  @reload="goLoading()"
                  v-if="isItemTypeProducts(products)"
                >
                </ComponentsViewFormsSquareProducts>
                <ComponentsViewFormsLimitedSquareProducts
                  small-size
                  :original-products-id="products.original_products_id"
                  :dealings-favorites-original-set-id="products.dealings_favorites_original_set_id"
                  :img-src="(isUndefined(products.original_products.img_src)) ? '' : products.original_products.img_src"
                  :text="products.original_products.products_name"
                  @reload="goLoading()"
                  v-else
                >
                </ComponentsViewFormsLimitedSquareProducts>
              </v-col>
              <v-col cols=12 v-if="windowSpSize">
                <v-row align="center" justify="center">
                  <v-col :cols="(windowSpSize) ? 5 : 3" class="ec-font-size10 text-center">
                    <span>規格</span><br />
                    <span>JANコード</span><br />
                    <span>賞味期限</span>
                  </v-col>
                  <v-col :cols="(windowSpSize) ? 3 : 3" class="ec-font-size10 text-center">
                    <span>小売価格 (税込)</span><br />
                    <span>卸価格 (税込)</span>
                  </v-col>
                  <v-col :cols="(windowSpSize) ? 4 : 3" class="ec-font-size10 text-center">
                    <span>購入数</span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols=12 class="py-1" v-if="windowSpSize">
                <v-divider></v-divider>
              </v-col>
              <!-- 通常商品 -->
              <v-col :cols="(windowSpSize) ? 12 : 9" v-if="isItemTypeProducts(products)">
                <v-row align="start" justify="center" v-for="(item, items_index) in products.items" :key="item.products_class_id">
                  <template v-if="parseInt(getValue('price', getValue('products_class_ws_price', item) || {})) >= 0">
                    <v-col :id="item.products_class.product_code" cols=5 class="caption text-left">
                      <v-row no-gutters>
                        <v-col cols=12>
                          <v-chip x-small class="mr-1" color="orange darken-4" dark label v-if="isCampaign(item)">キャンペーン中</v-chip>
                        </v-col>
                        <v-col cols=12>
                          <span v-text="getValue('size', getValue('products_class', item) || {})"></span><span v-text="getValue('color', getValue('products_class', item) || {})"></span>
                          <br>
                          <span class="searchJanCode" v-text="item.products_class.product_code"></span>
                          <br>
                          <span v-text="formatDate(item.products_class.expiration_date, 'YYYY年MM月DD日')"></span>
                          <template v-if="isUndefined(item.products_class.back_in_stock_date) === false && isPastDate(item.products_class.back_in_stock_date) == false">
                            <br><br>
                            <span>入荷予定</span><br>
                            <span v-text="formatDate(item.products_class.back_in_stock_date, 'YYYY年MM月DD日')"></span>
                          </template>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols=3 class="caption text-right">
                      <v-row align="center" justify="center" no-gutters>
                        <v-col cols=12>
                          &yen;<span v-text="' ' + parseInt(getValue('price', getValue('products_class_price', item) || {})).toLocaleString()"></span><br />
                        </v-col>
                        <v-col cols=12 v-if="parseInt(getValue('price', getValue('products_class_ws_price', item) || {})) >= 0">
                          &yen;<span class="warning--text" v-text="' ' + parseInt(getValue('price', getValue('products_class_ws_price', item) || {})).toLocaleString()"></span>
                        </v-col>
                        <v-col cols=12 v-else>
                          <span class="warning--text">取引対象外商品</span>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols=4 class="caption text-center">
                      <v-text-field
                        v-model="item.quantity"
                        filled
                        rounded
                        dense
                        hide-details
                        type="number"
                        class="caption right-input pl-3"
                        :background-color="(item.quantity == 0) ? '' : 'rgba(255, 152, 0, 0.2)'"
                        @focus="focusCalculator(item)"
                        @blur="blurCalculator(item)"
                        v-if="parseInt(getValue('price', getValue('products_class_ws_price', item) || {})) >= 0"
                      ></v-text-field>
                    </v-col>
                    <v-col cols=12 class="py-1" v-if="isUndefined(item.message) === false">
                      <v-alert dense border="left" type="error" class="caption">
                        <span v-text="item.message"></span>
                      </v-alert>
                    </v-col>
                    <v-col cols=12 class="py-1" v-if="products.items.length > (items_index + 1)">
                      <v-divider></v-divider>
                    </v-col>
                  </template>
                </v-row>
              </v-col>
              <!-- R2限定-->
              <v-col :cols="(windowSpSize) ? 12 : 9" v-else>
                <v-row align="start" justify="center">
                  <v-col cols=4 class="ec-font-size12 text-left">
                    <v-row v-if="(windowSpSize) === false">
                      <v-col cols=12>
                        <v-chip x-small label color="grey lighten-4" class="mr-2">セット内容</v-chip>
                      </v-col>
                      <v-col cols=12>
                        <v-row align="center" justify="center" no-gutters v-for="item in products.items" :key="item.dealings_favorites_original_set.products_class_id">
                          <v-col cols=10>
                            <span v-text="getValue('products_name', getValue('products', item) || {})"></span>
                            <span v-text="' ' + getValue('size', getValue('products_class', item) || {})" v-if="isUndefined(getValue('size', getValue('products_class', item) || {})) === false"></span>
                            <span v-text="' ' + getValue('color', getValue('products_class', item) || {})" v-if="isUndefined(getValue('color', getValue('products_class', item) || {})) === false"></span>
                          </v-col>
                          <v-col cols=2 class="text-right">
                            &times;&nbsp;<span v-text="getValue('quantity', getValue('dealings_favorites_original_set', item) || {})"></span>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols=4 class="caption text-right">
                    <v-row align="center" justify="center" no-gutters>
                      <v-col cols=12>
                        <span>-</span><br />
                      </v-col>
                      <v-col cols=12 v-if="products.original_products_price.price >= 0">
                        &yen;<span class="warning--text" v-text="' ' + parseInt(products.original_products_price.price).toLocaleString()"></span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols=4 class="caption text-center">
                    <v-text-field
                      v-model="products.quantity"
                      filled
                      rounded
                      dense
                      hide-details
                      type="number"
                      class="caption right-input pl-3"
                      :background-color="(products.quantity == 0) ? '' : 'rgba(255, 152, 0, 0.2)'"
                      @focus="focusCalculator(products)"
                      @blur="blurCalculator(products)"
                      v-if="products.original_products_price.price >= 0"
                    ></v-text-field>
                  </v-col>
                  <v-col cols=12 class="ec-font-size12 text-left" v-if="(windowSpSize)">
                    <v-row align="center" justify="center" class="pb-3">
                      <v-col cols=12>
                        <v-chip x-small label color="grey lighten-4" class="mr-2">セット内容</v-chip>
                      </v-col>
                    </v-row>
                    <v-row align="center" justify="center" no-gutters v-for="item in products.items" :key="item.dealings_favorites_original_set.products_class_id">
                      <v-col cols=10>
                        <span v-text="getValue('products_name', getValue('products', item) || {})"></span>
                        <span v-text="' ' + getValue('size', getValue('products_class', item) || {})" v-if="isUndefined(getValue('size', getValue('products_class', item) || {})) === false"></span>
                        <span v-text="' ' + getValue('color', getValue('products_class', item) || {})" v-if="isUndefined(getValue('color', getValue('products_class', item) || {})) === false"></span>
                      </v-col>
                      <v-col cols=2 class="text-right">
                        &times;&nbsp;<span v-text="getValue('quantity', getValue('dealings_favorites_original_set', item) || {})"></span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols=12 class="py-1" v-if="isUndefined(products.message) === false">
                    <v-alert dense border="left" type="error" class="caption">
                      <span v-text="products.message"></span>
                    </v-alert>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols=12 class="py-1" v-if="ShoppingFavorites.length > (products_index + 1)">
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols=12 class="py-1">
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols=12 class="pt-5">
        <v-row align="center" justify="end">
          <v-col :cols="(windowMiddleSize) ? 12 : 6">
            <ComponentsLayoutSection1>
              <v-row align="center" justify="center">
                <v-col cols=6>
                  <ComponentsLayoutHeading2 text="個数 （点数）" border-hidden></ComponentsLayoutHeading2>
                </v-col>
                <v-col cols=6 class="text-right font-weight-bold ec-font-size14">
                  <span v-text="CartCalculation.quantity"></span>
                </v-col>
                <v-col cols=6>
                  <ComponentsLayoutHeading2 text="小計 (税込)" border-hidden></ComponentsLayoutHeading2>
                </v-col>
                <v-col cols=6 class="text-right font-weight-bold ec-font-size14">
                  &yen; <span v-text="CartCalculation.sub_total.toLocaleString()"></span>
                </v-col>
                <v-col cols=12 class="py-1">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols=6>
                  <ComponentsLayoutHeading2 text="送料" border-hidden></ComponentsLayoutHeading2>
                </v-col>
                <v-col cols=6 class="text-right font-weight-bold ec-font-size14">
                  &yen; <span v-text="CartCalculation.postage.toLocaleString()"></span>
                </v-col>
                <v-col cols=6>
                  <ComponentsLayoutHeading2 text="手数料" border-hidden></ComponentsLayoutHeading2>
                </v-col>
                <v-col cols=6 class="text-right font-weight-bold ec-font-size14">
                  <span v-text="commission"></span>
                </v-col>
                <v-col cols=6 v-show="CartCalculation.discount > 0">
                  <ComponentsLayoutHeading2 text="値引き" border-hidden></ComponentsLayoutHeading2>
                </v-col>
                <v-col cols=6 class="text-right font-weight-bold ec-font-size14 red--text" v-show="CartCalculation.discount > 0">
                  &yen; <span v-text="(CartCalculation.discount  * -1).toLocaleString()"></span>
                </v-col>
                <v-col cols=12 class="py-1">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols=6>
                  <ComponentsLayoutHeading1 text="合計 (税込)" border-hidden></ComponentsLayoutHeading1>
                </v-col>
                <v-col cols=6 class="text-right font-weight-bold ec-font-size14">
                  &yen; <span v-text="total.toLocaleString()"></span>
                </v-col>
                <v-col cols=12 v-if="postageFreeLinePrice > 0">
                  <v-alert dense text type="info">
                    <span>送料無料まで &yen; </span><span v-text="postageFreeLinePrice.toLocaleString()"></span><span>(税込)</span>
                  </v-alert>
                </v-col>
                <v-col cols=12 class="pt-5">
                  <ComponentsItemsButton
                    block
                    large
                    :disabled="(CartCalculation.quantity === 0 || this.isCalcProgress)"
                    :loading="isProcessLoading"
                    text="レジに進む"
                    @click="confirmNext()"
                  >
                  </ComponentsItemsButton>
                </v-col>
              </v-row>
            </ComponentsLayoutSection1>
          </v-col>
        </v-row>
      </v-col>

    </v-row>

    <v-dialog v-model="isCalculator" persistent scrollable width="320">
      <ComponentsItemsCalculator
        :show="isCalculator"
        :model="edit.quantity"
        @change="closeCalculator($event)"
        @close="isCalculator = false"
      >
      </ComponentsItemsCalculator>
    </v-dialog>
  </ComponentsLayoutPage2>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
// import APILib from '@/libs/APILib'

import ComponentsLayoutPage2 from '@/components/layout/Page2'
import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsLayoutHeading2 from '@/components/layout/Heading2'
import ComponentsViewFormsSquareProducts from '@/components/view-forms/SquareProducts'
import ComponentsViewFormsLimitedSquareProducts from '@/components/view-forms/LimitedSquareProducts'
import ComponentsViewFormsSimpleProducts from '@/components/view-forms/SimpleProducts'
import ComponentsViewFormsLimitedSimpleProducts from '@/components/view-forms/LimitedSimpleProducts'

import ComponentsItemsCalculator from '@/components/items/Calculator'
import ComponentsItemsButton from '@/components/items/Button'

// import ComponentsPieceProductsBlock from '@/components/piece/ProductsBlock'
// import ComponentsPieceShoppingProductsCollectionBlock from '@/components/piece/ShoppingProductsCollectionBlock'

export default {
  name: 'ShoppingPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true,
    isCalculator: false,
    isCalcProgress: false,
    ShoppingFavorites: [],
    edit: {
      quantity: 0
    }
  }),

  /*
  watch: {
    UsersFavorites () {
      this.goLoading()
    }
  },
  */

  computed: {
    UsersFees: {
      get () {
        return this.$store.getters['modStorage/UsersFees']
      }
    },
    UsersFavorites: {
      get () {
        return this.$store.getters['modStorage/UsersFavorites']
      }
    },
    UsersFavoritesOriginals: {
      get () {
        return this.$store.getters['modStorage/UsersFavoritesOriginals']
      }
    },
    CartItems: {
      get () {
        return this.$store.getters['modShopping/CartItems']
      }
    },
    CartCalculation: {
      get () {
        return this.$store.getters['modShopping/CartCalculation']
      }
    },
    postageFreeLinePrice: {
      get () {
        return (this.CartCalculation.postage_free_line >= 0) ? this.CartCalculation.postage_free_line - this.CartCalculation.sub_total : -1
      }
    },
    commission: {
      get () {
        return '支払方法に準拠'
      }
    },
    total: {
      get () {
        return this.CartCalculation.sub_total + this.CartCalculation.postage - this.CartCalculation.discount
      }
    },
    CampaignListProducts: {
      get () {
        return this.$store.getters['modStorage/CampaignListProducts']
      }
    }
  },

  mounted: function () {
    if (!this.isSignIn) {
      this.pageTransition('/')
    }
    this.initLoading()
  },

  methods: {
    // 初期処理
    initLoading: function () {
      (async () => {
        this.isProcessLoading = true
        await this.$store.dispatch('modShopping/LoadShoppingCart')
        await this.$store.dispatch('modStorage/LoadUsersFavorites')
        await this.$store.dispatch('modStorage/LoadUsersFavoritesOriginals')
        // 販売停止商品の初期化
        for (const Products of this.UsersFavorites) {
          for (const ProductsClass of Products.items) {
            const index = this.CartItems.findIndex(item => item.dealings_cart_products.order_item_type_status === process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_PRODUCTS && item.products_class_id === ProductsClass.products_class_id)
            if (index >= 0) {
              if (!(ProductsClass.products_class_ws_price.price >= 0)) {
                await this.$store.dispatch('modShopping/SetShoppingCartProducts', [ProductsClass.products_class_id, 0])
              }
            }
          }
        }
        for (const Originals of this.UsersFavoritesOriginals) {
          const index = this.CartItems.findIndex(item => item.dealings_cart_products.order_item_type_status === process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_LIMITED && item.dealings_favorites_original_set_id === Originals.dealings_favorites_original_set_id)
          if (index >= 0) {
            if (!(Originals.original_products_price.price >= 0)) {
              await this.$store.dispatch('modShopping/SetShoppingCartLimited', [Originals.dealings_favorites_original_set_id, 0])
            }
          }
        }
        // カートセット
        this.ShoppingFavorites = [
          ...this.UsersFavorites.map(Products => {
            Products.items = Products.items.map(ProductsClass => {
              const index = this.CartItems.findIndex(item => item.dealings_cart_products.order_item_type_status === process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_PRODUCTS && item.products_class_id === ProductsClass.products_class_id && ProductsClass.products_class_ws_price.price >= 0)

              return {
                ...ProductsClass,
                quantity: (index >= 0) ? parseInt(this.CartItems[index].dealings_cart_products.quantity) : 0,
                order_item_type_status: process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_PRODUCTS,
                message: ProductsClass.message
              }
            })
            return {
              ...Products,
              order_item_type_status: process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_PRODUCTS
            }
          }),
          ...this.UsersFavoritesOriginals.map(Originals => {
            const index = this.CartItems.findIndex(item => item.dealings_cart_products.order_item_type_status === process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_LIMITED && item.dealings_favorites_original_set_id === Originals.dealings_favorites_original_set_id && Originals.original_products_price.price >= 0)
            console.log((index >= 0) ? this.CartItems[index].message : '限定　空')
            return {
              ...Originals,
              quantity: (index >= 0) ? parseInt(this.CartItems[index].dealings_cart_products.quantity) : 0,
              order_item_type_status: process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_LIMITED,
              message: Originals.message
            }
          })
        ]
        this.isProcessLoading = false
      })().catch((e) => {
        this.isProcessLoading = false
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    },
    goLoading: function () {
      (async () => {
        this.isProcessLoading = true
        await this.$store.dispatch('modShopping/LoadShoppingCart')
        await this.$store.dispatch('modStorage/LoadUsersFavorites')
        await this.$store.dispatch('modStorage/LoadUsersFavoritesOriginals')
        // 販売停止商品の初期化
        for (const Products of this.UsersFavorites) {
          for (const ProductsClass of Products.items) {
            const index = this.CartItems.findIndex(item => item.dealings_cart_products.order_item_type_status === process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_PRODUCTS && item.products_class_id === ProductsClass.products_class_id)
            if (index >= 0) {
              if (!(ProductsClass.products_class_ws_price.price >= 0)) {
                await this.$store.dispatch('modShopping/SetShoppingCartProducts', [ProductsClass.products_class_id, 0])
              }
            }
          }
        }
        for (const Originals of this.UsersFavoritesOriginals) {
          const index = this.CartItems.findIndex(item => item.dealings_cart_products.order_item_type_status === process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_LIMITED && item.dealings_favorites_original_set_id === Originals.dealings_favorites_original_set_id)
          if (index >= 0) {
            if (!(Originals.original_products_price.price >= 0)) {
              await this.$store.dispatch('modShopping/SetShoppingCartLimited', [Originals.dealings_favorites_original_set_id, 0])
            }
          }
        }
        // カートセット
        this.ShoppingFavorites = [
          ...this.UsersFavorites.map(Products => {
            Products.items = Products.items.map(ProductsClass => {
              const index = this.CartItems.findIndex(item => item.dealings_cart_products.order_item_type_status === process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_PRODUCTS && item.products_class_id === ProductsClass.products_class_id && ProductsClass.products_class_ws_price.price >= 0)
              return {
                ...ProductsClass,
                quantity: (index >= 0) ? parseInt(this.CartItems[index].dealings_cart_products.quantity) : 0,
                order_item_type_status: process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_PRODUCTS,
                message: (index >= 0) ? this.CartItems[index].message : ''
              }
            })
            return {
              ...Products,
              order_item_type_status: process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_PRODUCTS
            }
          }),
          ...this.UsersFavoritesOriginals.map(Originals => {
            const index = this.CartItems.findIndex(item => item.dealings_cart_products.order_item_type_status === process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_LIMITED && item.dealings_favorites_original_set_id === Originals.dealings_favorites_original_set_id && Originals.original_products_price.price >= 0)
            return {
              ...Originals,
              quantity: (index >= 0) ? parseInt(this.CartItems[index].dealings_cart_products.quantity) : 0,
              order_item_type_status: process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_LIMITED,
              message: (index >= 0) ? this.CartItems[index].message : ''
            }
          })
        ]
        this.isProcessLoading = false
      })().catch((e) => {
        this.isProcessLoading = false
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    },
    isCampaign: function (item) {
      return this.CampaignListProducts.some(campaign => campaign.campaign_specific_buying.products_class_id === item.products_class_id)
    },
    isItemTypeProducts: function (item) {
      return (item.order_item_type_status === process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_PRODUCTS)
    },
    viewCalculator: function (item) {
      this.edit = item
      this.isCalculator = true
    },
    focusCalculator: function (item) {
      setTimeout(
        function () {
          this.edit = item
          this.edit.quantity = (this.edit.quantity === 0) ? '' : this.edit.quantity
        }
        , 100)
      this.isCalcProgress = true
    },
    blurCalculator: function (item) {
      this.isCalcProgress = true
      this.edit = item
      this.edit.quantity = (this.edit.quantity === '') ? 0 : this.edit.quantity
      const quantity = (this.edit.quantity === '') ? 0 : this.edit.quantity
      this.closeCalculator(quantity)
      this.isCalcProgress = false
    },
    closeCalculator: function (quantity) {
      (async () => {
        if (this.isItemTypeProducts(this.edit)) {
          await this.$store.dispatch('modShopping/SetShoppingCartProducts', [this.edit.products_class_id, quantity])
          const index = this.CartItems.findIndex(item => item.dealings_cart_products.order_item_type_status === process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_PRODUCTS && item.products_class_id === this.edit.products_class_id)
          this.edit.quantity = (index >= 0) ? parseInt(this.CartItems[index].dealings_cart_products.quantity) : 0
          this.edit.message = (index >= 0) ? this.CartItems[index].message : ''
        } else {
          await this.$store.dispatch('modShopping/SetShoppingCartLimited', [this.edit.dealings_favorites_original_set_id, quantity])
          const index = this.CartItems.findIndex(item => item.dealings_cart_products.order_item_type_status === process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_LIMITED && item.dealings_favorites_original_set_id === this.edit.dealings_favorites_original_set_id)
          this.edit.quantity = (index >= 0) ? parseInt(this.CartItems[index].dealings_cart_products.quantity) : 0
          this.edit.message = (index >= 0) ? this.CartItems[index].message : ''
        }
        // this.edit.quantity = quantity
        this.isCalculator = false
      })().catch((e) => {
        this.isCalculator = false
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    },
    confirmNext: function () {
      if (this.postageFreeLinePrice > 0) {
        this.$confirm({ title: '確認', message: 'あと \\ ' + this.postageFreeLinePrice.toLocaleString() + ' で送料無料になりますが、レジに進んでよろしいですか？', button: { yes: 'レジに進む', no: '戻る' }, callback: this.actionNext })
      } else {
        this.actionNext(true)
      }
    },
    actionNext: function (ask) {
      (async () => {
        if (ask) {
          await this.$store.dispatch('modShopping/UpdateShoppingCartProducts', this.CartItems.map(item => item.dealings_cart_products))
          this.pageTransition('/shopping/shipping')
        }
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    },
    actionSearch: function (searchKeyword) {
      (async () => {
        // 文字検索で空だった場合の判定
        if (searchKeyword.length === 0) {
          return
        }
        // 検索範囲のエレメント取得
        const parentElement = document.getElementById('searchCurrent')
        // node分ループ
        for (const node of parentElement.childNodes) {
          // タグがないコメントなどかどうか判定
          if ((node.style === undefined)) {
            continue
          }
          // 要素ノードの場合のみ処理
          // 名前検索の場合部分一致でJanCodeの場合完全一致になるため処理を分ける
          const searchName = node.querySelector('.searchName')
          // 名前検索 nullチェック
          if (searchName !== null) {
            if (searchName.textContent.indexOf(searchKeyword) > -1) {
              this.scrollToElement(node)
              return
            }
          }
          // JanCodeの場合複数ある場合があるので全取得
          const searchJanCode = node.querySelectorAll('.searchJanCode')
          // JanCode nullチェック
          if (searchJanCode !== null) {
            // JanCodeの数だけループ
            for (const janCode of searchJanCode) {
              // 完全一致しているかどうか判定
              if (janCode.textContent === searchKeyword) {
                this.scrollToElement(node)
                return
              }
            }
          }
        }
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    },
    scrollToElement: function (targetNode) {
      (async () => {
        const offset = this.windowSpSize ? 200 : 180
        window.scrollTo({
          top: targetNode.getBoundingClientRect().top - offset,
          behavior: 'smooth'
        })
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    }
  },

  components: {
    ComponentsLayoutPage2,
    ComponentsLayoutSection1,
    ComponentsLayoutHeading1,
    ComponentsLayoutHeading2,
    ComponentsViewFormsSquareProducts,
    ComponentsViewFormsLimitedSquareProducts,
    ComponentsViewFormsSimpleProducts,
    ComponentsViewFormsLimitedSimpleProducts,
    ComponentsItemsCalculator,
    ComponentsItemsButton
    // ComponentsPieceProductsBlock,
    // ComponentsPieceShoppingProductsCollectionBlock
  }
}
</script>
