<template>
<v-row v-if="loading">
  <v-col cols=12>
    <v-row align="start" justify="start" no-gutters>
      <v-col cols=12 class="pt-5 pb-1">
        <v-icon color="primary" left>mdi-information</v-icon><span class="primary--text ec-font-size16 font-weight-bold">商品説明</span>
      </v-col>
      <v-col cols=12 class="py-1">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row align="start" justify="start" class="mx-3" no-gutters>
      <v-col cols=12 class="pa-1">
        <v-skeleton-loader class="mx-auto" type="text" height="50"></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row align="start" justify="center" class="mx-3 pt-5" no-gutters>
      <v-col cols=12 class="py-1">
        <v-divider></v-divider>
      </v-col>
      <v-col cols=12 class="py-1">
        <v-row align="start" justify="center" no-gutters>
          <v-col cols=3 class="pt-1">
            <v-chip x-small label color="grey lighten-1" class="mr-2 white--text">原産国</v-chip>
          </v-col>
          <v-col cols=9>
            <v-skeleton-loader class="mx-auto" type="text" height="30"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols=12 class="py-1">
        <v-divider></v-divider>
      </v-col>
      <v-col cols=12 class="py-1">
        <v-row align="start" justify="center" no-gutters>
          <v-col cols=3 class="pt-1">
            <ComponentsLayoutHeading3 text="素材"></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=9>
            <v-skeleton-loader class="mx-auto" type="text" height="30"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols=12 class="py-1">
        <v-divider></v-divider>
      </v-col>
      <v-col cols=12 class="py-1">
        <v-row align="start" justify="center" no-gutters>
          <v-col cols=3 class="pt-1">
            <ComponentsLayoutHeading3 text="成分値"></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=9>
            <v-skeleton-loader class="mx-auto" type="text" height="30"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols=12 class="py-1">
        <v-divider></v-divider>
      </v-col>
      <v-col cols=12 class="py-1">
        <v-row align="start" justify="center" no-gutters>
          <v-col cols=3 class="pt-1">
            <ComponentsLayoutHeading3 text="サイズ"></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=9>
            <v-skeleton-loader class="mx-auto" type="text" height="30"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols=12 class="py-1">
        <v-divider></v-divider>
      </v-col>
      <v-col cols=12 class="py-1">
        <v-row align="start" justify="center" no-gutters>
          <v-col cols=3 class="pt-1">
            <ComponentsLayoutHeading3 text="カロリー"></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=9>
            <v-skeleton-loader class="mx-auto" type="text" height="30"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols=12 class="py-1">
        <v-divider></v-divider>
      </v-col>
      <v-col cols=12 class="py-1">
        <v-row align="start" justify="center" no-gutters>
          <v-col cols=3 class="pt-1">
            <ComponentsLayoutHeading3 text="給与量"></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=9>
            <v-skeleton-loader class="mx-auto" type="text" height="30"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols=12 class="py-1">
        <v-divider></v-divider>
      </v-col>
      <v-col cols=12 class="py-1">
        <v-row align="start" justify="center" no-gutters>
          <v-col cols=3 class="pt-1">
            <ComponentsLayoutHeading3 text="備考"></ComponentsLayoutHeading3>
          </v-col>
          <v-col cols=9>
            <v-skeleton-loader class="mx-auto" type="text" height="30"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols=12 class="py-1">
        <v-divider></v-divider>
      </v-col>
    </v-row>
  </v-col>
</v-row>
<v-row v-else>
  <v-col cols=12>
    <v-row align="start" justify="start" no-gutters>
      <v-col cols=12 class="pt-5 pb-1">
        <v-icon color="primary" left>mdi-information</v-icon><span class="primary--text ec-font-size16 font-weight-bold">商品説明</span>
      </v-col>
      <v-col cols=12 class="py-1">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row align="start" justify="start" class="mx-3" no-gutters>
      <v-col cols=12 class="pa-1">
        <span class="ec-font-size12" v-html="products.goods_explain"></span>
      </v-col>
    </v-row>

    <v-row align="start" justify="center" class="mx-3">
      <v-col cols=12>
        <v-divider></v-divider>
        <v-row align="center" justify="center" class="pb-3 pt-5">
          <v-col :cols="(windowSpSize) ? 12 : 4">
            <v-chip x-small label color="primary" class="mr-2 white--text">原産国</v-chip>
          </v-col>
          <v-col :cols="(windowSpSize) ? 12 : 8">
            <span class="ec-font-size12" v-html="products.country"></span>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row align="center" justify="center" class="pb-3 pt-5">
          <v-col :cols="(windowSpSize) ? 12 : 4">
            <v-chip x-small label color="primary" class="mr-2 white--text">素材</v-chip>
          </v-col>
          <v-col :cols="(windowSpSize) ? 12 : 8">
            <span class="ec-font-size12" v-html="materials.map(item => item.material).join(',')"></span>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row align="center" justify="center" class="pb-3 pt-5">
          <v-col :cols="(windowSpSize) ? 12 : 4">
            <v-chip x-small label color="primary" class="mr-2 white--text">成分値</v-chip>
          </v-col>
          <v-col :cols="(windowSpSize) ? 12 : 8">
            <span class="ec-font-size12" v-html="products.component_value"></span>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row align="center" justify="center" class="pb-3 pt-5" v-if="isUndefined(products.size_details) === false">
          <v-col :cols="(windowSpSize) ? 12 : 4">
            <v-chip x-small label color="primary" class="mr-2 white--text">サイズ</v-chip>
          </v-col>
          <v-col :cols="(windowSpSize) ? 12 : 8">
            <span class="ec-font-size12" v-html="products.size_details"></span>
          </v-col>
        </v-row>
        <v-divider v-if="isUndefined(products.size_details) === false"></v-divider>
        <v-row align="center" justify="center" class="pb-3 pt-5" v-if="isUndefined(products.calorie) === false">
          <v-col :cols="(windowSpSize) ? 12 : 4">
            <v-chip x-small label color="primary" class="mr-2 white--text">カロリー</v-chip>
          </v-col>
          <v-col :cols="(windowSpSize) ? 12 : 8">
            <span class="ec-font-size12" v-html="products.calorie"></span>
          </v-col>
        </v-row>
        <v-divider v-if="isUndefined(products.calorie) === false"></v-divider>
        <v-row align="center" justify="center" class="pb-3 pt-5" v-if="isUndefined(products.supply_amount) === false">
          <v-col :cols="(windowSpSize) ? 12 : 4">
            <v-chip x-small label color="primary" class="mr-2 white--text">給与量</v-chip>
          </v-col>
          <v-col :cols="(windowSpSize) ? 12 : 8">
            <span class="ec-font-size12" v-html="products.supply_amount"></span>
          </v-col>
        </v-row>
        <v-divider v-if="isUndefined(products.supply_amount) === false"></v-divider>
        <v-row align="center" justify="center" class="pb-3 pt-5" v-if="isUndefined(products.remarks) === false">
          <v-col cols="12">
            <v-chip x-small label color="primary" class="mr-2 white--text">備考</v-chip>
          </v-col>
          <v-col cols="12">
            <span class="ec-font-size12" v-html="products.remarks"></span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>

</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutHeading3 from '@/components/layout/Heading3'

export default {
  name: 'ComponentsViewFormsProductsContents',

  mixins: [MixinLib],

  data: () => ({
    showSupplyAmount: false,
    showRemarks: false
  }),

  components: {
    ComponentsLayoutHeading3
  },

  props: {
    products: {
      type: Object,
      require: true,
      default: () => {
        return {
          brand_id: '',
          calorie: '',
          catch_copy: '',
          component_value: '',
          country: '',
          goods_explain: '',
          information: '',
          normal_freeze_status: '',
          outline: '',
          products_name: '',
          remarks: '',
          set_contents: '',
          size_details: '',
          supply_amount: '',
          use_target_status: ''
        }
      }
    },
    materials: {
      type: Array,
      require: true,
      default: () => {
        return []
      }
    },
    loading: {
      type: Boolean,
      require: false,
      default: () => false
    }
  }
}
</script>
