<template>
<v-row v-if="loading">
  <v-col cols=4>
    <v-card flat color="grey lighten-2" class="pa-0 ma-0" width="120" height="120" max-width="120" max-height="120"></v-card>
  </v-col>
  <v-col cols=8>
  </v-col>
</v-row>
<v-row v-else>
  <v-col cols=12 class="d-flex" v-if="windowSpSize">
    <v-row>
      <v-col cols=4>
        <v-card flat color="grey lighten-2" width="100" height="100" max-width="100" max-height="100">
          <v-row align="center" justify="center" style="height: 100%" no-gutters>
            <v-col cols="12">
              <v-img :src="(images.length === 0) ? '' : images[0].img_src" class="mx-auto" style="background-position:center"></v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols=8>
        <v-row no-gutters>
          <v-col cols=12>
            <v-chip x-small color="BrandLabelColor" class="mr-1" dark label><span v-text="getBrandName(products.brand_id)"></span></v-chip>
          </v-col>
          <v-col cols=12 class="ec-font-size14 font-weight-bold primary--text">
            <span v-text="products.products_name"></span>
            <span v-text="' ' + productsClass.color" v-if="!isUndefined(productsClass.color)"></span>
            <span v-text="' ' + productsClass.size" v-if="!isUndefined(productsClass.size)"></span>
          </v-col>
          <v-col cols=12 v-if="isUndefined(setCount) === false">
            <span class="ec-font-size12 font-weight-bold" v-text="'× ' + setCount + '個'"></span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
  <v-col cols=12 class="d-flex" v-else>
    <v-card flat color="grey lighten-2" class="mr-5" width="120" height="120" max-width="120" max-height="120">
      <v-row align="center" justify="center" style="height: 100%" no-gutters>
        <v-col cols="12">
          <v-img :src="(images.length === 0) ? '' : images[0].img_src" class="mx-auto" style="background-position:center"></v-img>
        </v-col>
      </v-row>
    </v-card>
    <v-row align="start" justify="center" no-gutters>
      <v-col :cols=8 class="text-left">
        <v-row no-gutters>
          <v-col cols=12>
            <v-chip x-small color="BrandLabelColor" class="mr-1" dark label><span v-text="getBrandName(products.brand_id)"></span></v-chip>
          </v-col>
          <v-col cols=12 class="ec-font-size14 font-weight-bold primary--text">
            <span v-text="products.products_name"></span>
            <span v-text="' ' + productsClass.color" v-if="!isUndefined(productsClass.color)"></span>
            <span v-text="' ' + productsClass.size" v-if="!isUndefined(productsClass.size)"></span>
          </v-col>
          <v-col cols=12>
            <pre :style="{ whiteSpace: 'pre-wrap' }" class="ec-font-size14 font-weight-bold" v-html="products.catch_copy"></pre>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols=4 class="fill-height" v-if="isUndefined(setCount) === false">
        <v-row align="center" justify="center"  class="fill-height" no-gutters>
          <span class="ec-font-size14 font-weight-bold" v-text="'× ' + setCount + '個'"></span>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

export default {
  name: 'ComponentsViewFormsLimitedProductsSet',

  mixins: [MixinLib],

  data: () => ({
    imgSrcIndex: 0
  }),

  methods: {
  },

  props: {
    images: {
      type: Array,
      require: true,
      default: () => {
        return []
      }
    },
    products: {
      type: Object,
      require: true,
      default: () => {
        return {
          brand_id: '',
          calorie: '',
          catch_copy: '',
          component_value: '',
          country: '',
          goods_explain: '',
          information: '',
          normal_freeze_status: '',
          outline: '',
          products_name: '',
          remarks: '',
          set_contents: '',
          size_details: '',
          supply_amount: '',
          use_target_status: ''
        }
      }
    },
    productsClass: {
      type: Object,
      require: true,
      default: () => {
        return {
          after_menu_best_before: '',
          after_opening_standard: '',
          color: '',
          expiration_disp_status: '',
          product_code: '',
          sales_limit_unit_buy: '',
          sales_limit_unit_buy_status: '',
          sales_limit_unit_dealing: '',
          sales_limit_unit_dealing_status: '',
          sales_status: '',
          set_status: '',
          size: '',
          term_how_to_read: '',
          term_how_to_write: '',
          term_print_method: '',
          term_print_place: ''
        }
      }
    },
    setCount: {
      type: String,
      require: false,
      default: () => ''
    },
    loading: {
      type: Boolean,
      require: false,
      default: () => false
    }
  }
}
</script>
