<template>
<v-card width="320" color="blue-grey lighten-5">
  <v-card-text  class="pa-1">
    <v-row align="center" justify="center" class="ma-1" no-gutters>
      <v-col cols="12" class="px-3 text-right ec-font-size40" style="padding-top: 60px; padding-bottom: 40px;">
        <span class="font-weight-bold" v-text="num.toLocaleString()"></span>
      </v-col>
      <v-col cols=4 class="pa-1" v-for="(actionButton, index) in listActionButton" :key="index">
        <v-btn block height="60" class="white" outlined v-text="actionButton.text" @click="clickAction(actionButton)"></v-btn>
      </v-col>
    </v-row>
  </v-card-text>
  <v-divider></v-divider>
  <v-card-actions>
    <v-btn color="primary" class="ec-font-size12" text @click="$emit('close')">閉じる</v-btn>
  </v-card-actions>
</v-card>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

export default {
  name: 'ComponentsItemsCalculator',

  mixins: [MixinLib],

  data: () => ({
    num: 0,
    listActionButton: [
      { text: '7', clickAction: (v) => { return (v * 10) + 7 } },
      { text: '8', clickAction: (v) => { return (v * 10) + 8 } },
      { text: '9', clickAction: (v) => { return (v * 10) + 9 } },
      { text: '4', clickAction: (v) => { return (v * 10) + 4 } },
      { text: '5', clickAction: (v) => { return (v * 10) + 5 } },
      { text: '6', clickAction: (v) => { return (v * 10) + 6 } },
      { text: '1', clickAction: (v) => { return (v * 10) + 1 } },
      { text: '2', clickAction: (v) => { return (v * 10) + 2 } },
      { text: '3', clickAction: (v) => { return (v * 10) + 3 } },
      { text: 'C', clickAction: (v) => { return 0 } },
      { text: '0', clickAction: (v) => { return (v * 10) + 0 } },
      { text: '決定' }
    ]
  }),

  watch: {
    inputData () {
      this.num = this.inputData
    }
  },

  computed: {
    inputData: {
      get () {
        return this.model
      },
      set (v) {
        this.$emit('change', v)
      }
    }
  },

  methods: {
    clickAction: function (actionButton) {
      if (actionButton.clickAction) {
        this.num = actionButton.clickAction(this.num)
      } else {
        this.$emit('change', this.num)
      }
    }
  },

  props: {
    model: {
      type: Number,
      require: true,
      default: () => {
        return 0
      }
    }
  }
}
</script>
