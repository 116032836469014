<template>
<v-row v-if="loading">
  <v-col cols=12>
    <v-row align="center" justify="center" no-gutters>
      <v-col cols=12 class="pb-3">
        <v-skeleton-loader class="mx-auto" type="text" height="80"></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-col>
</v-row>
<v-row v-else>
  <v-col cols=12>
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12" class="pb-3 text-left" v-if="!isUndefined(originalProducts.sales_str_date) || !isUndefined(originalProducts.sales_end_date)">
        <v-chip x-small dark class="ma-auto mr-3" disabled label><span>発売期間</span></v-chip>
        <span class="ec-font-size12">
          <span v-text="getDate(originalProducts.sales_str_date,'YYYY.MM.DD から ')" v-show="!isUndefined(originalProducts.sales_str_date)"></span>
          <span v-text="getDate(originalProducts.sales_end_date,'YYYY.MM.DD 迄')" v-show="!isUndefined(originalProducts.sales_end_date)"></span>
        </span>
      </v-col>
      <v-col cols=12 class="pb-3">
        <span class="ec-font-size14 font-weight-bold" v-text="originalProducts.catch_copy"></span>
      </v-col>
      <v-col cols=12 class="pb-5">
        <pre :style="{ whiteSpace: 'pre-wrap' }" class="ec-font-size12" v-text="originalProducts.outline"></pre>
      </v-col>
      <v-col cols=12 class="ec-font-size12" v-if="!isUndefined(originalProducts.information)">
        <v-chip x-small dark color="success" class="ma-auto mb-2" disabled label><span>お知らせ</span></v-chip><br />
        <span v-text="originalProducts.information"></span>
      </v-col>
    </v-row>
  </v-col>
</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

export default {
  name: 'ComponentsViewFormsLimitedProductsDescription',

  mixins: [MixinLib],

  data: () => ({
  }),

  props: {
    originalProducts: {
      type: Object,
      require: true,
      default: () => {
        return {
          brand_id: '',
          catch_copy: '',
          img_src: '',
          information: '',
          outline: '',
          products_name: '',
          sales_end_date: '',
          sales_limit_unit_buy: '',
          sales_limit_unit_buy_status: '',
          sales_limit_unit_dealing: '',
          sales_limit_unit_dealing_status: '',
          sales_status: '',
          sales_str_date: '',
          set_status: ''
        }
      }
    },
    loading: {
      type: Boolean,
      require: false,
      default: () => false
    }
  }
}
</script>
