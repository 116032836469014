<template>
<ComponentsLayoutPage1>
  <v-row align="center" justify="center" class="mx-auto px-5" :style="{ maxWidth: '480px', paddingTop: '30px', paddingBottom: '50px' }">
    <v-col cols=12 class="pb-5">
      <ComponentsLayoutHeading1 text="ログイン"></ComponentsLayoutHeading1>
    </v-col>
    <v-col cols="12">
      <ComponentsItemsTextField
        name="account"
        :value.sync="account"
        :loading="isProcessLoading"
        ref="account"
      ></ComponentsItemsTextField>
    </v-col>
    <v-col cols="12">
      <ComponentsItemsTextFieldTypePassword
        name="password"
        :value.sync="password"
        :loading="isProcessLoading"
        autocomplete="current-password"
        ref="password"
      ></ComponentsItemsTextFieldTypePassword>
    </v-col>
    <v-col cols="12" class="pt-5">
      <ComponentsItemsButton
        block
        text="ログイン"
        @click="goLogin()"
      >
      </ComponentsItemsButton>
    </v-col>
    <v-col cols="12" class="text-center">
      <ComponentsItemsButton
        text-btn
        text="パスワードをお忘れですか？"
        @click="pageTransition('/forget/password')"
      >
      </ComponentsItemsButton>
    </v-col>
    <v-col cols="12">
      <v-divider></v-divider>
    </v-col>
    <v-col cols="12" class="text-center">
      <span>アカウントをお持ちでない場合はこちらから</span>
    </v-col>
    <v-col cols="12" class="text-center">
      <ComponentsItemsButton
        text-btn
        text="お取引のお申込み"
        @click="pageTransition('/entry')"
      >
      </ComponentsItemsButton>
    </v-col>
  </v-row>
</ComponentsLayoutPage1>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import AuthLib from '@/libs/AuthLib'

import ComponentsLayoutPage1 from '@/components/layout/Page1'
// import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
// import ComponentsLayoutHeading3 from '@/components/layout/Heading3'

import ComponentsItemsTextField from '@/components/items/TextField'
import ComponentsItemsTextFieldTypePassword from '@/components/items/TextFieldTypePassword'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'LoginPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false,
    account: '',
    password: ''
  }),

  watch: {
    isSignIn () {
      if (this.isSignIn) {
        this.pageTransition('/')
      }
    }
  },

  mounted () {
    if (this.isSignIn) {
      this.pageTransition('/')
    }
  },

  methods: {
    goLogin: function () {
      (async () => {
        if (this.$refs.account) {
          await this.$refs.account.confirm()
        }
        if (this.$refs.password) {
          await this.$refs.password.confirm()
        }
        if (this.$refs.account && this.$refs.account.error) {
          return false
        }
        if (this.$refs.password && this.$refs.password.error) {
          return false
        }
        this.isLoading = true
        await AuthLib.SignIn(this.account, this.password)
        // this.isLoading = false
      })().catch((e) => {
        this.isLoading = false
        if (e.code === 'NotAuthorizedException') {
          return this.$confirm({ title: 'Error', message: 'メールアドレス、または、パスワードをご確認ください', button: { yes: 'OK' } })
        }
        this.$confirm({ title: 'Error', message: e, button: { yes: 'OK' } })
      })
    }
  },

  components: {
    ComponentsLayoutPage1,
    // ComponentsLayoutSection1,
    ComponentsLayoutHeading1,
    // ComponentsLayoutHeading3,
    ComponentsItemsTextField,
    ComponentsItemsTextFieldTypePassword,
    ComponentsItemsButton
  }
}
</script>
