<template>
<v-card flat color="transparent" class="mx-1 pt-3" :width="width" v-if="loading">
  <v-card flat color="grey lighten-2" class="pa-0 ma-0" :width="width" :height="height"></v-card>
</v-card>
<v-card flat color="transparent" class="mx-1 pt-3" :width="width" v-else>
  <v-card flat color="grey lighten-2" class="pa-0 ma-0" :width="width" :height="height">
    <v-row align="center" justify="center" style="height: 100%" no-gutters>
      <v-col cols="12">
        <v-img :src="originalProducts.img_src" class="mx-auto" style="background-position:center"></v-img>
      </v-col>
    </v-row>
  </v-card>
</v-card>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

export default {
  name: 'ComponentsViewFormsLimitedProductsImage',

  mixins: [MixinLib],

  data: () => ({
    imgSrcIndex: 0
  }),

  methods: {
  },

  props: {
    originalProducts: {
      type: Object,
      require: true,
      default: () => {
        return {
          brand_id: '',
          catch_copy: '',
          img_src: '',
          information: '',
          outline: '',
          products_name: '',
          sales_end_date: '',
          sales_limit_unit_buy: '',
          sales_limit_unit_buy_status: '',
          sales_limit_unit_dealing: '',
          sales_limit_unit_dealing_status: '',
          sales_status: '',
          sales_str_date: '',
          set_status: ''
        }
      }
    },
    loading: {
      type: Boolean,
      require: false,
      default: () => false
    },
    width: [String, Number],
    height: [String, Number]
  }
}
</script>
