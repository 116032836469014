<template>
<v-row align="start" justify="start">
  <v-col :cols="(windowMiddleSize) ? 12 : 3">
    <ComponentsLayoutHeading1 text="配送情報" border-hidden></ComponentsLayoutHeading1>
  </v-col>
  <v-col :cols="(windowMiddleSize) ? 12 : 9" :class="(windowMiddleSize) ? 'pb-5' : ''">
    <v-card :outlined="(windowMiddleSize)" :flat="(!windowMiddleSize)" :class="(windowMiddleSize) ? 'px-2 py-3' : 'pb-3'">
      <v-row align="center" justify="center">
<!--        <v-col cols=12 >-->
<!--          <ComponentsLayoutSection1>-->
<!--            <v-row align="center" justify="center">-->
<!--              <v-col cols=12>-->
<!--                <span v-text="'最短 ' + getFastestDeliveryDateTime() + ' にお届けします'"></span>-->
<!--              </v-col>-->
<!--            </v-row>-->
<!--          </ComponentsLayoutSection1>-->
<!--        </v-col>-->
        <v-col cols=12>
          <ComponentsItemsRadioGroup
            name="shopping_delivery_type"
            :items="CartSelectableDeliveries"
            item-text="name"
            item-value="code"
            :value.sync="DeliveryType"
            :loading="loading || isProcessLoading"
          >
          </ComponentsItemsRadioGroup>
        </v-col>
        <v-col cols=12 v-show="(DeliveryType == 3)">
          <ComponentsLayoutSection1>
            <v-row align="center" justify="center">
              <v-col cols=12>
                <ComponentsLayoutHeading3 text="お届け希望日"></ComponentsLayoutHeading3>
              </v-col>
              <v-col cols=12 class="ec-font-size12">
                <ComponentsItemsSelectField
                  name="shopping_delivery_date"
                  :items="CartSelectableDeliveryDate"
                  item-text="date_text"
                  item-value="date_id"
                  :value.sync="DeliveryDate"
                  :loading="loading || isProcessLoading"
                >
                </ComponentsItemsSelectField>
              </v-col>
              <v-col cols=12>
                <ComponentsLayoutHeading3 text="お届け希望時間"></ComponentsLayoutHeading3>
              </v-col>
              <v-col cols=12 class="ec-font-size12">
                <ComponentsItemsSelectField
                  name="shopping_delivery_time"
                  :items="CartSelectableDeliveryTime"
                  item-text="name"
                  item-value="code"
                  :value.sync="DeliveryTime"
                  :loading="loading || isProcessLoading"
                >
                </ComponentsItemsSelectField>
              </v-col>
            </v-row>
          </ComponentsLayoutSection1>
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsLayoutHeading3 from '@/components/layout/Heading3'

import ComponentsItemsSelectField from '@/components/items/SelectField'
import ComponentsItemsRadioGroup from '@/components/items/RadioGroup'

export default {
  name: 'ComponentsViewFormsShoppingDelivery',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false,
    fastDate: '',
    fastTime: '',
    timeList: {}
    /*
    deliveryDates: [
      { value: '2022-03-10', text: '2022/03/10(木)' }
    ],
    deliveryTimes: [
      { value: '1', text: '午前中' }
    ]
    */
  }),

  mounted: function () {
    this.list = this.$store.getters['modShopping/CartSelectableDeliveryDate']
    this.fastDate = (this.CartSelectableDeliveryDate.length === 0) ? '' : this.CartSelectableDeliveryDate[0].date_id
    this.fastTime = (this.CartSelectableDeliveryDate.length === 0) ? '' : this.CartSelectableDeliveryDate[0].time_id
    this.timeList = this.$store.getters['modShopping/CartSelectableDeliveryTime']
  },

  computed: {
    DeliveryType: {
      get () {
        return this.Cart.delivery_type
      },
      set (v) {
        this.$store.dispatch('modShopping/UpdateShoppingCart', { delivery_type: v })
      }
    },
    DeliveryDate: {
      get () {
        return this.Cart.delivery_date
      },
      set (v) {
        this.$store.dispatch('modShopping/UpdateShoppingCart', { delivery_date: v })
      }
    },
    DeliveryTime: {
      get () {
        return this.Cart.delivery_time
      },
      set (v) {
        this.$store.dispatch('modShopping/UpdateShoppingCart', { delivery_time: v })
      }
    },
    Cart: {
      get () {
        return this.$store.getters['modShopping/Cart']
      }
    },
    CartSelectableDeliveries: {
      get () {
        const deliveries = this.$store.getters['modShopping/CartSelectableDeliveries']
        deliveries.splice(1, 1)
        return deliveries
      }
    },
    CartSelectableDeliveryDate: {
      get () {
        return this.$store.getters['modShopping/CartSelectableDeliveryDate']
      }
    },
    CartSelectableDeliveryTime: {
      get () {
        const deliveryTime = this.$store.getters['modShopping/CartSelectableDeliveryTime']
        console.log(this.fastDate + ' : ' + this.fastTime)
        if (this.DeliveryDate === this.fastDate) {
          if (this.fastTime === '4') {
            deliveryTime.splice(1, 2)
          }
          if (this.fastTime === '5') {
            deliveryTime.splice(1, 3)
          }
          if (this.fastTime === '6') {
            deliveryTime.splice(1, 4)
          }
        }
        return deliveryTime
      }
    }
  },

  watch: {
    CartSelectableDeliveryTime (newValue) {
      console.log(this.DeliveryTime)
      if (this.DeliveryDate === this.fastDate) {
        if (this.DeliveryTime !== '0' && this.DeliveryTime !== '2') {
          if (this.fastTime === '4' && this.DeliveryTime < 4) this.$store.dispatch('modShopping/UpdateShoppingCart', { delivery_time: 0 })
          if (this.fastTime === '5' && this.DeliveryTime < 5) this.$store.dispatch('modShopping/UpdateShoppingCart', { delivery_time: 0 })
          if (this.fastTime === '6' && this.DeliveryTime < 6) this.$store.dispatch('modShopping/UpdateShoppingCart', { delivery_time: 0 })
        }
      }
    }
  },

  methods: {
    getFastestDeliveryDateTime: function () {
      return (this.CartSelectableDeliveryDate.length === 0) ? '' : this.CartSelectableDeliveryDate[0].date_text + ' ' + this.getTimeName(this.CartSelectableDeliveryDate[0].time_id)
    },
    getTimeName: function (TimeID) {
      const index = this.timeList.findIndex(item => item.code === TimeID)
      return (index >= 0) ? this.timeList[index].name : ''
    }
  },

  components: {
    ComponentsLayoutSection1,
    ComponentsLayoutHeading1,
    ComponentsLayoutHeading3,
    ComponentsItemsSelectField,
    ComponentsItemsRadioGroup
  },

  props: {
    loading: {
      type: Boolean,
      require: false,
      default: () => false
    }
  }
}
</script>
