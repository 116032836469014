<template>
<ComponentsLayoutPage1>
  <v-row align="center" justify="center" class="mx-auto px-5" :style="{ maxWidth: '720px', paddingTop: '30px', paddingBottom: '50px' }" no-gutters>
    <v-col cols=12>
      <ComponentsLayoutHeading1 text="パスワードの再発行"></ComponentsLayoutHeading1>
    </v-col>
    <v-col cols=12>
      <v-row align="center" justify="center" :style="{ paddingTop: '30px', paddingBottom: '50px' }" no-gutters>
        <v-col cols=12>
          <span>ご登録時のメールアドレスを入力して「次へ」ボタンをクリックしてください。</span>
        </v-col>
        <v-col cols=12>
          <span>※パスワード再発行メールを送信します。メールの内容をご確認の上、新しいパスワードを登録してください。</span>
        </v-col>
        <v-col cols="12" class="py-5">
          <ComponentsLayoutSection1>
            <v-row align="center" justify="center">
              <v-col cols=12>
                <ComponentsLayoutHeading3 text="メールアドレス" required></ComponentsLayoutHeading3>
              </v-col>
              <v-col cols=12>
                <ComponentsItemsTextField
                  name="account"
                  :value.sync="account"
                  :loading="isProcessLoading"
                  ref="account"
                ></ComponentsItemsTextField>
              </v-col>
            </v-row>
          </ComponentsLayoutSection1>
        </v-col>
        <v-col cols="12" class="text-center py-5">
          <ComponentsItemsButton
            block
            text="次へ"
            @click="goLogin()"
          >
          </ComponentsItemsButton>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</ComponentsLayoutPage1>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutPage1 from '@/components/layout/Page1'
import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsLayoutHeading3 from '@/components/layout/Heading3'

import ComponentsItemsTextField from '@/components/items/TextField'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ForgetPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false,
    account: ''
  }),

  computed: {
    isLogin: {
      get () {
        return this.$store.getters['modSession/isLogin']
      }
    }
  },

  mounted () {
    if (this.isLogin) {
      return this.pageTransition('/')
    }
  },

  methods: {
    goLogin: function () {
      (async () => {
        if (this.$refs.account) {
          await this.$refs.account.confirm()
        }
        if (this.$refs.account && this.$refs.account.error) {
          return false
        }
        this.isProcessLoading = true
        await APILib.getDealingsResetPassword(this.account)
        this.pageTransition('/forget/password/verify')
        this.isProcessLoading = false
      })().catch((e) => {
        this.isProcessLoading = false
        this.$confirm({ title: 'Error', message: 'ご入力のメールアドレスが誤っていないかご確認ください', button: { yes: 'OK' } })
      })
    }
  },

  components: {
    ComponentsLayoutPage1,
    ComponentsLayoutSection1,
    ComponentsLayoutHeading1,
    ComponentsLayoutHeading3,
    ComponentsItemsTextField,
    ComponentsItemsButton
  }
}
</script>
