import axios from 'axios'
import UtilLib from '@/libs/UtilLib'
import AuthLib from '@/libs/AuthLib'
/// import UAParser from 'ua-parser-js'

const ApiLibs = {

  __apiEndPoint: async function (uri) {
    return (await AuthLib.isLogin()) ? process.env.VUE_APP_API_ENDPOINT + uri : process.env.VUE_APP_API_ENDPOINT + uri
  },

  __apiKey: async function () {
    return (await AuthLib.isLogin()) ? process.env.VUE_APP_API_KEY : process.env.VUE_APP_API_KEY
  },

  __getApiLibsCall: async function (uri, httpHeaders) {
    return axios({
      method: 'GET',
      url: await ApiLibs.__apiEndPoint(uri),
      ...httpHeaders
    }).then(function (response) {
      return ApiLibs.getResponse(response, true)
    }).catch(err => {
      console.log(err)
      throw err
    })
  },

  __postApiLibsCall: async function (method, uri, httpHeaders, data, isRes = false) {
    return axios({
      method: method,
      url: await ApiLibs.__apiEndPoint(uri),
      ...httpHeaders,
      data: data,
      validateStatus: function (status) {
        return status === 200 || status === 400 || status === 401 || status === 403 || status === 404 || status === 421 || status === 422 || status === 429
      }
    }).then(function (response) {
      return ApiLibs.getResponse(response, isRes)
    }).catch(err => {
      throw err
    })
  },

  __deleteApiLibsCall: async function (uri, httpHeaders, isRes = false) {
    return axios({
      method: 'DELETE',
      url: await ApiLibs.__apiEndPoint(uri),
      ...httpHeaders
    }).then(function (response) {
      return ApiLibs.getResponse(response, isRes)
    }).catch(err => {
      throw err
    })
  },

  __imageUploadApiLibsCall: async function (uri, httpHeaders, formData) {
    return axios.post(
      await ApiLibs.__apiEndPoint(uri),
      formData,
      ...httpHeaders
    ).then(function (response) {
      return ApiLibs.getResponse(response)
    }).catch(err => {
      throw err
    })
  },

  getRequestHeader: async function (additional = {}, isToken = true, ContentType = 'application/json') {
    const httpHeaders = {
      'x-api-key': await ApiLibs.__apiKey(),
      'Content-Type': ContentType
    }
    if (isToken) {
      const isLogin = await AuthLib.isLogin()
      if (isLogin) {
        httpHeaders.authorization = await AuthLib.getToken()
        if (UtilLib.isUndefined(httpHeaders.authorization)) {
          throw new Error('NoSessionException')
        }
      }
    }
    // if (isToken) {
    //   httpHeaders.authorization = CookieLib.getCookie(process.env.VUE_APP_TOKEN_NAME)
    // }
    return {
      headers: {
        ...httpHeaders,
        ...additional
      }
    }
  },

  getResponse: function (response, isRes = true) {
    if (parseInt(response.status) !== 200) {
      throw new Error(response.status)
    }
    // if (!response.data[status]) {
    //   return (isRes) ? response.data : true
    // }
    if (parseInt(response.data.status) !== 200) {
      // console.log(response.data)
      if (UtilLib.getValue('message', response.data)) {
        throw new Error(response.data.message)
      } else {
        throw new Error(response.data.status)
      }
    }
    return (isRes) ? response.data.response : true
  },

  /************************************************************************
   * 登録情報
   ***********************************************************************/

  // 登録情報の取得
  getUsers: async function () {
    const uri = '/users'
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  // 登録情報の更新
  putUsers: async function (password) {
    const uri = '/users'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__postApiLibsCall('PUT', uri, httpHeaders, { dealings: { password: password } }, true)
  },

  // お届け先一覧情報の取得
  getUsersAddress: async function () {
    const uri = '/users/address'
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  // 取引申請登録
  postUsersApply: async function (list) {
    const uri = '/dealings/apply'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__postApiLibsCall('POST', uri, httpHeaders, { contents: list }, true)
  },

  /**
   * 商品検索
   */
  getUsersProductsSearch: async function (Query = {}, page = 1, limit = 30) {
    const uri = '/users/products?' + Object.keys(Query).map(v => v + '=' + Query[v]).join('&') + '&page=' + page + '&limit=' + limit
    // const uri = '/products?' + Object.keys(Query).map(v => v + '=' + Query[v]).join('&')
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 商品規格検索
   */
  getUsersProductsClassSearch: async function (Query = {}) {
    const uri = '/users/products/class?' + Object.keys(Query).map(v => v + '=' + Query[v]).join('&')
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 商品詳細返却
   */
  getUsersProducts: async function (productsID) {
    const uri = '/users/products/' + productsID
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 商品規格一覧返却
   */
  getUsersProductsClassList: async function (productsID) {
    const uri = '/users/products/' + productsID + '/class'
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 商品規格詳細返却
   */
  getUsersProductsClass: async function (productsID, productsClassID) {
    const uri = '/users/products/' + productsID + '/class/' + productsClassID
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /************************************************************************
   * パスワード再発行
   ***********************************************************************/
  // パスワード再発行申請
  getDealingsResetPassword: async function (mailAddress) {
    const uri = '/dealings/reset-password?mail_address=' + mailAddress
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  // パスワード再発行申請
  putDealingsResetPassword: async function (code) {
    const uri = '/dealings/reset-password?authentication_code=' + code
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__postApiLibsCall('PUT', uri, httpHeaders, {}, true)
  },

  // パスワード再発行申請
  postDealingsResetPassword: async function (code, password) {
    const uri = '/dealings/reset-password?authentication_code=' + code
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__postApiLibsCall('POST', uri, httpHeaders, { password: password }, true)
  },

  /************************************************************************
   * メーカー
   ***********************************************************************/
  /**
   * メーカー一覧返却
   */
  getMakerList: async function () {
    const uri = '/maker'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * メーカー詳細返却
   */
  getMaker: async function (MakerID) {
    const uri = '/maker/' + MakerID
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /************************************************************************
   * ブランドカテゴリ
   ***********************************************************************/
  /**
   * ブランドカテゴリ一覧返却
   */
  getBrandCategories: async function () {
    const uri = '/brand-category'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /************************************************************************
   * ブランド
   ***********************************************************************/
  /**
   * ブランド一覧返却
   */
  getBrandList: async function () {
    const uri = '/brand/sort'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * メーカー詳細返却
   */
  getBrand: async function (BrandID) {
    const uri = '/brand/' + BrandID
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /************************************************************************
   * 商品分類
   ***********************************************************************/
  /**
   * 商品分類一覧返却
   */
  getCategoriesList: async function () {
    const uri = '/categories'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 商品分類返却
   */
  getCategories: async function (CategoriesID) {
    const uri = '/categories/' + CategoriesID
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /************************************************************************
   * 商品
   ***********************************************************************/
  /**
   * 商品検索
   */
  getProductsSearch: async function (Query = {}, page = 1, limit = 30) {
    const uri = '/products?' + Object.keys(Query).map(v => v + '=' + Query[v]).join('&') + '&page=' + page + '&limit=' + limit
    // const uri = '/products?' + Object.keys(Query).map(v => v + '=' + Query[v]).join('&')
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 商品規格検索
   */
  getProductsClassSearch: async function (Query = {}) {
    const uri = '/products/class?' + Object.keys(Query).map(v => v + '=' + Query[v]).join('&')
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 商品詳細返却
   */
  getProducts: async function (productsID) {
    const uri = '/products/' + productsID
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 商品規格一覧返却
   */
  getProductsClassList: async function (productsID) {
    const uri = '/products/' + productsID + '/class'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 商品規格詳細返却
   */
  getProductsClass: async function (productsID, productsClassID) {
    const uri = '/products/' + productsID + '/class/' + productsClassID
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /************************************************************************
   * お気に入り
   ***********************************************************************/

  /**
   * R2限定 お気に入り一覧返却
   */
  getFavoritesOriginals: async function () {
    const uri = '/favorites/originals'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * R2限定 お気に入り登録
   */
  postFavoritesOriginals: async function (OriginalProductsID, contents) {
    const uri = '/favorites/originals/' + OriginalProductsID
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__postApiLibsCall('POST', uri, httpHeaders, { dealings_favorites_original_set: contents }, true)
  },

  /**
   * R2限定 お気に入り削除
   */
  deleteFavoritesOriginals: async function (DealingsFavoritesOriginalSetID) {
    const uri = '/favorites/originals/' + DealingsFavoritesOriginalSetID
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__postApiLibsCall('DELETE', uri, httpHeaders, {}, true)
  },

  /**
   * お気に入り一覧返却
   */
  getFavorites: async function () {
    const uri = '/favorites'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * お気に入り登録
   */
  postFavorites: async function (ProductsID) {
    const uri = '/favorites/' + ProductsID
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__postApiLibsCall('POST', uri, httpHeaders, {}, true)
  },

  /**
   * お気に入り削除
   */
  deleteFavorites: async function (ProductsID) {
    const uri = '/favorites/' + ProductsID
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__postApiLibsCall('DELETE', uri, httpHeaders, {}, true)
  },

  /************************************************************************
   * キャンペーン商品
   ***********************************************************************/
  /**
   * キャンペーン一覧返却
   */
  getCampaignList: async function () {
    const uri = '/campaign/list'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * キャンペーン対象商品一覧返却
   */
  getCampaignListProducts: async function () {
    const uri = '/campaign/list/products'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * キャンペーン詳細返却
   */
  getCampaign: async function (CampaignID) {
    const uri = '/campaign/' + CampaignID
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /************************************************************************
   * R2限定商品
   ***********************************************************************/
  /**
   * R2限定商品一覧返却
   */
  getOriginalProductsList: async function () {
    const uri = '/original-products'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * R2限定商品詳細返却
   */
  getOriginalProducts: async function (OriginalProductsID) {
    const uri = '/original-products/' + OriginalProductsID
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /************************************************************************
   * 註文
   ***********************************************************************/
  /**
   * 註文一覧返却
   */
  postOrderHistorySearch: async function (SearchOptions = {}, page = 1, limit = 30) {
    const uri = '/orders-history/search?page=' + page + '&limit=' + limit
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__postApiLibsCall('POST', uri, httpHeaders, { orders: SearchOptions }, true)
  },

  /**
   * 註文詳細返却
   */
  getOrderHistory: async function (OrderID) {
    const uri = '/orders-history/' + OrderID
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 註文キャンセル
   */
  deleteOrderHistory: async function (OrderID) {
    const uri = '/orders-history/' + OrderID
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__postApiLibsCall('DELETE', uri, httpHeaders, {}, true)
  },

  /************************************************************************
   * 販促
   ***********************************************************************/

  /**
   * 新商品一覧返却
   */
  getNewRelease: async function () {
    const uri = '/new-release'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * おすすめ商品一覧返却
   */
  getIntroduce: async function () {
    const uri = '/introduce'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  // レコメンド商品一覧
  getRecommend: async function () {
    return []
  },

  // 売上ランキング商品一覧
  getRanking: async function () {
    const uri = '/information/ranking'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__postApiLibsCall('GET', uri, httpHeaders, {}, true)
  },

  /**
   * 販促物同梱一覧返却
   */
  getPamphletList: async function () {
    const uri = '/pamphlet'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 販促資料一覧返却
   */
  /*
  getMarketingMaterials: async function () {
    const uri = '/marketing//materials'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },
  */

  /**
   * 販促資料一覧返却
   */
  getMarketingMaterialsCategories: async function (ParentMarketingMaterialsCategoriesID) {
    const uri = '/marketing/materials-categories/' + ParentMarketingMaterialsCategoriesID
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /************************************************************************
   * 設定
   ***********************************************************************/

  /**
   * お問合せ
   */
  putContact: async function (contents) {
    const uri = '/information/contact'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__postApiLibsCall('PUT', uri, httpHeaders, { contents: contents }, true)
  },

  /**
   * お知らせ
   */
  getNews: async function () {
    const uri = '/information/news'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 企業情報返却
   */
  getCompany: async function () {
    const uri = '/information/company'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * プライバシーポリシー返却
   */
  getPolicy: async function () {
    const uri = '/information/policy'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 特定商取引法返却
   */
  getTradelaw: async function () {
    const uri = '/information/tradelaw'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * ショッピングガイド一覧返却
   */
  getGuideList: async function () {
    const uri = '/information/guide'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * ショッピングガイド返却
   */
  getGuide: async function (GuideID) {
    const uri = '/information/guide/' + GuideID
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /************************************************************************
   * 登録情報
   ***********************************************************************/

  /**
   * クレジット返却
   */
  getShoppingPaymentsCredit: async function () {
    const uri = '/shopping/payments/credit'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * クレジット登録
   */
  postShoppingPaymentsCredit: async function (token) {
    const uri = '/shopping/payments/credit?token=' + token
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__postApiLibsCall('POST', uri, httpHeaders, {}, true)
  },

  /**
   * クレジット削除
   */
  deleteShoppingPaymentsCredit: async function (CardSeq) {
    const uri = '/shopping/payments/credit/' + CardSeq
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__postApiLibsCall('DELETE', uri, httpHeaders, {}, true)
  },

  // カート返却
  getShoppingCart: async function () {
    const uri = '/shopping/cart'
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  // カート基本情報更新
  putShoppingCart: async function (contents = {}) {
    const uri = '/shopping/cart'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__postApiLibsCall('PUT', uri, httpHeaders, { dealings_cart: contents }, true)
  },

  // カート商品情報の初期化
  clearShoppingCartProductsSet: async function () {
    const uri = '/shopping/cart/products'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__postApiLibsCall('DELETE', uri, httpHeaders, {}, true)
  },

  // カート商品情報の登録・更新
  putShoppingCartProducts: async function (contents = {}) {
    const uri = '/shopping/cart/products'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__postApiLibsCall('PUT', uri, httpHeaders, { dealings_cart_products: contents }, true)
  },

  // カート商品情報の数量変更
  putShoppingCartProductsSet: async function (OrderItemTypeStatus, ProductsClassID, Quantity) {
    const uri = '/shopping/cart/products/' + OrderItemTypeStatus + '/' + ProductsClassID + '/' + Quantity
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__postApiLibsCall('PUT', uri, httpHeaders, {}, true)
  },

  // カート販促物情報の登録・更新
  putShoppingCartPamphlet: async function (contents = {}) {
    const uri = '/shopping/cart/pamphlet'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__postApiLibsCall('PUT', uri, httpHeaders, { dealings_cart_pamphlet: contents }, true)
  },

  // お届け可能日の返却
  getShoppingCartDeliveryDates: async function () {
    const uri = '/shopping/cart/delivery-dates'
    const httpHeaders = await ApiLibs.getRequestHeader({}, true)
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  // 購入処理
  postShoppingCartComplete: async function () {
    const uri = '/shopping/cart/complete'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__postApiLibsCall('POST', uri, httpHeaders, {}, true)
  },

  /************************************************************************
   * マスターデータ
   ***********************************************************************/

  /**
   * メンテナンスチェック
   */
  getComponentMaintenance: async function () {
    const uri = '/component/maintenance'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 注文ステータス
   */
  getComponentOrderStatus: async function () {
    const uri = '/component/order-status'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 支払方法返却
   */
  getComponentPayments: async function () {
    const uri = '/component/payments'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 都道府県情報返却
   */
  getComponentPrefecture: async function () {
    const uri = '/component/prefecture'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 店舗形態情報返却
   */
  getComponentShopForm: async function () {
    const uri = '/component/shop-form'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
 * 認知経路情報返却
 */
  getComponentRouteMethod: async function () {
    const uri = '/component/route-method'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 注力ブランド情報返却
   */
  getComponentPickupBrand: async function () {
    const uri = '/component/pickup-brand'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 連絡方法情報返却
   */
  getComponentContactMethod: async function () {
    const uri = '/component/contact-method'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  },

  /**
   * 申請フォーム情報返却
   */
  getComponentEntryFormItems: async function () {
    const uri = '/component/entry-form-items'
    const httpHeaders = await ApiLibs.getRequestHeader({})
    return await ApiLibs.__getApiLibsCall(uri, httpHeaders)
  }

}

export default ApiLibs
