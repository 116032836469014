<template>
<ComponentsLayoutPage1>
  <v-row align="start" justify="start" no-gutters>
    <v-col cols=12 class="pt-3 pb-5" v-for="blocks in categories" :key="blocks.id">
      <span class="font-weight-bold" v-text="blocks.name"></span>

      <v-divider></v-divider>

      <v-row align="start" justify="start" class="pt-5" no-gutters>
        <v-col cols=6 sm=4 md=3 lg=3 xl=3 class="py-3 px-5" v-for="item in list.filter(v => v.brand_category_id == blocks.id)" :key="item.id">
          <ComponentsViewFormsSquareBrand
            :img-src="item.img_src"
            :label="item.brand_name"
            @click="pageMove('/productsList?brand=' + item.id)"
          >
          </ComponentsViewFormsSquareBrand>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</ComponentsLayoutPage1>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
// import APILib from '@/libs/APILib'

import ComponentsLayoutPage1 from '@/components/layout/Page1'
import ComponentsViewFormsSquareBrand from '@/components/view-forms/SquareBrand'

export default {
  name: 'BrandPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true
    // categories: [],
    // list: []
  }),

  computed: {
    list: {
      get () {
        return this.$store.getters['modStorage/viewBrands']
      }
    },
    categories: {
      get () {
        return this.$store.getters['modStorage/viewBrandCategories']
      }
    },
    isSearchValid: {
      get () {
        return this.$store.getters['modSearch/isSearchValid']
      },
      set (v) {
        this.$store.dispatch('modSearch/isSearchValid', v)
      }
    },
    isSaveValid: {
      get () {
        return this.$store.getters['modSearch/isSaveValid']
      },
      set (v) {
        this.$store.dispatch('modSearch/isSaveValid', v)
      }
    },
    Category: {
      get () {
        return this.$store.getters['modSearch/Category']
      },
      set (v) {
        this.$store.dispatch('modSearch/Category', v)
      }
    },
    Brand: {
      get () {
        return this.$store.getters['modSearch/Brand']
      },
      set (v) {
        this.$store.dispatch('modSearch/Brand', v)
      }
    },
    Keyword: {
      get () {
        return this.$store.getters['modSearch/Keyword']
      },
      set (v) {
        this.$store.dispatch('modSearch/Keyword', v)
      }
    }
  },

  mounted: function () {
    this.goLoading()
  },

  methods: {
    goLoading: function () {
      (async () => {
        this.isProcessLoading = true
        await this.$store.dispatch('modStorage/LoadBrand')
        await this.$store.dispatch('modStorage/LoadBrandCategory')
        this.isProcessLoading = false
      })().catch((e) => {
        console.log(e)
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    },
    pageMove (v) {
      this.isSearchValid = 'true'
      this.isSaveValid = ''
      this.Category = ''
      this.Brand = ''
      this.Keyword = ''
      this.isSearchValid = ''
      this.pageTransition(v)
    }
  },

  components: {
    ComponentsLayoutPage1,
    ComponentsViewFormsSquareBrand
  }
}
</script>
