<template>
<v-card rounded flat class="rounded-lg" :color="secondaryColor">
  <v-card-text class="ec-font-size12" :style="{ color: tertiaryFontColor }">
    <slot></slot>
  </v-card-text>
</v-card>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

export default {
  name: 'ComponentsLayoutSection1',

  mixins: [MixinLib],

  props: {
  }
}
</script>
