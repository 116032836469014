<template>
<v-row align="start" justify="start">
  <v-col :cols="(windowMiddleSize) ? 12 : 3">
    <ComponentsLayoutHeading1 text="お支払い情報" border-hidden></ComponentsLayoutHeading1>
  </v-col>
  <v-col :cols="(windowMiddleSize) ? 12 : 9">
    <v-card :outlined="(windowMiddleSize)" :flat="(!windowMiddleSize)" :class="(windowMiddleSize) ? 'px-2 py-3' : 'pb-3'">
      <v-row :align="(windowMiddleSize) ? 'center' : 'start'" justify="start">
        <v-col cols=9>
          <v-row no-gutters>
            <v-col cols=12 class="ec-font-size16">
              <span v-text="getPaymentName()"></span>
            </v-col>
            <v-col cols=12 class="ec-font-size12" v-show="isCredits">
              <v-row no-gutters v-if="isUndefined(cards)">
                <v-col cols=12 class="pt-3">
                  <v-alert dense outlined type="error" class="mb-0">
                    <span class="ec-font-size12">クレジットカードが選択されていません</span>
                  </v-alert>
                </v-col>
              </v-row>
              <v-row no-gutters v-else>
                <v-col cols=12>
                  <span v-text="cards.CardNo"></span>&nbsp;<span class="ec-font-size10">( 有効期限: <span v-text="cards.Expire.substr(0,2)"></span>/<span v-text="cards.Expire.substr(2,2)"></span> )</span><br />
                  <span v-text="CreditPaymentMethodsName()"></span>&nbsp;<span v-text="CreditPaymentTimesName()"></span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols=12 class="ec-font-size12">
              <span></span>
            </v-col>
          </v-row>
          <!--
          <v-row no-gutters>
            <v-col cols=12 class="ec-font-size16 font-weight-bold">
              <span>クレジット</span>
            </v-col>
            <v-col cols=12 class="ec-font-size12">
              <span>****-****-****-0123</span>
            </v-col>
            <v-col cols=12 class="ec-font-size12">
              <span>1回払い</span>
            </v-col>
          </v-row>
          --->
        </v-col>
        <v-col cols=3 class="text-right" v-if="windowMiddleSize">
          <ComponentsItemsButton
            icon-btn
            icon="mdi-arrow-right"
            color="red"
            :loading="loading || isProcessLoading"
            @click="$emit('click')"
          >
          </ComponentsItemsButton>
        </v-col>
        <v-col cols=3 class="text-right" v-else>
          <ComponentsItemsButton
            text-btn
            color="red"
            text="変更"
            :loading="loading || isProcessLoading"
            @click="$emit('click')"
          >
          </ComponentsItemsButton>
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ComponentsViewFormsShoppingPayment',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false
  }),

  computed: {
    isCredits: {
      get () {
        return (this.Cart.payment_id === process.env.VUE_APP_PAYMENTS_CREDITS)
      }
    },
    PaymentID: {
      get () {
        return this.Cart.payment_id
      },
      set (v) {
        this.$store.dispatch('modShopping/UpdateShoppingCart', { payment_id: v })
      }
    },
    Cart: {
      get () {
        return this.$store.getters['modShopping/Cart']
      }
    },
    CartSelectablePayments: {
      get () {
        return this.$store.getters['modShopping/CartSelectablePayments']
      }
    },
    credits: {
      get () {
        return this.$store.getters['modStorage/PaymentsCredit']
      }
    },
    cards: {
      get () {
        return this.credits.find(item => item.CardSeq === this.Cart.credit_seq) || {}
      }
    }
  },

  methods: {
    getPaymentName: function () {
      const index = this.CartSelectablePayments.findIndex(item => item.code === this.PaymentID)
      return (index >= 0) ? this.CartSelectablePayments[index].name : ''
    },
    CreditPaymentMethodsName: function () {
      const list = this.commonCreditPaymentMethods()
      const index = list.findIndex(item => item.code === this.Cart.credit_method)
      return (index >= 0) ? list[index].name : ''
    },
    CreditPaymentTimesName: function () {
      const list = this.commonCreditPaymentTimes()
      const index = list.findIndex(item => item.code === this.Cart.credit_times)
      return (index >= 0) ? list[index].name : ''
    }
  },

  components: {
    ComponentsLayoutHeading1,
    ComponentsItemsButton
  },

  props: {
    loading: {
      type: Boolean,
      require: false,
      default: () => false
    }
  }
}
</script>
