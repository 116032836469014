<template>
<v-app :style="(windowMiddleSize) ? 'background-color:rgba(var(--b3f,250,250,250),1);' : 'background-color: white;'" >

  <div id="scrollTop"></div>

  <!-- v-system-bar -->
  <v-system-bar fixed flat app :color="(windowSpSize) ? '#EEEEEE' : 'white'">
    <v-row align="center" justify="center" class="ma-auto ma-0 pa-0" style="max-width: 1024px;">
      <v-col cols=12 :class="(windowSpSize) ? 'ma-0 pa-0 text-center caption' : 'ma-0 pa-0'">
        <span >ペットフード・グッズの店舗主様向けの卸専門ショップ</span>
      </v-col>
    </v-row>
  </v-system-bar>

  <!-- header -->
  <v-app-bar fixed flat app color="white" class="mx-auto" height="80" style="max-width: 1024px;">

    <v-app-bar-nav-icon v-if="(windowMiddleSize)" @click="navigationStatus = true"></v-app-bar-nav-icon>

    <v-toolbar-title >
      <router-link to="/">
        <v-img :src="require('@/assets/logo.png')" style="max-width: 200px; height: auto;"></v-img>
      </router-link>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <v-text-field
      v-model="productSearchKeyword"
      filled
      rounded
      dense
      hide-details
      single-line
      placeholder="キーワードを入力"
      append-icon="mdi-magnify"
      v-if="(!windowMiddleSize)"
      @click:append="pageMove(`/productsList?keyword=${productSearchKeyword}`, productSearchKeyword), productSearchKeyword = ''"
      @keydown.enter="pageMove(`/productsList?keyword=${productSearchKeyword}`, productSearchKeyword), productSearchKeyword = ''"
    ></v-text-field>

    <v-spacer></v-spacer>

    <ComponentsItemsTextIconButton
      class="pt-3"
      :icon="item.icon"
      :text="item.text"
      :color="item.color"
      @click="pageTransition(item.route)"
      v-for="item in globalNavigation"
      :key="item.text"
      v-show="(!windowMiddleSize)"
      :loading="isLoading"
    >
    </ComponentsItemsTextIconButton>

    <v-row align="center" justify="center" class="text-right" no-gutters style="width: 420px; line-height: 0.9;" v-if="(windowMiddleSize) && (windowWidthSize >= 420)">
      <v-col cols=12>
        <a :href="'tel:' + Company.tel" style="text-decoration: none;">
          <v-icon color="R2MainColorLight" small left>mdi-phone-in-talk</v-icon><span class="R2MainColorLight--text ec-font-size10 font-weight-black" v-text="Company.tel"></span>
        </a>
        <br />
        <span class="ec-font-size10" v-text="companyProfile.comment1"></span><br />
        <span class="ec-font-size10" v-text="companyProfile.comment2"></span>
      </v-col>
    </v-row>
  </v-app-bar>

  <!-- v-navigation-drawer -->
  <v-navigation-drawer fixed clipped touchless style="top:0; background-color: rgba(66,66,66,0.95); width: 320px;" v-model="navigationStatus" >
    <v-toolbar color="transparent" flat dark >
      <v-spacer></v-spacer>
      <v-btn icon  @click="navigationStatus = false">
        <v-icon color="grey lighten-2">mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-list dense nav >
      <v-list-item @click="pageTransition(item.route), navigationStatus = false" :key="'i-' + index" v-for="(item, index) in navigationItems">
        <v-list-item-icon>
          <v-icon color="grey" v-text="item.icon"></v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title><span class="grey--text text--lighten-1" v-text="item.text"></span></v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="SignOut()" v-if="isSignIn">
        <v-list-item-icon>
          <v-icon color="grey">mdi-lock</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title><span class="grey--text text--lighten-1">ログアウト</span></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>

  <v-main>
    <v-container fluid>

      <!-- navigation -->
      <v-row align="center" justify="center" v-if="(!windowMiddleSize)">
        <v-col cols=12 class="pt-0 px-0">
          <v-card class="mx-auto" flat tile color="R2FooterColor" width="100vw" style="z-index: 5; position: fixed;">
            <v-toolbar color="transparent" class="mx-auto" flat >
              <v-row class="mx-auto" style="max-width: 1024px;">
                <v-col class="text-center">
                  <v-btn v-for="item in globalItems" :key="item.text" plain @click="pageTransition(item.route)">
                    <v-icon dense small color="R2MainColor">mdi-arrow-right-drop-circle</v-icon>&nbsp;
                    <span class="ec-font-size12 font-weight-bold" v-text="item.text"></span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </v-card>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" v-else>
        <v-col cols=12 class="pt-0 px-0">
          <v-card class="mx-auto" flat tile color="R2FooterColor" width="100vw" style="z-index: 5; position: fixed;">
            <v-card-text class="py-2">
              <v-toolbar color="transparent" flat>
                <ComponentsItemsTextIconButton
                  :icon="item.icon"
                  :text="item.text"
                  :color="item.color"
                  @click="pageTransition(item.route)"
                  v-for="item in globalNavigation"
                  :key="item.text"
                  class="mx-auto"
                >
                </ComponentsItemsTextIconButton>
              </v-toolbar>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- contents -->
      <v-row align="center" justify="center" class="mx-auto" :style="mainRouterStyle">
        <v-col cols=12 class="pa-0">

          <v-row>
            <v-col>  <v-breadcrumbs :items="breadcrumbs" divider="-" class="py-1" :style="{ paddingLeft: '1px' }"></v-breadcrumbs>  </v-col>
            <v-col>
              <v-text-field
                v-model="productSearchKeyword2"
                filled
                rounded
                dense
                hide-details
                single-line
                prepend-inner-icon="mdi-magnify"
                placeholder="商品名・JANコード"
                @click:prepend-inner="$emit('change', productSearchKeyword2)"
                @keydown.enter="$emit('change', productSearchKeyword2)"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols=12 class="px-0 ec-font-size14">
          <slot></slot>
        </v-col>
      </v-row>

      <v-row align="center" justify="center">
        <v-col cols=12 class="pa-0">
          <ComponentsLayoutExtensionFooter></ComponentsLayoutExtensionFooter>
        </v-col>
      </v-row>

      <transition name="fade">
        <v-btn fixed fab bottom right color="#BDBDBDAA" style="bottom: 50px" v-show="this.showFloatingAction" v-scroll-to="'#scrollTop'">
          <v-icon color="white">mdi-chevron-up</v-icon>
        </v-btn>
      </transition>

    </v-container>
  </v-main>

  <v-footer absolute app color="R2FooterColor" padless height="40">
    <v-row no-gutters>
      <v-col cols="12" class="py-2 text-center">
        <span class="'mx-auto ec-font-size10" >copyright (c) R2(アール・ツー) all rights reserved.</span>
      </v-col>
    </v-row>
  </v-footer>

  <!-- <vue-confirm-dialog></vue-confirm-dialog> --->

</v-app>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsItemsTextIconButton from '@/components/items/TextIconButton'
import ComponentsLayoutExtensionFooter from '@/components/layout/ExtensionFooter'

export default {
  name: 'ComponentsLayoutPage1',

  mixins: [MixinLib],

  data: () => ({
    productSearchKeyword: '',
    productSearchKeyword2: '',
    companyProfile: {
      comment1: 'お電話受付：10:00～18:00(土日祝を除く)',
      comment2: 'ウェブからのご注文は営業日時外も承っております'
    }
  }),

  computed: {
    Company: {
      get () {
        return this.$store.getters['modStorage/Company']
      }
    },
    navigationStatus: {
      get () {
        return this.$store.getters['modSession/navigationStatus']
      },
      set (v) {
        this.$store.dispatch('modSession/navigationStatus', v)
      }
    },
    pageShowNavigation: {
      get () {
        return this.$store.getters['modPage/pageShowNavigation']
      }
    },
    breadcrumbs: {
      get () {
        return this.$store.getters['modPage/breadcrumbs']
      }
    },
    mainRouterStyle: {
      get () {
        if (this.pageShowNavigation) {
          if (this.windowMiddleSize) {
            return {
              maxWidth: '1024px',
              marginBottom: '20px',
              paddingTop: '80px'
              // paddingTop: '128px'
            }
          }
          // if (this.$vuetify.breakpoint.sm) {
          //   return {
          //     maxWidth: '1024px',
          //     marginBottom: '20px',
          //     paddingTop: '72px'
          //   }
          // }
          return {
            maxWidth: '1024px',
            marginBottom: '20px',
            paddingTop: '80px'
          }
        }
        return {
          maxWidth: '1024px',
          marginBottom: '20px',
          paddingTop: '60px'
        }
      }
    },
    globalItems: {
      get () {
        if (this.isSignIn) {
          return [
            {
              route: '/categories',
              text: 'カテゴリから探す'
            },
            {
              route: '/brands',
              text: 'ブランドから探す'
            },
            {
              route: '/campaign',
              text: 'キャンペーン'
            },
            /*
            {
              route: '/limited-products',
              text: 'R2限定商品'
            },
            */
            {
              route: '/myPage/downloads',
              text: '画像・販促用資料'
            },
            {
              route: 'https://news.r2-pet.com',
              text: 'お知らせ'
            }
          ]
        } else {
          return [
            {
              route: '/categories',
              text: 'カテゴリから探す'
            },
            {
              route: '/brands',
              text: 'ブランドから探す'
            }
          ]
        }
      }
    },
    globalNavigation: {
      get () {
        if (this.windowMiddleSize) {
          if (this.isSignIn) {
            return [
              {
                route: '/search',
                icon: 'mdi-magnify',
                color: '',
                text: '商品検索'
              },
              {
                route: '/contact',
                icon: 'mdi-help-circle-outline',
                color: '',
                text: 'お問合せ'
              },
              {
                route: '/myPage/orderHistory',
                icon: 'mdi-clock-time-nine-outline',
                color: '',
                text: '注文履歴'
              },
              {
                route: '/shopping',
                icon: 'mdi-cart-outline',
                color: '#DE5D50',
                text: '購買リスト'
              }
            ]
          } else {
            return [
              {
                route: '/contact',
                icon: 'mdi-help-circle-outline',
                color: '',
                text: 'お問合せ'
              },
              {
                route: '/login',
                icon: 'mdi-account-circle-outline',
                color: '',
                text: 'ログイン'
              },
              {
                route: '/entry',
                icon: 'mdi-account-plus',
                color: '#DE5D50',
                text: '新規取引申請'
              }
            ]
          }
        } else {
          if (!this.isSignIn) {
            return [
              {
                route: '/contact',
                icon: 'mdi-help-circle-outline',
                color: '',
                text: 'お問合せ'
              },
              {
                route: '/login',
                icon: 'mdi-account-circle-outline',
                color: '',
                text: 'ログイン'
              },
              {
                route: '/entry',
                icon: 'mdi-account-plus',
                color: '#DE5D50',
                text: '新規取引申請'
              }
            ]
          } else {
            return [
              {
                route: '/contact',
                icon: 'mdi-help-circle-outline',
                color: '',
                text: 'お問合せ'
              },
              {
                route: '/myPage',
                icon: 'mdi-account',
                color: '',
                text: 'マイページ'
              },
              {
                route: '/myPage/orderHistory',
                icon: 'mdi-clock-time-nine-outline',
                color: '',
                text: '注文履歴'
              },
              {
                route: '/shopping',
                icon: 'mdi-cart-outline',
                color: '#DE5D50',
                text: '購買リスト'
              }
            ]
          }
        }
      }
    },
    navigationItems: {
      get () {
        var items = []
        if (this.isSignIn) {
          items.push(
            {
              route: '/myPage',
              icon: 'mdi-account-circle',
              text: 'マイページ'
            },
            {
              route: '/categories',
              icon: 'mdi-magnify',
              text: 'カテゴリから探す'
            },
            {
              route: '/brands',
              icon: 'mdi-magnify',
              text: 'ブランドから探す'
            },
            {
              route: '/campaign/campaign/list',
              icon: 'mdi-star',
              text: 'キャンペーン'
            },
            {
              route: '/campaign/limited/list',
              icon: 'mdi-star',
              text: 'R2限定商品'
            },
            {
              route: 'https://news.r2-pet.com',
              icon: 'mdi-book-open-page-variant-outline',
              text: 'お知らせ'
            }
          )
        } else {
          items.push(
            {
              route: '/login',
              icon: 'mdi-account-circle-outline',
              text: 'ログイン'
            },
            {
              route: '/categories',
              icon: 'mdi-magnify',
              text: 'カテゴリから探す'
            },
            {
              route: '/brands',
              icon: 'mdi-magnify',
              text: 'ブランドから探す'
            }
          )
        }

        items = [
          ...items,
          /*
          {
            route: '/',
            icon: 'mdi-help-circle',
            text: '営業日カレンダー'
          },
          */
          {
            route: '/guide',
            icon: 'mdi-help-circle',
            text: 'ショッピングガイド'
          },
          {
            route: '/contact',
            icon: 'mdi-help-circle',
            text: 'お問合せ'
          },
          {
            route: '/company',
            icon: 'mdi-information-outline',
            text: '会社概要'
          },
          {
            route: '/policy',
            icon: 'mdi-information-outline',
            text: 'プライバシーポリシー'
          },
          {
            route: '/trade-law',
            icon: 'mdi-information-outline',
            text: '特定商取引法に基づく表記'
          }
        ]
        return items
      }
    },
    showFloatingAction: {
      get () {
        return (window.pageYOffset >= 32)
      }
    },
    isSearchValid: {
      get () {
        return this.$store.getters['modSearch/isSearchValid']
      },
      set (v) {
        this.$store.dispatch('modSearch/isSearchValid', v)
      }
    },
    isSaveValid: {
      get () {
        return this.$store.getters['modSearch/isSaveValid']
      },
      set (v) {
        this.$store.dispatch('modSearch/isSaveValid', v)
      }
    },
    Category: {
      get () {
        return this.$store.getters['modSearch/Category']
      },
      set (v) {
        this.$store.dispatch('modSearch/Category', v)
      }
    },
    Brand: {
      get () {
        return this.$store.getters['modSearch/Brand']
      },
      set (v) {
        this.$store.dispatch('modSearch/Brand', v)
      }
    },
    Keyword: {
      get () {
        return this.$store.getters['modSearch/Keyword']
      },
      set (v) {
        this.$store.dispatch('modSearch/Keyword', v)
      }
    }
  },

  mounted: function () {
  },

  methods: {
    pageMove (v, Keyword) {
      this.isSearchValid = 'true'
      this.isSaveValid = ''
      this.Category = ''
      this.Brand = ''
      this.Keyword = Keyword
      this.isSearchValid = ''
      this.pageTransition(v)
    }
  },

  components: {
    ComponentsItemsTextIconButton,
    ComponentsLayoutExtensionFooter
  }
}
</script>
<style >
.v-breadcrumbs li {
  font-size: 0.8em;
}
</style>
