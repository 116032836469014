<template>
<ComponentsLayoutPage1>
  <v-row align="center" justify="center" class="mx-auto px-5" :style="{ maxWidth: '720px', paddingTop: '30px', paddingBottom: '50px' }" no-gutters>
    <v-col cols=12>
      <ComponentsLayoutHeading1 text="パスワードの再発行"></ComponentsLayoutHeading1>
    </v-col>
    <v-col cols=12>
      <v-row align="center" justify="center" :style="{ paddingTop: '30px' }" no-gutters>
        <v-col cols="12" class="py-5">
          <ComponentsLayoutSection1>
            <v-row align="center" justify="center">
              <v-col cols=12>
                <span>パスワード再発行メールに記載された認証番号をご入力ください</span>
              </v-col>
              <v-col cols=12>
                <ComponentsItemsTextField
                  name="verify_code"
                  :value.sync="verifyCode"
                  :loading="isProcessLoading"
                  ref="verify_code"
                ></ComponentsItemsTextField>
              </v-col>
            </v-row>
          </ComponentsLayoutSection1>
        </v-col>
        <v-col cols="12" class="text-center py-5">
          <ComponentsItemsButton
            block
            text="次に進む"
            @click="onFinish()"
          >
          </ComponentsItemsButton>
        </v-col>
        <v-col cols="12" class="text-center py-5">
          <ComponentsItemsButton
            block
            outlined
            text="戻る"
            @click="pageTransition('/forget/password/')"
          >
          </ComponentsItemsButton>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</ComponentsLayoutPage1>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutPage1 from '@/components/layout/Page1'
import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'

import ComponentsItemsTextField from '@/components/items/TextField'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ForgetAuthenticationVerifyPagePage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false,
    verifyCode: ''
  }),

  mounted () {
    if (this.isSignIn) {
      this.pageTransition('/')
    }
  },

  methods: {
    onFinish: function () {
      (async () => {
        if (this.$refs.verify_code) {
          await this.$refs.verify_code.confirm()
        }
        if (this.$refs.verify_code && this.$refs.verify_code.error) {
          return false
        }
        this.isProcessLoading = true
        await APILib.putDealingsResetPassword(this.verifyCode)
        this.pageTransition('/forget/password/reset', null, { code: this.verifyCode })
        this.isProcessLoading = false
      })().catch((e) => {
        this.isProcessLoading = false
        this.$confirm({ title: 'Error', message: '認証番号が誤っていないかご確認ください', button: { yes: 'OK' } })
      })
    }
  },

  components: {
    ComponentsLayoutPage1,
    ComponentsLayoutSection1,
    ComponentsLayoutHeading1,
    ComponentsItemsTextField,
    ComponentsItemsButton
  }
}
</script>
