import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'

// デザインチェック用
import DesignPage from '@/views/DesignPage.vue'

import TopPage from '@/views/TopPage.vue'
// import TopPage2 from '@/views/TopPage2.vue'
import LoginPage from '@/views/LoginPage.vue'
import CategoryPage from '@/views/CategoryPage.vue'
import BrandPage from '@/views/BrandPage.vue'
import CampaignPage from '@/views/CampaignPage.vue'

// キャンペーン
import CampaignCampaignListPage from '@/views/Campaign/CampaignListPage.vue'
import CampaignCampaignContentsPage from '@/views/Campaign/CampaignContentsPage.vue'

// R2限定商品
import CampaignLimitedListPage from '@/views/Campaign/LimitedListPage.vue'
import CampaignLimitedContentsPage from '@/views/Campaign/LimitedContentsPage.vue'

// 新規取引申請
import EntryPage from '@/views/EntryPage.vue'
import EntryApplyPage from '@/views/Entry/ApplyPage.vue'
import EntryConfirmPage from '@/views/Entry/ConfirmPage.vue'
import EntryCompletePage from '@/views/Entry/CompletePage.vue'

// お問合せ
import ContactPage from '@/views/ContactPage.vue'

// パスワードの再発行
import ForgetPage from '@/views/ForgetPage.vue'
import ForgetAuthenticationVerifyPagePage from '@/views/Forget/AuthenticationVerifyPage.vue'
import ForgetPasswordResetPage from '@/views/Forget/ResetPage.vue'

//
import GuidePage from '@/views/GuidePage.vue'
import InformationCompanyPage from '@/views/Information/CompanyPage.vue'
import InformationPolicyPage from '@/views/Information/PolicyPage.vue'
import InformationTradelawPage from '@/views/Information/TradelawPage.vue'

import ShoppingPage from '@/views/ShoppingPage.vue'
import ShoppingShippingPage from '@/views/Shopping/ShippingPage.vue'
import ShoppingConfirmPage from '@/views/Shopping/ConfirmPage.vue'
import ShoppingPaymentPage from '@/views/Shopping/PaymentPage.vue'
import ShoppingCompletePage from '@/views/Shopping/CompletePage.vue'

import ProductsListPage from '@/views/ProductsListPage.vue'
import ProductsPage from '@/views/ProductsPage.vue'

import MyPagePage from '@/views/MyPage.vue'
import MyPageOrderHistoryPage from '@/views/MyPage/OrderHistoryPage.vue'
import MyPageProfilePage from '@/views/MyPage/ProfilePage.vue'
import MyPageCreditPage from '@/views/MyPage/CreditPage.vue'
import MyPageCreateCreditPage from '@/views/MyPage/CreateCreditPage.vue'
import MyPageDownloadsPage from '@/views/MyPage/DownloadsPage.vue'
import MyPagePasswordPage from '@/views/MyPage/PasswordPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'TopPage',
    component: TopPage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'R2(アール・ツー) / TOPページ「ペットフード・グッズの店舗主様向けの卸専門ショップ」',
        desc: '',
        frame_common_header: true,
        frame_navigation: true,
        frame_common_footer: true,
        frame_extension_footer: true,
        breadcrumbs: [
          {
            text: 'TOP',
            disabled: true,
            href: '/'
          }
        ]
      }
    }
  },
  {
    path: '/design',
    name: 'DesignPage',
    component: DesignPage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'R2(アール・ツー) / TOPページ「ペットフード・グッズの店舗主様向けの卸専門ショップ」',
        desc: '',
        breadcrumbs: [
          {
            text: 'TOP',
            disabled: true,
            href: '/'
          }
        ]
      }
    }
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'R2(アール・ツー) / TOPページ「ペットフード・グッズの店舗主様向けの卸専門ショップ」',
        desc: '',
        frame_common_header: true,
        frame_navigation: true,
        frame_common_footer: true,
        frame_extension_footer: false,
        breadcrumbs: [
          {
            text: 'TOP',
            disabled: false,
            href: '/'
          },
          {
            text: 'ログイン',
            disabled: true,
            href: '/login'
          }
        ]
      }
    }
  },
  {
    path: '/categories',
    name: 'CategoryPage',
    component: CategoryPage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'R2(アール・ツー) / TOPページ「ペットフード・グッズの店舗主様向けの卸専門ショップ」',
        desc: '',
        frame_common_header: true,
        frame_navigation: true,
        frame_common_footer: true,
        frame_extension_footer: true,
        breadcrumbs: [
          {
            text: 'TOP',
            disabled: false,
            href: '/'
          },
          {
            text: 'カテゴリから探す',
            disabled: true,
            href: '/categories'
          }
        ]
      }
    }
  },
  {
    path: '/brands',
    name: 'BrandPage',
    component: BrandPage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'R2(アール・ツー) / TOPページ「ペットフード・グッズの店舗主様向けの卸専門ショップ」',
        desc: '',
        frame_common_header: true,
        frame_navigation: true,
        frame_common_footer: true,
        frame_extension_footer: true,
        breadcrumbs: [
          {
            text: 'TOP',
            disabled: false,
            href: '/'
          },
          {
            text: 'ブランドから探す',
            disabled: true,
            href: '/categories'
          }
        ]
      }
    }
  },
  // --------------------------------------------------------
  // キャンペーン
  // --------------------------------------------------------
  {
    path: '/campaign',
    name: 'CampaignPage',
    component: CampaignPage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'R2(アール・ツー) / TOPページ「ペットフード・グッズの店舗主様向けの卸専門ショップ」',
        desc: '',
        frame_common_header: true,
        frame_navigation: true,
        frame_common_footer: true,
        frame_extension_footer: true,
        breadcrumbs: [
          {
            text: 'TOP',
            disabled: false,
            href: '/'
          },
          {
            text: 'キャンペーン',
            disabled: true,
            href: '/campaign'
          }
        ]
      }
    }
  },
  {
    path: '/campaign/campaign/list',
    name: 'CampaignCampaignListPage',
    component: CampaignCampaignListPage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'R2(アール・ツー) / TOPページ「ペットフード・グッズの店舗主様向けの卸専門ショップ」',
        desc: '',
        frame_common_header: true,
        frame_navigation: true,
        frame_common_footer: true,
        frame_extension_footer: true,
        breadcrumbs: [
          {
            text: 'TOP',
            disabled: false,
            href: '/'
          },
          {
            text: 'キャンペーン',
            disabled: false,
            href: '/campaign'
          },
          {
            text: 'キャンペーン一覧',
            disabled: true,
            href: '/campaign/campaign/list'
          }
        ]
      }
    }
  },
  {
    path: '/campaign/campaign/contents/:CampaignID',
    name: 'CampaignCampaignContentsPage',
    component: CampaignCampaignContentsPage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'R2(アール・ツー) / TOPページ「ペットフード・グッズの店舗主様向けの卸専門ショップ」',
        desc: '',
        frame_common_header: true,
        frame_navigation: true,
        frame_common_footer: true,
        frame_extension_footer: true,
        breadcrumbs: [
          {
            text: 'TOP',
            disabled: false,
            href: '/'
          },
          {
            text: 'キャンペーン',
            disabled: false,
            href: '/campaign'
          },
          {
            text: 'キャンペーン一覧',
            disabled: false,
            href: '/campaign/campaign/list'
          },
          {
            text: '内容説明',
            disabled: true,
            href: '/campaign/campaign/contents/:CampaignID'
          }
        ]
      }
    }
  },
  {
    path: '/campaign/limited/list',
    name: 'CampaignLimitedListPage',
    component: CampaignLimitedListPage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'R2(アール・ツー) / TOPページ「ペットフード・グッズの店舗主様向けの卸専門ショップ」',
        desc: '',
        frame_common_header: true,
        frame_navigation: true,
        frame_common_footer: true,
        frame_extension_footer: true,
        breadcrumbs: [
          {
            text: 'TOP',
            disabled: false,
            href: '/'
          },
          {
            text: 'キャンペーン',
            disabled: false,
            href: '/campaign'
          },
          {
            text: 'R2限定一覧',
            disabled: true,
            href: '/campaign/limited/list'
          }
        ]
      }
    }
  },
  {
    path: '/campaign/limited/contents/:OriginalProductsID',
    name: 'CampaignLimitedContentsPage',
    component: CampaignLimitedContentsPage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'R2(アール・ツー) / TOPページ「ペットフード・グッズの店舗主様向けの卸専門ショップ」',
        desc: '',
        frame_common_header: true,
        frame_navigation: true,
        frame_common_footer: true,
        frame_extension_footer: true,
        breadcrumbs: [
          {
            text: 'TOP',
            disabled: false,
            href: '/'
          },
          {
            text: 'キャンペーン',
            disabled: false,
            href: '/campaign'
          },
          {
            text: 'R2限定一覧',
            disabled: false,
            href: '/campaign/limited/list'
          },
          {
            text: '内容説明',
            disabled: true,
            href: '/campaign/limited/contents/:limitedProductsCode'
          }
        ]
      }
    }
  },
  // --------------------------------------------------------
  // 商品検索
  // --------------------------------------------------------
  {
    path: '/productsList',
    name: 'ProductsListPage',
    component: ProductsListPage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'R2(アール・ツー) / TOPページ「ペットフード・グッズの店舗主様向けの卸専門ショップ」',
        desc: '',
        frame_common_header: true,
        frame_navigation: true,
        frame_common_footer: true,
        frame_extension_footer: true,
        breadcrumbs: [
          {
            text: 'TOP',
            disabled: false,
            href: '/'
          },
          {
            text: '商品検索',
            disabled: true,
            href: '/productsList'
          }
        ]
      }
    }
  },
  {
    path: '/products/:productCode',
    name: 'ProductsPage',
    component: ProductsPage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'R2(アール・ツー) / TOPページ「ペットフード・グッズの店舗主様向けの卸専門ショップ」',
        desc: '',
        frame_common_header: true,
        frame_navigation: true,
        frame_common_footer: true,
        frame_extension_footer: true,
        breadcrumbs: [
          {
            text: 'TOP',
            disabled: false,
            href: '/'
          },
          {
            text: '商品詳細',
            disabled: true,
            href: '/productsList'
          }
        ]
      }
    }
  },
  // --------------------------------------------------------
  // パスワードをお忘れの方
  // --------------------------------------------------------
  {
    path: '/forget/password',
    name: 'ForgetPage',
    component: ForgetPage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'R2(アール・ツー) / TOPページ「ペットフード・グッズの店舗主様向けの卸専門ショップ」',
        desc: '',
        frame_common_header: true,
        frame_navigation: true,
        frame_common_footer: true,
        frame_extension_footer: true,
        breadcrumbs: [
          {
            text: 'TOP',
            disabled: false,
            href: '/'
          },
          {
            text: 'パスワードをお忘れの方',
            disabled: true,
            href: '/forget'
          }
        ]
      }
    }
  },
  {
    path: '/forget/password/verify',
    name: 'ForgetAuthenticationVerifyPagePage',
    component: ForgetAuthenticationVerifyPagePage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'R2(アール・ツー) / TOPページ「ペットフード・グッズの店舗主様向けの卸専門ショップ」',
        desc: '',
        frame_common_header: true,
        frame_navigation: true,
        frame_common_footer: true,
        frame_extension_footer: true,
        breadcrumbs: [
          {
            text: 'TOP',
            disabled: false,
            href: '/'
          },
          {
            text: 'パスワードをお忘れの方',
            disabled: true,
            href: '/forget'
          }
        ]
      }
    }
  },
  {
    path: '/forget/password/reset',
    name: 'ForgetPasswordResetPage',
    component: ForgetPasswordResetPage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'R2(アール・ツー) / TOPページ「ペットフード・グッズの店舗主様向けの卸専門ショップ」',
        desc: '',
        frame_common_header: true,
        frame_navigation: true,
        frame_common_footer: true,
        frame_extension_footer: true,
        breadcrumbs: [
          {
            text: 'TOP',
            disabled: false,
            href: '/'
          },
          {
            text: 'パスワードをお忘れの方',
            disabled: true,
            href: '/forget'
          }
        ]
      }
    }
  },
  // --------------------------------------------------------
  // ショッピングガイド
  // --------------------------------------------------------
  {
    path: '/guide',
    name: 'GuidePage',
    component: GuidePage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'R2(アール・ツー) / TOPページ「ペットフード・グッズの店舗主様向けの卸専門ショップ」',
        desc: '',
        frame_common_header: true,
        frame_navigation: true,
        frame_common_footer: true,
        frame_extension_footer: true,
        breadcrumbs: [
          {
            text: 'TOP',
            disabled: false,
            href: '/'
          },
          {
            text: 'ショッピングガイド',
            disabled: true,
            href: '/guide'
          }
        ]
      }
    }
  },
  {
    path: '/company',
    name: 'InformationCompanyPage',
    component: InformationCompanyPage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'R2(アール・ツー) / TOPページ「ペットフード・グッズの店舗主様向けの卸専門ショップ」',
        desc: '',
        frame_common_header: true,
        frame_navigation: true,
        frame_common_footer: true,
        frame_extension_footer: true,
        breadcrumbs: [
          {
            text: 'TOP',
            disabled: false,
            href: '/'
          },
          {
            text: '会社概要',
            disabled: true,
            href: '/Company'
          }
        ]
      }
    }
  },
  {
    path: '/policy',
    name: 'InformationPolicyPage',
    component: InformationPolicyPage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'R2(アール・ツー) / TOPページ「ペットフード・グッズの店舗主様向けの卸専門ショップ」',
        desc: '',
        frame_common_header: true,
        frame_navigation: true,
        frame_common_footer: true,
        frame_extension_footer: true,
        breadcrumbs: [
          {
            text: 'TOP',
            disabled: false,
            href: '/'
          },
          {
            text: 'プライバシーポリシー',
            disabled: true,
            href: '/policy'
          }
        ]
      }
    }
  },
  {
    path: '/trade-law',
    name: 'InformationTradelawPage',
    component: InformationTradelawPage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'R2(アール・ツー) / TOPページ「ペットフード・グッズの店舗主様向けの卸専門ショップ」',
        desc: '',
        frame_common_header: true,
        frame_navigation: true,
        frame_common_footer: true,
        frame_extension_footer: true,
        breadcrumbs: [
          {
            text: 'TOP',
            disabled: false,
            href: '/'
          },
          {
            text: '特定商取引法に基づく表記',
            disabled: true,
            href: '/trade-law'
          }
        ]
      }
    }
  },
  // --------------------------------------------------------
  // 新規お取引申込み
  // --------------------------------------------------------
  {
    path: '/entry',
    name: 'EntryPage',
    component: EntryPage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'R2(アール・ツー) / TOPページ「ペットフード・グッズの店舗主様向けの卸専門ショップ」',
        desc: '',
        frame_common_header: true,
        frame_navigation: true,
        frame_common_footer: true,
        frame_extension_footer: true,
        breadcrumbs: [
          {
            text: 'TOP',
            disabled: false,
            href: '/'
          },
          {
            text: '取引先申請',
            disabled: true,
            href: '/entry'
          }
        ]
      }
    }
  },
  {
    path: '/entry/apply',
    name: 'EntryApplyPage',
    component: EntryApplyPage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'R2(アール・ツー) / TOPページ「ペットフード・グッズの店舗主様向けの卸専門ショップ」',
        desc: '',
        frame_common_header: true,
        frame_navigation: true,
        frame_common_footer: true,
        frame_extension_footer: true,
        breadcrumbs: [
          {
            text: 'TOP',
            disabled: false,
            href: '/'
          },
          {
            text: '取引先申請',
            disabled: false,
            href: '/entry'
          },
          {
            text: 'お申込みフォーム',
            disabled: true,
            href: '/entry/apply'
          }
        ]
      }
    }
  },
  {
    path: '/entry/confirm',
    name: 'EntryConfirmPage',
    component: EntryConfirmPage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'R2(アール・ツー) / TOPページ「ペットフード・グッズの店舗主様向けの卸専門ショップ」',
        desc: '',
        frame_common_header: true,
        frame_navigation: true,
        frame_common_footer: true,
        frame_extension_footer: true,
        breadcrumbs: [
          {
            text: 'TOP',
            disabled: false,
            href: '/'
          },
          {
            text: '取引先申請',
            disabled: false,
            href: '/entry'
          },
          {
            text: 'お申込みフォーム',
            disabled: false,
            href: '/entry/apply'
          },
          {
            text: '入力内容の確認',
            disabled: true,
            href: '/entry/confirm'
          }
        ]
      }
    }
  },
  {
    path: '/entry/complete',
    name: 'EntryCompletePage',
    component: EntryCompletePage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'R2(アール・ツー) / TOPページ「ペットフード・グッズの店舗主様向けの卸専門ショップ」',
        desc: '',
        frame_common_header: true,
        frame_navigation: true,
        frame_common_footer: true,
        frame_extension_footer: true,
        breadcrumbs: [
          {
            text: 'TOP',
            disabled: false,
            href: '/'
          },
          {
            text: '取引先申請',
            disabled: false,
            href: '/entry'
          },
          {
            text: 'お申込みフォーム',
            disabled: false,
            href: '/entry/apply'
          },
          {
            text: '入力内容の確認',
            disabled: false,
            href: '/entry/confirm'
          },
          {
            text: '申請完了',
            disabled: true,
            href: '/entry/complete'
          }
        ]
      }
    }
  },
  // --------------------------------------------------------
  // お問合せ
  // --------------------------------------------------------
  {
    path: '/contact',
    name: 'ContactPage',
    component: ContactPage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'R2(アール・ツー) / TOPページ「ペットフード・グッズの店舗主様向けの卸専門ショップ」',
        desc: '',
        frame_common_header: true,
        frame_navigation: true,
        frame_common_footer: true,
        frame_extension_footer: true,
        breadcrumbs: [
          {
            text: 'TOP',
            disabled: false,
            href: '/'
          },
          {
            text: 'お問合せ',
            disabled: true,
            href: '/contact'
          }
        ]
      }
    }
  },
  // --------------------------------------------------------
  // カート
  // --------------------------------------------------------
  {
    path: '/shopping',
    name: 'ShoppingPage',
    component: ShoppingPage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'R2(アール・ツー) / TOPページ「ペットフード・グッズの店舗主様向けの卸専門ショップ」',
        desc: '',
        frame_common_header: true,
        frame_navigation: true,
        frame_common_footer: true,
        frame_extension_footer: false,
        breadcrumbs: [
          {
            text: 'TOP',
            disabled: false,
            href: '/'
          },
          {
            text: '購買リスト',
            disabled: true,
            href: '/shopping'
          }
        ]
      }
    }
  },
  {
    path: '/shopping/shipping',
    name: 'ShoppingShippingPage',
    component: ShoppingShippingPage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'R2(アール・ツー) / TOPページ「ペットフード・グッズの店舗主様向けの卸専門ショップ」',
        desc: '',
        frame_common_header: true,
        frame_navigation: false,
        frame_common_footer: true,
        frame_extension_footer: false,
        breadcrumbs: [
          {
            text: 'TOP',
            disabled: false,
            href: '/'
          },
          {
            text: '購買リスト',
            disabled: false,
            href: '/shopping'
          },
          {
            text: 'お届け先の選択',
            disabled: true,
            href: '/shopping/shipping'
          }
        ]
      }
    }
  },
  {
    path: '/shopping/confirm',
    name: 'ShoppingConfirmPage',
    component: ShoppingConfirmPage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'R2(アール・ツー) / TOPページ「ペットフード・グッズの店舗主様向けの卸専門ショップ」',
        desc: '',
        frame_common_header: true,
        frame_navigation: false,
        frame_common_footer: true,
        frame_extension_footer: false,
        breadcrumbs: [
          {
            text: 'TOP',
            disabled: false,
            href: '/'
          },
          {
            text: '購買リスト',
            disabled: false,
            href: '/shopping'
          },
          {
            text: '購入確認',
            disabled: true,
            href: '/shopping/confirm'
          }
        ]
      }
    }
  },
  {
    path: '/shopping/payment',
    name: 'ShoppingPaymentPage',
    component: ShoppingPaymentPage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'R2(アール・ツー) / TOPページ「ペットフード・グッズの店舗主様向けの卸専門ショップ」',
        desc: '',
        frame_common_header: true,
        frame_navigation: false,
        frame_common_footer: true,
        frame_extension_footer: false,
        breadcrumbs: [
          {
            text: 'TOP',
            disabled: false,
            href: '/'
          },
          {
            text: '購買リスト',
            disabled: false,
            href: '/shopping'
          },
          {
            text: 'お支払い方法の選択',
            disabled: true,
            href: '/shopping/payment'
          }
        ]
      }
    }
  },
  {
    path: '/shopping/complete',
    name: 'ShoppingCompletePage',
    component: ShoppingCompletePage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'R2(アール・ツー) / TOPページ「ペットフード・グッズの店舗主様向けの卸専門ショップ」',
        desc: '',
        frame_common_header: true,
        frame_navigation: false,
        frame_common_footer: true,
        frame_extension_footer: false,
        breadcrumbs: [
          {
            text: 'TOP',
            disabled: false,
            href: '/'
          },
          {
            text: '購買リスト',
            disabled: false,
            href: '/shopping'
          },
          {
            text: '購入確認',
            disabled: true,
            href: '/shopping/confirm'
          },
          {
            text: '購入完了',
            disabled: true,
            href: '/shopping/payment'
          }
        ]
      }
    }
  },
  {
    path: '/myPage',
    name: 'MyPagePage',
    component: MyPagePage,
    meta: {
      requiresAuth: false,
      page: {
        title: 'R2(アール・ツー) / TOPページ「ペットフード・グッズの店舗主様向けの卸専門ショップ」',
        desc: '',
        frame_common_header: true,
        frame_navigation: true,
        frame_common_footer: true,
        frame_extension_footer: true,
        breadcrumbs: [
          {
            text: 'TOP',
            disabled: false,
            href: '/'
          },
          {
            text: 'マイページ',
            disabled: true,
            href: '/myPage'
          }
        ]
      }
    },
    children: [
      {
        path: 'orderHistory',
        name: 'MyPageOrderHistoryPage',
        component: MyPageOrderHistoryPage,
        meta: {
          requiresAuth: false,
          page: {
            title: 'R2(アール・ツー) / TOPページ「ペットフード・グッズの店舗主様向けの卸専門ショップ」',
            desc: '',
            frame_common_header: true,
            frame_navigation: true,
            frame_common_footer: true,
            frame_extension_footer: true,
            breadcrumbs: [
              {
                text: 'TOP',
                disabled: false,
                href: '/'
              },
              {
                text: 'マイページ',
                disabled: false,
                href: '/myPage'
              },
              {
                text: '注文履歴',
                disabled: true,
                href: '/myPage/orderHistory'
              }
            ]
          }
        }
      },
      {
        path: 'profile',
        name: 'MyPageProfilePage',
        component: MyPageProfilePage,
        meta: {
          requiresAuth: false,
          page: {
            title: 'R2(アール・ツー) / TOPページ「ペットフード・グッズの店舗主様向けの卸専門ショップ」',
            desc: '',
            frame_common_header: true,
            frame_navigation: true,
            frame_common_footer: true,
            frame_extension_footer: true,
            breadcrumbs: [
              {
                text: 'TOP',
                disabled: false,
                href: '/'
              },
              {
                text: 'マイページ',
                disabled: false,
                href: '/myPage'
              },
              {
                text: '登録情報',
                disabled: true,
                href: '/myPage/profile'
              }
            ]
          }
        }
      },
      {
        path: 'credit',
        name: 'MyPageCreditPage',
        component: MyPageCreditPage,
        meta: {
          requiresAuth: false,
          page: {
            title: 'R2(アール・ツー) / TOPページ「ペットフード・グッズの店舗主様向けの卸専門ショップ」',
            desc: '',
            frame_common_header: true,
            frame_navigation: true,
            frame_common_footer: true,
            frame_extension_footer: true,
            breadcrumbs: [
              {
                text: 'TOP',
                disabled: false,
                href: '/'
              },
              {
                text: 'マイページ',
                disabled: false,
                href: '/myPage'
              },
              {
                text: 'クレジット情報',
                disabled: true,
                href: '/myPage/credit'
              }
            ]
          }
        }
      },
      {
        path: 'credit/add',
        name: 'MyPageCreateCreditPage',
        component: MyPageCreateCreditPage,
        meta: {
          requiresAuth: false,
          page: {
            title: 'R2(アール・ツー) / TOPページ「ペットフード・グッズの店舗主様向けの卸専門ショップ」',
            desc: '',
            frame_common_header: true,
            frame_navigation: true,
            frame_common_footer: true,
            frame_extension_footer: true,
            breadcrumbs: [
              {
                text: 'TOP',
                disabled: false,
                href: '/'
              },
              {
                text: 'マイページ',
                disabled: false,
                href: '/myPage'
              },
              {
                text: 'クレジット情報',
                disabled: false,
                href: '/myPage/credit'
              },
              {
                text: 'クレジット登録',
                disabled: true,
                href: '/myPage/credit/add'
              }
            ]
          }
        }
      },
      {
        path: 'downloads',
        name: 'MyPageDownloadsPage',
        component: MyPageDownloadsPage,
        meta: {
          requiresAuth: false,
          page: {
            title: 'R2(アール・ツー) / TOPページ「ペットフード・グッズの店舗主様向けの卸専門ショップ」',
            desc: '',
            frame_common_header: true,
            frame_navigation: true,
            frame_common_footer: true,
            frame_extension_footer: true,
            breadcrumbs: [
              {
                text: 'TOP',
                disabled: false,
                href: '/'
              },
              {
                text: 'マイページ',
                disabled: false,
                href: '/myPage'
              },
              {
                text: '画像販促用資料',
                disabled: true,
                href: '/myPage/downloads'
              }
            ]
          }
        }
      },
      {
        path: 'downloads/:MarketingMaterialsCategoriesID',
        name: 'MyPageDownloadsLayerPage',
        component: MyPageDownloadsPage,
        meta: {
          requiresAuth: false,
          page: {
            title: 'R2(アール・ツー) / TOPページ「ペットフード・グッズの店舗主様向けの卸専門ショップ」',
            desc: '',
            frame_common_header: true,
            frame_navigation: true,
            frame_common_footer: true,
            frame_extension_footer: true,
            breadcrumbs: [
              {
                text: 'TOP',
                disabled: false,
                href: '/'
              },
              {
                text: 'マイページ',
                disabled: false,
                href: '/myPage'
              },
              {
                text: '画像販促用資料',
                disabled: true,
                href: '/myPage/downloads'
              }
            ]
          }
        }
      },
      {
        path: 'password',
        name: 'MyPagePasswordPage',
        component: MyPagePasswordPage,
        meta: {
          requiresAuth: false,
          page: {
            title: 'R2(アール・ツー) / TOPページ「ペットフード・グッズの店舗主様向けの卸専門ショップ」',
            desc: '',
            frame_common_header: true,
            frame_navigation: true,
            frame_common_footer: true,
            frame_extension_footer: true,
            breadcrumbs: [
              {
                text: 'TOP',
                disabled: false,
                href: '/'
              },
              {
                text: 'マイページ',
                disabled: false,
                href: '/myPage'
              },
              {
                text: 'パスワード変更',
                disabled: true,
                href: '/myPage/password'
              }
            ]
          }
        }
      }
    ]
  }
]

// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes
// })

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

router.afterEach((to, from) => {
  // if (to.meta && to.meta.requiresAuth) {
  //   API.putUsersPageAccessLog(to.fullPath)
  // }
  if (to.meta && to.meta.page) {
    store.dispatch('modPage/setPageContents', to.meta.page)
  }
})

export default router
