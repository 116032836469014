<template>
<v-card flat color="transparent" class="mx-1" style="min-width: 60px;" v-if="loading">
  <v-card-text class="text-center pa-1" >
    <v-progress-circular
      indeterminate
      color="grey lighten-5"
    ></v-progress-circular>
  </v-card-text>
</v-card>
<v-card flat color="transparent" class="mx-1" style="min-width: 60px;" @click="$emit('click')" v-else>
  <v-card-text class="text-center pa-1" >
    <v-icon :large="(!$vuetify.breakpoint.mdAndDown)" dense :color="(isUndefined(color)) ? undefined : color" v-text="icon"></v-icon>
    <br />
    <span :style="{ color: (isUndefined(color)) ? undefined : color }" class="ec-font-size12 font-weight-bold" v-text="text"></span>
  </v-card-text>
</v-card>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

export default {
  name: 'ComponentsItemsTextIconButton',

  mixins: [MixinLib],

  props: {
    color: {
      type: String,
      required: false,
      default: () => ''
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    icon: {
      type: String,
      required: false,
      default: () => ''
    },
    text: {
      type: String,
      required: false,
      default: () => ''
    }
  }
}
</script>
