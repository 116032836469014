const state = {
  isSearchValid: '',
  isSaveValid: '',
  Category: '',
  Brand: '',
  Keyword: ''
}

const getters = {
  isSearchValid: (state) => {
    return state.isSearchValid
  },
  isSaveValid: (state) => {
    return state.isSaveValid
  },
  Category: (state) => {
    return state.Category
  },
  Brand: (state) => {
    return state.Brand
  },
  Keyword: (state) => {
    return state.Keyword
  }
}

const mutations = {
  isSearchValid (state, contents) {
    state.isSearchValid = contents
  },
  isSaveValid (state, contents) {
    state.isSaveValid = contents
  },
  Category (state, contents) {
    state.Category = contents
  },
  Brand (state, contents) {
    state.Brand = contents
  },
  Keyword (state, contents) {
    state.Keyword = contents
  }
}

const actions = {
  isSearchValid ({ commit, dispatch, state }, contents) {
    commit('isSearchValid', contents)
  },
  isSaveValid ({ commit, dispatch, state }, contents) {
    commit('isSaveValid', contents)
  },
  Category ({ commit, dispatch, state }, contents) {
    commit('Category', contents)
  },
  Brand ({ commit, dispatch, state }, contents) {
    commit('Brand', contents)
  },
  Keyword ({ commit, dispatch, state }, contents) {
    commit('Keyword', contents)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
