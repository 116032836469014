<template>
<v-app :style="{ backgroundColor: primaryColor }">
  <v-main>
    <v-container fluid fill-height>
      <v-row align="center" justify="center">
        <v-col cols=6 class="mx-auto text-center">
          <v-img :src="splash" :lazy-src="splash" contain aspect-ratio="1" class="mx-auto blinking"></v-img>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</v-app>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

export default {
  name: 'ComponentsLayoutLoadingScreen',

  mixins: [MixinLib],

  data: () => ({
    splash: require('@/assets/logo.png')
  }),

  props: {
  }
}
</script>
<style scoped>
.blinking {
  -webkit-animation:blink 2s ease-in-out infinite alternate;
  -moz-animation:blink 2s ease-in-out infinite alternate;
  animation:blink 2s ease-in-out infinite alternate;
}
@-webkit-keyframes blink{
    0% {opacity:0;}
    100% {opacity:1;}
}
@-moz-keyframes blink{
    0% {opacity:0;}
    100% {opacity:1;}
}
@keyframes blink{
    0% {opacity:0;}
    100% {opacity:1;}
}
</style>
