<template>
<v-card flat color="transparent" class="mx-1" :width="parseInt(widthSize) + 10">

  <ComponentsViewFormsSquareProductsImage
    :img-src="imgSrc"
    :label="label"
    :width-size="widthSize"
    :campaign="(isCampaign)"
    @click="$emit('click')"
  >
  </ComponentsViewFormsSquareProductsImage>
  <!--
  <v-card flat color="grey lighten-2" class="pa-0 ma-0" :width="parseInt(widthSize)" :height="parseInt(widthSize)" @click="$emit('click')">
    <v-col cols="12" class="pt-1 pl-2">
      <v-chip x-small class="mr-1" dark label v-text="label"></v-chip>
    </v-col>
    <v-img :src="imgSrc" class="mx-auto" style="background-position:center">
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
  </v-card>
  -->

  <v-card-text class="text-left pa-1">
    <v-row :id="text" align="center" justify="center" :style="{ cursor: 'pointer' }" @click="$emit('click')">
      <v-col cols="12" class="ec-row-h40">
        <v-chip x-small color="ChipLabelColor" class="mr-1" label v-for="(item, index) in categories" :key="index" v-text="item"></v-chip>
      </v-col>
      <v-col cols="12" class='ec-row-h80 text-left py-2 font-weight-bold' :style="{ color: buttonColor }">
        <span class="searchName" v-text="text"></span>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" v-if="isSignIn">
      <v-col cols="12">
        <ComponentsViewFormsProductsSetOrderButton
          :small-size="smallSize"
          :loading="isProcessLoading"
          :products-id="productsId"
          @click-add="addFavorites()"
          @click-remove="removeFavorites()"
        >
        </ComponentsViewFormsProductsSetOrderButton>
      </v-col>
    </v-row>
  </v-card-text>
</v-card>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsViewFormsSquareProductsImage from '@/components/view-forms/SquareProductsImage'
import ComponentsViewFormsProductsSetOrderButton from '@/components/view-forms/ProductsSetOrderButton'

export default {
  name: 'ComponentsViewFormsSquareProducts',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false
  }),

  computed: {
    isCampaign: {
      get () {
        return this.CampaignListProducts.some(item => item.campaign_specific_buying.products_id === this.productsId)
      }
    },
    CampaignListProducts: {
      get () {
        return this.$store.getters['modStorage/CampaignListProducts']
      }
    },
    windowWidthSize: {
      get () {
        return this.$store.getters['modPage/windowWidthSize']
      }
    },
    imageWidthSize: {
      get () {
        const widthSize = (this.windowWidthSize >= 1024) ? 1024 : this.windowWidthSize
        if (!this.$vuetify.breakpoint.smAndDown) {
          return 160
        }
        return parseInt(160 * (widthSize / 600))
      }
    },
    imageHeightSize: {
      get () {
        return this.imageWidthSize
      }
    }
  },

  methods: {
    addFavorites: function () {
      (async () => {
        this.isProcessLoading = true
        const contents = await APILib.postFavorites(this.productsId)
        this.$store.dispatch('modStorage/SetUsersFavorites', contents)
        this.$confirm({ title: 'Success', message: '購買リストに追加しました', button: { yes: 'OK' } })
        this.isProcessLoading = false
      })().catch((e) => {
        this.isProcessLoading = false
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    },
    removeFavorites: function () {
      (async () => {
        console.log('removeFavorites')
        this.isProcessLoading = true
        // const contents = await APILib.deleteFavorites(this.productsId)
        // this.$store.dispatch('modStorage/SetUsersFavorites', contents)
        await APILib.deleteFavorites(this.productsId)
        setTimeout(this.callReload, 1000)
        this.$confirm({ title: 'Success', message: '購買リストから除外しました', button: { yes: 'OK' } })
        this.isProcessLoading = false
      })().catch((e) => {
        this.isProcessLoading = false
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    },
    callReload: function () {
      console.log('callReload')
      this.$emit('reload')
    }
  },

  components: {
    ComponentsViewFormsSquareProductsImage,
    ComponentsViewFormsProductsSetOrderButton
  },

  props: {
    imgSrc: {
      type: String,
      require: false,
      default: () => ''
    },
    productsId: {
      type: String,
      require: false,
      default: () => ''
    },
    label: {
      type: String,
      require: false,
      default: () => ''
    },
    categories: {
      type: Array,
      require: false,
      default: () => []
    },
    text: {
      type: String,
      require: false,
      default: () => ''
    },
    widthSize: {
      type: [String, Number],
      require: false,
      default: () => '140'
    },
    smallSize: {
      type: Boolean,
      require: false,
      default: () => false
    }
  }
}
</script>
