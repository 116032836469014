// import UtilLib from '@/libs/UtilLib'
// import Cookies from 'js-cookie'

const state = {
  isSignIn: false,
  navigationStatus: false,
  entryData: {}
}

const getters = {
  isSignIn: (state) => {
    return state.isSignIn
  },
  navigationStatus: (state) => {
    return state.navigationStatus
  },
  entryData: (state) => {
    return state.entryData
  }
}

const mutations = {
  isSignIn (state, isSignIn) {
    state.isSignIn = isSignIn
  },
  navigationStatus (state, navigationStatus) {
    state.navigationStatus = navigationStatus
  },
  entryData (state, contents) {
    state.entryData = contents
  }
}

const actions = {
  isSignIn ({ commit, dispatch, state }, isSignIn) {
    commit('isSignIn', isSignIn)
  },
  navigationStatus ({ commit, dispatch, state }, status) {
    commit('navigationStatus', status)
  },
  entryData ({ commit, dispatch, state }, contents) {
    commit('entryData', contents)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
