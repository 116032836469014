// import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@fortawesome/fontawesome-free/css/all.css'

import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import VueConfirmDialog from 'vue-confirm-dialog'
import VueScrollTo from 'vue-scrollto'
import VueMask from 'v-mask'

Vue.config.productionTip = false

Vue.use(VueScrollTo)
Vue.use(VueConfirmDialog)
Vue.use(VueMask)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
