<template>
<ComponentsLayoutPage1>
  <v-row align="start" justify="start" no-gutters :style="{ paddingBottom: '100px' }">

    <!-- 商品タイトル -->
    <v-col cols=12 class="pt-5" v-if="windowSpSize">
      <ComponentsViewFormsCampaignTitle
        :campaign="getValue('campaign', contents)"
        :loading="isProcessLoading"
      >
      </ComponentsViewFormsCampaignTitle>
    </v-col>
    <v-col cols=12 class="pb-5" v-if="windowSpSize">
      <!-- 商品基本情報 -->
      <ComponentsViewFormsCampaignDescription
        :campaign="getValue('campaign', contents)"
        :loading="isProcessLoading"
      >
      </ComponentsViewFormsCampaignDescription>
    </v-col>

    <!-- 商品画像 -->
    <v-col cols=12 sm=12 md=4 lg=4 xl=4 class="pb-5">
      <ComponentsViewFormsCampaignImage
        :campaign="getValue('campaign', contents)"
        :width="imageWidthSize"
        :height="imageHeightSize"
        :loading="isProcessLoading"
        class="mx-auto"
      >
      </ComponentsViewFormsCampaignImage>
    </v-col>

    <v-col cols=8 v-if="!windowSpSize">
      <v-row>
        <v-col cols=12>
          <!-- 商品タイトル -->
          <ComponentsViewFormsCampaignTitle
            :campaign="getValue('campaign', contents)"
            :loading="isProcessLoading"
          >
          </ComponentsViewFormsCampaignTitle>
        </v-col>
        <v-col cols=12>
          <!-- 商品基本情報 -->
          <ComponentsViewFormsCampaignDescription
            :campaign="getValue('campaign', contents)"
            :loading="isProcessLoading"
          >
          </ComponentsViewFormsCampaignDescription>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols=12 class="pt-5" v-if="(getValue('campaign_specific_buying', contents) || []).length > 0">
      <v-row>
        <v-col cols=12>
          <v-icon color="primary" left>mdi-package-variant-closed</v-icon><span class="primary--text ec-font-size16 font-weight-bold">キャンペーン対象商品</span>
        </v-col>

        <v-col cols=6 md=4 lg=3 xl=3 class="pb-5" v-for="item in getValue('specific_buying_products', contents) || []" :key="item.products_id">
          <ComponentsViewFormsSquareProducts
            small-size
            :products-id="item.products_id"
            :img-src="(isUndefined(item.products_image)) ? '' : item.products_image[0].img_src"
            :label="getBrandName(item.products.brand_id)"
            :text="item.products.products_name"
            :width-size="(windowMiddleSize) ? 140 : 200"
            class="mx-auto"
            @click="pageTransition('/products/' + item.products_id)"
          >
          </ComponentsViewFormsSquareProducts>
        </v-col>

      </v-row>
    </v-col>

  </v-row>
</ComponentsLayoutPage1>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutPage1 from '@/components/layout/Page1'
import ComponentsViewFormsCampaignTitle from '@/components/view-forms/CampaignTitle'
import ComponentsViewFormsCampaignDescription from '@/components/view-forms/CampaignDescription'
import ComponentsViewFormsCampaignImage from '@/components/view-forms/CampaignImage'
import ComponentsViewFormsSquareProducts from '@/components/view-forms/SquareProducts'

export default {
  name: 'CampaignCampaignContentsPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true,
    contents: {}
  }),

  computed: {
    CampaignID: {
      get () {
        return this.$route.params.CampaignID
      }
    },
    imageWidthSize: {
      get () {
        const widthSize = (this.windowWidthSize >= 960) ? 960 : this.windowWidthSize
        if (!this.$vuetify.breakpoint.smAndDown) {
          return parseInt((widthSize / 3) - 30)
        }
        return (this.$vuetify.breakpoint.md) ? parseInt((widthSize / 2) - 30) : parseInt(widthSize - 30)
      }
    },
    imageHeightSize: {
      get () {
        return this.imageWidthSize
      }
    }
  },

  mounted: function () {
    if (!this.isSignIn) {
      this.pageTransition('/login')
    }
    this.goLoading()
  },

  methods: {
    goLoading: function () {
      (async () => {
        this.isProcessLoading = true
        this.contents = await APILib.getCampaign(this.CampaignID)
        console.log(this.contents)
        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    }
  },

  components: {
    ComponentsLayoutPage1,
    ComponentsViewFormsCampaignTitle,
    ComponentsViewFormsCampaignDescription,
    ComponentsViewFormsCampaignImage,
    ComponentsViewFormsSquareProducts
  }
}
</script>
