<template>
<v-row align="center" justify="center">

  <v-col cols=12>
    <ComponentsLayoutHeading1 text="画像販促用資料"></ComponentsLayoutHeading1>
  </v-col>

  <v-col cols=12>
    <span v-for="(item, index) in layer" :key="item.marketing_materials_categories_id">
      <span v-if="layer.length > (index + 1)">
        <a href="#" @click="pageTransition('/myPage/downloads/' + item.marketing_materials_categories_id)">
          <span class="ec-font-size12" v-text="item.marketing_materials_categories.marketing_materials_categories_name"></span>
        </a>
        <span class="ec-font-size14 px-3">&gt;</span>
      </span>
      <span v-else>
        <span class="ec-font-size16" v-text="item.marketing_materials_categories.marketing_materials_categories_name"></span>
      </span>
    </span>
  </v-col>

  <v-col cols=12>
    <v-list dense>
      <v-subheader>FOLDERS</v-subheader>
      <template v-for="(item, index) in categories">
        <v-divider :key="item.marketing_materials_categories_id + '-' + index"></v-divider>

        <v-list-item :key="item.marketing_materials_categories_id">
          <v-list-item-action>
            <v-btn icon :color="buttonColor" @click="pageTransition('/myPage/downloads/' + item.marketing_materials_categories_id)">
              <v-icon :color="buttonColor">mdi-folder-outline</v-icon>
            </v-btn>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <span v-text="item.marketing_materials_categories.marketing_materials_categories_name"></span>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon :color="buttonColor" @click="pageTransition('/myPage/downloads/' + item.marketing_materials_categories_id)">
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>

      <v-subheader>FILES</v-subheader>
      <template v-for="(item, index) in materials">
        <v-divider :key="item.marketing_materials_id + '-' + index"></v-divider>

        <v-list-item :key="item.marketing_materials_id">
          <v-list-item-action>
            <v-btn icon :color="buttonColor" @click="downloadItem(item.marketing_materials)">
              <v-icon>mdi-cloud-download</v-icon>
            </v-btn>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <span v-text="item.marketing_materials.marketing_materials_name"></span>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon :color="buttonColor" @click="downloadItem(item.marketing_materials)">
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>

    </v-list>
  </v-col>

  <!--
  <v-col cols=12>
    <v-row align="center" justify="start" v-for="categories in list" :key="categories.marketing_materials_categories_id">
      <v-col cols=12>
        <ComponentsLayoutSection2>
          <span class='font-weight-bold' v-text="categories.marketing_materials_categories.marketing_materials_categories_name"></span>
        </ComponentsLayoutSection2>
      </v-col>
      <v-col :cols="(windowMiddleSize) ? 6 : 3" class="pl-5" v-for="materials in categories.materials" :key="materials.marketing_materials_id">
        <a :href="materials.marketing_materials.files" target="_blank">
          <v-card outlined class="rounded-lg" :style="{ borderColor: '#DE5D50' }">
            <v-card-text :style="{ color: '#DE5D50' }" class="text-truncate">
              <v-icon color="#DE5D50">mdi-folder-arrow-down</v-icon>
              <span class='pl-2 ec-font-size12 font-weight-bold' v-text="materials.marketing_materials.marketing_materials_name"></span>
            </v-card-text>
          </v-card>
        </a>
      </v-col>
    </v-row>
  </v-col>
  -->
</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'
import axios from 'axios'

import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
// import ComponentsLayoutSection2 from '@/components/layout/Section1'

export default {
  name: 'MyPageDownloadsPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false,
    parent_categories_id: '',
    categories_id: '',
    name: '',
    categories: [],
    materials: [],
    layer: []
    // list: []
  }),

  watch: {
    MarketingMaterialsCategoriesID () {
      this.goLoading()
    }
  },

  computed: {
    MarketingMaterialsCategoriesID: {
      get () {
        return this.$route.params.MarketingMaterialsCategoriesID || 'top'
      }
    }
  },

  mounted () {
    if (!this.isSignIn) {
      return this.pageTransition('/login')
    }
    this.goLoading()
  },

  methods: {
    goLoading: function () {
      (async () => {
        this.isProcessLoading = true
        const resultRows = await APILib.getMarketingMaterialsCategories(this.MarketingMaterialsCategoriesID)
        this.parent_categories_id = this.getValue('parent_marketing_materials_categories_id', resultRows) || ''
        this.categories_id = this.getValue('marketing_materials_categories_id', resultRows) || ''
        this.name = this.getValue('marketing_materials_categories_name', this.getValue('marketing_materials_categories', resultRows) || {}) || ''
        this.categories = this.getValue('categories', resultRows) || []
        this.materials = this.getValue('materials', resultRows) || []
        this.layer = this.getValue('layer', resultRows) || []

        // this.list = await APILib.getMarketingMaterialsCategories(this.MarketingMaterialsCategoriesID)
        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    },

    downloadItem (item) {
      axios.get(item.files, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data])
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = item.marketing_materials_name + '.' + item.files.split('.').pop()
          link.click()
          URL.revokeObjectURL(link.href)
        })
    }
  },

  components: {
    ComponentsLayoutHeading1
    // ComponentsLayoutSection2
  }
}
</script>
