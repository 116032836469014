<template>
<v-row no-gutters v-if="loading || isProcessLoading">
  <v-col cols="12">
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12">
        <v-skeleton-loader class="mx-auto" type="text" height="80"></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-col>
</v-row>
<v-row no-gutters v-else>
  <v-col cols="12">
    <v-row align="center" justify="center" no-gutters>
      <v-col cols=12 class="py-1">
        <span class="ec-font-size18 font-weight-bold primary--text" v-text="campaign.campaign_name"></span>
      </v-col>
    </v-row>
  </v-col>
</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

export default {
  name: 'ComponentsViewFormsCampaignTitle',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false
  }),

  props: {
    campaign: {
      type: Object,
      require: true,
      default: () => {
        return {
          catch_copy: '',
          img_src: '',
          information: '',
          outline: '',
          campaign_name: '',
          sales_str_date: '',
          sales_end_date: ''
        }
      }
    },
    loading: {
      type: Boolean,
      require: false,
      default: () => false
    }
  }
}
</script>
