<template>
<v-checkbox
  dense
  :true-value="trueValue"
  :false-value="falseValue"
  :color="buttonColor"
  class="mt-0 mr-0"
  :input-value="value"
  :loading="loading"
  :hide-details="!error"
  :rules="validate"
  :error-messages="errors"
  :placeholder="placeholder"
  @change="updateValue"
>
  <template v-slot:label>
    <span :style="{ color: buttonColor }" class="ec-font-size12" v-text="text"></span>
  </template>
</v-checkbox>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import ValidationLib from '@/libs/ValidationLib'

export default {
  name: 'ComponentsItemsCheckBox',

  mixins: [MixinLib],

  data () {
    return {
      validate: [],
      placeholder: '',
      error: false,
      errors: ''
    }
  },

  computed: {
  },

  created: function () {
    this.validate = ValidationLib.rules(this.name)
    this.placeholder = ValidationLib.placeholder(this.name)
  },

  methods: {
    updateValue: function (e) {
      this.$emit('update:value', e)
    },

    isError: function () {
      return (this.errors !== '')
    },

    confirm: function () {
      return new Promise((resolve, reject) => {
        try {
          this.errors = ''
          for (var y = 0; y < this.validate.length; y++) {
            const err = this.validate[y](this.value)
            if (err !== true) {
              this.errors = err
              break
            }
          }
          if (this.isError()) {
            this.error = true
            return resolve(false)
          }
          this.error = false
          return resolve(true)
        } catch (e) {
          return reject(e)
        }
      })
    }
  },

  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: [String, Boolean],
      required: true,
      default: () => ''
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    text: {
      type: String,
      required: false,
      default: () => ''
    },
    trueValue: {
      type: [String, Boolean],
      required: false,
      default: () => '1'
    },
    falseValue: {
      type: [String, Boolean],
      required: false,
      default: () => '2'
    }
  }
}
</script>
