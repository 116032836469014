import Vue from 'vue'
import Vuex from 'vuex'

import modPage from '@/store/vuex/pages.js'
import modSession from '@/store/vuex/session.js'
import modStorage from '@/store/vuex/storage.js'
import modShopping from '@/store/vuex/shopping.js'
import modSearch from '@/store/vuex/search.js'

import createMultiTabState from 'vuex-multi-tab-state'
import createPersistedState from 'vuex-persistedstate'
// import SecureLS from 'secure-ls'
// const ls = new SecureLS({ isCompression: false })
// import Cookies from 'js-cookie'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    modSession: {
      isSignIn: false,
      navigationStatus: false,
      entryData: {}
    },
    modPage: {
      windowSize: {
        width: 0,
        height: 0
      },
      isInitialLoading: false,
      isLoading: true,
      isDataLoading: false,
      breadcrumbs: [],
      frame_common_header: false,
      frame_navigation: false,
      frame_common_footer: false,
      frame_extension_footer: false
    },
    modStorage: {
      UseTargetStatus: [
        { id: '1', name: '犬用品' },
        { id: '2', name: '猫用品' },
        { id: '9', name: '犬猫兼用' }
      ],
      News: [],
      Categories: [],
      Maker: [],
      Brand: [],
      BrandCategory: [],
      Prefecture: [],
      OrderStatus: [],
      NewRelease: [],
      Introduce: [],
      Recommend: [],
      Ranking: [],
      Pamphlet: [],
      blogFeeds: [],
      Users: [],
      UsersAddress: [],
      UsersFavorites: [],
      UsersFavoritesOriginals: [],
      Company: {},
      CompanyBrand: [],
      Policy: [],
      Tradelaw: [],
      ShopForm: [],
      RouteMethod: [],
      PickupBrand: [],
      ContactMethod: [],
      EntryFormItems: [],
      CampaignList: [],
      CampaignListFuture: [],
      CampaignListProducts: [],
      MaintenanceMode: false,
      PaymentsCredit: [],
      SalesManagerStatus: [
        { code: '1', name: '有' },
        { code: '2', name: '無' }
      ],
      LicenseStatus: [
        { code: '1', name: '保持' },
        { code: '2', name: '未保持' }
      ]
    },
    modShopping: {
      Cart: {},
      CartItems: [],
      CartPamphlet: [],
      Calculation: {},
      CartSelectablePamphlet: [],
      CartSelectableDeliveryDate: [],
      CartSelectableDeliveryTime: [],
      CartSelectablePayments: [],
      CartSelectableDeliveries: [],
      CommitOrderID: ''
    },
    modSearch: {
      isSearchValid: '',
      isSaveValid: '',
      Category: '',
      Brand: '',
      Keyword: ''
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    modPage,
    modSession,
    modStorage,
    modShopping,
    modSearch
  },
  plugins: [
    createMultiTabState({ statesPaths: ['modSession.accessToken'] }),
    createPersistedState({ paths: ['modSearch.isSearchValid', 'modSearch.isSaveValid', 'modSearch.Category', 'modSearch.Brand', 'modSearch.Keyword'] })
    // createPersistedState({
    //   key: 'cache',
    //   paths: [
    //     'modSession.accessToken'
    //   ],
    //   /*
    //   storage: {
    //     // getItem: key => ls.get(key),
    //     // setItem: (key, value) => ls.set(key, value),
    //     // removeItem: key => ls.remove(key)
    //     getItem: key => Cookies.get(key),
    //     setItem: (key, value) => Cookies.set(key, value, { expires: 7, secure: true }),
    //     removeItem: key => Cookies.remove(key)
    //   }
    //   */
    //   storage: window.sessionStorage
    // })
  ]
})
