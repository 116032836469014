<template>
<v-row align="start" justify="start">
  <v-col :cols="(windowMiddleSize) ? 12 : 3">
    <ComponentsLayoutHeading1 text="お届け先情報" border-hidden></ComponentsLayoutHeading1>
  </v-col>
  <v-col :cols="(windowMiddleSize) ? 12 : 9" :class="(windowMiddleSize) ? 'pb-5' : ''">
    <v-card :outlined="(windowMiddleSize)" :flat="(!windowMiddleSize)" :class="(windowMiddleSize) ? 'px-2 py-3' : 'pb-3'">
      <v-row align="center" justify="center">
        <v-col cols=9>
          <v-row no-gutters>
            <v-col cols=12 class="ec-font-size16 font-weight-bold">
              <span v-text="ShoppingAddress.dealings_address.name"></span>
            </v-col>
            <v-col cols=12 class="ec-font-size12">
              &#12306;&nbsp;<span v-text="ShoppingAddress.dealings_address.post_no"></span>
            </v-col>
            <v-col cols=12 class="ec-font-size12">
              <span v-text="getPrefectureName(ShoppingAddress.dealings_address.prefecture_id)"></span><span v-text="ShoppingAddress.dealings_address.address"></span>
            </v-col>
            <v-col cols=12 class="ec-font-size12">
              <span v-text="ShoppingAddress.dealings_address.tel"></span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols=3 class="text-right" v-if="windowMiddleSize">
          <ComponentsItemsButton
            icon-btn
            icon="mdi-arrow-right"
            color="red"
            :loading="loading || isProcessLoading"
            @click="$emit('click')"
          >
          </ComponentsItemsButton>
        </v-col>
        <v-col cols=3 class="text-right" v-else>
          <ComponentsItemsButton
            text-btn
            color="red"
            text="変更"
            :loading="loading || isProcessLoading"
            @click="$emit('click')"
          >
          </ComponentsItemsButton>
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ComponentsViewFormsShoppingShipping',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false
  }),

  computed: {
    Cart: {
      get () {
        return this.$store.getters['modShopping/Cart']
      }
    },
    UsersAddress: {
      get () {
        return this.$store.getters['modStorage/UsersAddress']
      }
    },
    ShoppingAddress: {
      get () {
        return this.UsersAddress.find(item => item.dealings_address_id === this.Cart.dealings_address_id)
      }
    }
  },

  methods: {
  },

  components: {
    ComponentsLayoutHeading1,
    ComponentsItemsButton
  },

  props: {
    loading: {
      type: Boolean,
      require: false,
      default: () => false
    }
  }
}
</script>
