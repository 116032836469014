<template>
<v-row align="center" justify="center">

  <v-col cols=12>
    <ComponentsLayoutHeading1 text="ご登録のクレジットカード一覧"></ComponentsLayoutHeading1>
  </v-col>

  <v-col cols=12 v-for="item in credits" :key="item.CardSeq">
    <ComponentsLayoutSection1>
      <v-row align="center" justify="center" no-gutters>
        <v-col cols=8>
          <v-row no-gutters>
            <v-col cols=12 >
              <span class="ec-font-size16 font-weight-bold pr-3" v-text="item.CardNo"></span>
            </v-col>
            <v-col cols=12 >
              <span>有効期限: <span v-text="item.Expire"></span></span>
            </v-col>
            <v-col cols=12>
              <span v-text="item.HolderName"></span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols=4 class="text-right">
          <ComponentsItemsButton
            small
            outlined
            icon="mdi-close"
            text="削除"
            @click="removeCredit(item.CardSeq)"
          >
          </ComponentsItemsButton>
        </v-col>
      </v-row>
    </ComponentsLayoutSection1>
  </v-col>

  <v-col cols=12 class="text-center">
    <ComponentsItemsButton
      text="新しいお支払い方法の追加"
      icon="mdi-plus"
      @click="pageTransition('/myPage/credit/add')"
    >
    </ComponentsItemsButton>
  </v-col>

</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'MyPageCreditPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false
  }),

  computed: {
    credits: {
      get () {
        return this.$store.getters['modStorage/PaymentsCredit']
      }
    }
  },

  mounted: function () {
    if (!this.isSignIn) {
      this.pageTransition('/login')
    }
    this.goLoading()
  },

  methods: {
    goLoading () {
      (async () => {
        await this.$store.dispatch('modStorage/LoadPaymentsCredit')
        this.isProcessLoading = false
      })().catch((e) => {
        this.isProcessLoading = false
      })
    },
    removeCredit (CardSeq) {
      (async () => {
        this.isProcessLoading = true
        await APILib.deleteShoppingPaymentsCredit(CardSeq)
        this.$confirm({ title: 'SUCCESS', message: 'クレジットカードの削除を行いました', button: { yes: 'OK' } })
        this.goLoading()
      })().catch((e) => {
        this.isProcessLoading = false
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    }
  },

  components: {
    ComponentsLayoutHeading1,
    ComponentsLayoutSection1,
    ComponentsItemsButton
  }
}
</script>
