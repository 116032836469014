<template>
<ComponentsLayoutSection1>
  <v-row align="center" justify="center" no-gutters>
    <v-col cols="12" class="text-left ec-secHeading">
      <span class="ec-secHeading__en">NEW</span>
      <span class="ec-secHeading__line"></span>
      <span class="ec-secHeading__ja">今月の新商品</span>
    </v-col>
    <v-col cols="12">
      <v-slide-group :show-arrows="(windowMiddleSize) ? false : true">
        <v-slide-item v-for="(item, index) in list" :key="index">
          <ComponentsViewFormsSquareProducts
            small-size
            :img-src="(isUndefined(item.products_image)) ? '' : item.products_image[0].img_src"
            :products-id="item.products_id"
            :label="getBrandName(item.products.brand_id)"
            :categories="item.category.map(item => getCategoriesName(item.categories_id))"
            :text="item.products.products_name + (isUndefined(item.products_class.color) ? '' : ' ' + item.products_class.color) + (isUndefined(item.products_class.size) ? '' : ' ' + item.products_class.size)"
            @click="pageTransition('/products/' + item.products_id)"
          >
          </ComponentsViewFormsSquareProducts>
        </v-slide-item>
      </v-slide-group>
    </v-col>
  </v-row>
</ComponentsLayoutSection1>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsViewFormsSquareProducts from '@/components/view-forms/SquareProducts'

export default {
  name: 'ComponentsViewFormsNewRelease',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true
  }),

  computed: {
    list: {
      get () {
        return this.$store.getters['modStorage/NewRelease']
      }
    }
  },

  components: {
    ComponentsLayoutSection1,
    ComponentsViewFormsSquareProducts
  }

}
</script>
