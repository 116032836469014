<template>
<v-row v-if="loading">
  <v-col cols=12>
    <v-row align="start" justify="start" no-gutters>
      <v-col cols=12 class="pt-5 pb-1">
        <v-icon color="R2MainColorLight" left>mdi-check-circle</v-icon><span class="R2MainColorLight--text ec-font-size16 font-weight-bold">商品のセールスポイント</span>
      </v-col>
      <v-col cols=12 class="py-1">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row align="start" justify="start" class="mx-3" no-gutters>
      <v-col cols=12 class="pa-1" v-if="isSignIn">
        <v-skeleton-loader class="mx-auto" type="text" height="40"></v-skeleton-loader>
      </v-col>
      <v-col cols=12 class="pa-1" v-else>
        <ComponentsViewFormsProductsApply
          warningMessage="表示するにはお取引のご契約が必要です"
        >
        </ComponentsViewFormsProductsApply>
      </v-col>
    </v-row>
  </v-col>
</v-row>
<v-row v-else>
  <v-col cols=12>
    <v-row align="start" justify="start" no-gutters>
      <v-col cols=12 class="pt-5 pb-1">
        <v-icon color="R2MainColorLight" left>mdi-check-circle</v-icon><span class="R2MainColorLight--text ec-font-size16 font-weight-bold">商品のセールスポイント</span>
      </v-col>
      <v-col cols=12 class="py-1">
        <v-divider></v-divider>
      </v-col>
    </v-row>
  </v-col>
  <v-col cols=12 v-if="isSignIn">
    <ComponentsLayoutSection1>
      <v-row align="start" justify="start">
        <v-col cols=12>
          <span v-text="productsSalesPoint.sales_point"></span>
        </v-col>
      </v-row>
    </ComponentsLayoutSection1>
  </v-col>
  <v-col cols=12 v-else>
    <ComponentsViewFormsProductsApply
      warningMessage="表示するにはお取引のご契約が必要です"
    >
    </ComponentsViewFormsProductsApply>
  </v-col>
</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsViewFormsProductsApply from '@/components/view-forms/ProductsApply'

export default {
  name: 'ComponentsViewFormsProductsSalePoint',

  mixins: [MixinLib],

  data: () => ({
  }),

  components: {
    ComponentsLayoutSection1,
    ComponentsViewFormsProductsApply
  },

  props: {
    loading: {
      type: Boolean,
      require: false,
      default: () => false
    },
    productsSalesPoint: {
      type: Object,
      require: false,
      default: () => {
        return {
          sales_point: ''
        }
      }
    }
  }
}
</script>
