<template>
<ComponentsLayoutPage1>
  <v-row align="center" justify="center" class="py-5 mx-auto" :style="{ maxWidth: '640px', paddingBottom: '100px' }">

    <v-col cols="12" class="text-center" :style="{ paddingTop: '50px' }">
      <v-card outlined>
        <v-card-text>
          <v-row align="center" justify="center" no-gutters>
            <v-col cols="12" class="py-3 text-center">
              <span>この度はお申込み有難うございます。<br>担当者より折り返しメール又はお電話にてご連絡を差し上げます。<br>申し込み後、3営業日以内に連絡が届かない場合はお手数ですが以下お電話にてご連絡ください。</span>
            </v-col>
            <v-col cols=12 class="py-3 text-center">
              <v-icon color="R2MainColorLight" left>mdi-phone-in-talk</v-icon><span class="R2MainColorLight--text ec-font-size18 font-weight-black" v-text="Company.tel"></span><span class="caption pl-5" v-text="`FAX: ${Company.fax}`"></span><br />
              <span class="caption">お電話受付：10:00～18:00(土日祝を除く)</span><br />
              <span class="caption">ウェブからのご注文は営業日時外も承っております</span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="8" class="py-5">
      <ComponentsItemsButton
        block
        :loading="isProcessLoading"
        text="TOPに戻る"
        @click="pageTransition('/')"
      >
      </ComponentsItemsButton>
    </v-col>
  </v-row>
</ComponentsLayoutPage1>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
// import APILib from '@/libs/APILib'

import ComponentsLayoutPage1 from '@/components/layout/Page1'

import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ConsoleEntryCompletePage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false
  }),

  computed: {
    Company: {
      get () {
        return this.$store.getters['modStorage/Company']
      }
    }
  },

  methods: {
  },

  components: {
    ComponentsLayoutPage1,
    ComponentsItemsButton
  }
}
</script>
