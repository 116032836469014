<template>
<v-row v-if="loading || isProcessLoading">
  <v-col cols="12">
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12">
        <v-skeleton-loader class="mx-auto" type="text" height="80"></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-col>
</v-row>
<v-row v-else>
  <v-col cols="12">
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12">
        <v-chip-group>
          <v-chip :x-small="windowSpSize" :small="!windowSpSize" color="ChipLabelColor" class="mr-1" label ><span v-text="getUseTargetStatusName(products.use_target_status)"></span></v-chip>
          <v-chip :x-small="windowSpSize" :small="!windowSpSize" color="ChipLabelColor" class="mr-1" label v-for="item in categories" :key="item"><span v-text="item"></span></v-chip>
        </v-chip-group>
      </v-col>
      <v-col cols=12 class="py-1">
        <span class="ec-font-size18 font-weight-bold primary--text" v-text="products.products_name"></span>
      </v-col>
      <v-col cols="12" class="text-left">
        <v-chip x-small color="BrandLabelColor" class="mr-1" dark label v-text="getBrandName(products.brand_id)"></v-chip>
      </v-col>
    </v-row>
  </v-col>
</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

export default {
  name: 'ComponentsViewFormsProductsTitle',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false
  }),

  computed: {
    categories: {
      get () {
        return this.products_categories
          .map(item => {
            return this.getCategoriesName(item.categories_id)
          })
          .reduce((results, cur) => (results.some(v => v === cur)) ? results : [...results, cur], [])
      }
    }
  },

  props: {
    products: {
      type: Object,
      require: true,
      default: () => {
        return {
          brand_id: '',
          calorie: '',
          catch_copy: '',
          component_value: '',
          country: '',
          goods_explain: '',
          information: '',
          normal_freeze_status: '',
          outline: '',
          products_name: '',
          remarks: '',
          set_contents: '',
          size_details: '',
          supply_amount: '',
          use_target_status: ''
        }
      }
    },
    products_categories: {
      type: Array,
      require: true,
      default: () => []
    },
    loading: {
      type: Boolean,
      require: false,
      default: () => false
    }
  }
}
</script>
