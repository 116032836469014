<template>
<v-textarea
  :value="value"
  :loading="loading"
  dense
  outlined
  :hide-details="!error"
  class="ec-font-size16"
  :rules="validate"
  :error-messages="errors"
  :placeholder="placeholder"
  background-color="rgba(var(--b3f,250,250,250),1)"
  @input="updateValue"
  :rows="rows"
></v-textarea>
</template>
<script>
import ValidationLib from '@/libs/ValidationLib'

export default {
  name: 'ComponentsItemsTextArea',

  data () {
    return {
      validate: [],
      placeholder: '',
      error: false,
      errors: ''
    }
  },

  computed: {
  },

  created: function () {
    this.validate = ValidationLib.rules(this.name)
    this.placeholder = ValidationLib.placeholder(this.name)
  },

  methods: {
    updateValue: function (e) {
      this.$emit('update:value', e)
    },

    isError: function () {
      return (this.errors.length > 0)
    },

    confirm: function () {
      return new Promise((resolve, reject) => {
        try {
          this.errors = ''
          for (var y = 0; y < this.validate.length; y++) {
            const err = this.validate[y](this.value)
            if (err !== true) {
              this.errors = err
              break
            }
          }
          if (this.isError()) {
            this.error = true
            return resolve(false)
          }
          this.error = false
          return resolve(true)
        } catch (e) {
          return reject(e)
        }
      })
    }
  },

  props: {
    name: { type: String, required: true },
    value: { type: String, required: true, default: () => '' },
    rows: { type: [String, Number], required: false, default: () => '5' },
    loading: { type: [String, Boolean], required: true }
  }
}
</script>
