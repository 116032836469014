<template>
<ComponentsLayoutPage1>
  <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '680px', paddingTop: '30px', paddingBottom: '50px' }">
    <v-col cols=12>
      <pre class="ec-font-size14" :style="{ whiteSpace: 'pre-wrap', fontFamily: '\'Helvetica Neue\', Tabular, \'Hiragino Kaku Gothic ProN\', \'Hiragino Sans\', \'BIZ UDPGothic\', Meiryo, sans-serif !important' }" v-html="Policy.content"></pre>
    </v-col>
  </v-row>
</ComponentsLayoutPage1>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutPage1 from '@/components/layout/Page1'

export default {
  name: 'InformationPolicyPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true
  }),

  computed: {
    Policy: {
      get () {
        return this.$store.getters['modStorage/Policy']
      }
    }
  },

  components: {
    ComponentsLayoutPage1
  }
}
</script>
