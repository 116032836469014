<template>
<ComponentsLayoutPage1>
  <v-row align="start" justify="start" :style="{ paddingTop: '30px', paddingBottom: '50px' }" v-if="isSignIn">
    <v-col :cols="(windowMiddleSize) ? 12 : 2">
      <v-row class="pb-5" v-if="windowMiddleSize">
        <v-col cols=6 class="px-5" v-for="(link, index) in items" :key="'i-' + index">
          <ComponentsItemsButton
            :outlined="!($route.path.indexOf(link.route))"
            block
            :text="link.text"
            @click="pageTransition(link.route)"
          >
          </ComponentsItemsButton>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols=12>
          <v-list nav >
            <v-list-item-group v-model="selectedItem" color="primary">
              <v-list-item @click="pageTransition(link.route)" v-for="(link, index) in items" :key="'i-' + index">
                <v-list-item-content>
                  <v-list-item-title class="ec-font-size12"><span v-text="link.text"></span></v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="pt-5" @click="SignOut(), pageTransition('/')">
                <v-list-item-content>
                  <v-list-item-title class="ec-font-size12"><span>ログアウト</span></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row>
    </v-col>
    <v-col :cols="(windowMiddleSize) ? 12 : 10">
      <router-view />
    </v-col>
  </v-row>
</ComponentsLayoutPage1>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutPage1 from '@/components/layout/Page1'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'MyPagePage',

  mixins: [MixinLib],

  data: () => ({
    selectedItem: 0,
    items: [
      {
        route: '/myPage/orderHistory',
        text: '注文履歴一覧'
      },
      {
        route: '/myPage/profile',
        text: '登録情報'
      },
      {
        route: '/myPage/credit',
        text: 'クレジット情報'
      },
      {
        route: '/myPage/password',
        text: 'パスワード変更'
      },
      /*
      {
        route: '/myPage',
        text: 'お問合せ情報'
      },
      */
      {
        route: '/myPage/downloads',
        text: '画像・販促用資料'
      }
    ]
  }),

  computed: {
    isMyPageTop: {
      get () {
        return (this.$route.path === '/myPage')
      }
    }
  },

  mounted () {
    if (!this.isSignIn) {
      this.pageTransition('/login')
    }
    if (this.isMyPageTop) {
      this.pageTransition(this.items[0].route)
    }
  },

  components: {
    ComponentsLayoutPage1,
    ComponentsItemsButton
  }
}
</script>
