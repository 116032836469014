<template>
<ComponentsLayoutPage1>
  <v-row align="start" justify="start" class="py-5" :style="{ marginBottom: '40px' }">
    <v-col cols=12>
      <ComponentsLayoutHeading1 text="お支払い方法の選択"></ComponentsLayoutHeading1>
    </v-col>

    <v-col :cols="(windowMiddleSize) ? 12 : 8" class="mx-auto pb-5" >
      <v-radio-group v-model="edit.payment_id" dense>
        <v-row align="start" justify="start" v-for="payments in CartSelectablePayments" :key="payments.code">
          <v-col cols=12 v-if="isCredits(payments.code)">
            <v-row no-gutters>
              <!-- クレジット -->
              <v-col cols=12>
                <ComponentsLayoutSection1>
                  <v-radio color="R2MainColorLight" :value="payments.code">
                    <template v-slot:label>
                      <v-row no-gutters >
                        <v-col cols=12 class="ec-font-size12 font-weight-bold">
                          <span v-text="payments.name"></span>
                        </v-col>
                      </v-row>
                    </template>
                  </v-radio>
                </ComponentsLayoutSection1>
              </v-col>

              <v-col cols=12 v-show="edit.payment_id == payments.code">
                <v-radio-group v-model="credit_type" dense>
                  <v-row class="pa-1" no-gutters>
                    <v-col cols=12 class="ActiveColor pa-5">
                      <v-row>
                        <v-col cols=12>
                          <v-radio color="R2MainColorLight" value="1">
                            <template v-slot:label>
                              <v-row no-gutters >
                                <v-col cols=12 class="ec-font-size12 font-weight-bold">
                                  <span>登録済みのクレジットカードを利用する</span>
                                </v-col>
                              </v-row>
                            </template>
                          </v-radio>
                        </v-col>
                        <v-col cols=12 v-show="credit_type === '1'">
                          <v-row>
                            <v-col cols=12 class="pb-3">
                              <v-divider></v-divider>
                            </v-col>
                            <v-col cols=12 class="ec-font-size12 py-1">
                              <span>お支払いに利用するクレジットカードを選択ください</span>
                            </v-col>
                            <v-col cols=12>
                              <v-radio-group dense v-model="edit.credit_seq">
                                <v-row no-gutters>
                                  <v-col cols=12 class="py-1" v-for="item in credits" :key="item.CardSeq">
                                    <ComponentsLayoutSection2>
                                      <v-radio color="R2MainColorLight" :value="item.CardSeq">
                                        <template v-slot:label>
                                          <v-row align="center" justify="center" no-gutters>
                                            <v-col cols=8 class="ec-font-size12">
                                              <span class="font-weight-bold pr-3" v-text="item.CardNo"></span>
                                            </v-col>
                                            <v-col cols=4 class="ec-font-size10">
                                              <span>有効期限: <span v-text="item.Expire"></span></span><br /><span v-text="item.HolderName"></span>
                                            </v-col>
                                          </v-row>
                                        </template>
                                      </v-radio>
                                    </ComponentsLayoutSection2>
                                  </v-col>
                                </v-row>
                              </v-radio-group>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="pa-1" no-gutters>
                    <v-col cols=12 class="ActiveColor pa-5">
                      <v-row >
                        <v-col cols=12>
                          <v-radio color="R2MainColorLight" value="2">
                            <template v-slot:label>
                              <v-row no-gutters >
                                <v-col cols=12 class="ec-font-size12 font-weight-bold">
                                  <span>新しいクレジットカードを登録して利用する</span>
                                </v-col>
                              </v-row>
                            </template>
                          </v-radio>
                        </v-col>
                        <v-col cols=12 v-show="credit_type === '2'">
                          <v-row>
                            <v-col cols=12 class="pb-3">
                              <v-divider></v-divider>
                            </v-col>
                            <v-col cols=12 class="ec-font-size12 py-1">
                              <span>お支払いに利用するクレジットカードをご入力ください</span>
                            </v-col>
                            <v-col cols=12 class="pb-5">
                              <v-row>
                                <v-col cols=12 class="ec-font-size10">
                                  <span>VISA、JCB、Master、アメリカン・エキスプレス、NICOSがご利用いただけます。<br />※3回以上の分割払いまたはリボ払いを選択の場合、金利手数料はお客様ご負担となります。</span>
                                </v-col>
                                <v-col :cols="(windowMiddleSize) ? 12 : 4" class="ec-font-size14 py-1">
                                  <span>クレジットカード番号</span>
                                </v-col>
                                <v-col :cols="(windowMiddleSize) ? 12 : 8" class="py-1">
                                  <ComponentsItemsTextField
                                    name='credit_card_no'
                                    prepend-inner-icon="mdi-credit-card"
                                    :value.sync="cardObject.cardno"
                                    :loading="isProcessLoading"
                                    mask="####-####-####-####"
                                    max-length="19"
                                    :css-style="{ maxWidth: '280px' }"
                                    ref="credit_card_no"
                                  ></ComponentsItemsTextField>
                                </v-col>
                                <v-col :cols="(windowMiddleSize) ? 12 : 4" class="ec-font-size14 py-1">
                                  <span>有効期限</span>
                                </v-col>
                                <v-col :cols="(windowMiddleSize) ? 12 : 8" class="py-1">
                                  <ComponentsItemsTextField
                                    name='credit_expire'
                                    :value.sync="cardObject.expire"
                                    :loading="isProcessLoading"
                                    mask="##/##"
                                    max-length="5"
                                    :css-style="{ maxWidth: '100px' }"
                                    ref="credit_expire"
                                  ></ComponentsItemsTextField>
                                </v-col>
                                <v-col :cols="(windowMiddleSize) ? 12 : 4" class="ec-font-size14 py-1">
                                  <span>セキュリティコード</span>
                                </v-col>
                                <v-col :cols="(windowMiddleSize) ? 12 : 8" class="py-1">
                                  <ComponentsItemsTextField
                                    name='credit_security_code'
                                    :value.sync="cardObject.securitycode"
                                    :loading="isProcessLoading"
                                    mask="####"
                                    max-length="4"
                                    :css-style="{ maxWidth: '100px' }"
                                    ref="credit_security_code"
                                  ></ComponentsItemsTextField>
                                </v-col>
                                <v-col :cols="(windowMiddleSize) ? 12 : 4" class="ec-font-size14 py-1">
                                  <span>名義人</span>
                                </v-col>
                                <v-col :cols="(windowMiddleSize) ? 12 : 8" class="py-1">
                                  <ComponentsItemsTextField
                                    name='credit_holder_name'
                                    :value.sync="cardObject.holdername"
                                    :loading="isProcessLoading"
                                    max-length="50"
                                    :css-style="{ maxWidth: '240px' }"
                                    ref="credit_holder_name"
                                  ></ComponentsItemsTextField>
                                </v-col>
                                <!---
                                <v-col cols=12>
                                  <ComponentsItemsCheckBox
                                    name="credit_save"
                                    :value.sync="edit.credit_save"
                                    :loading="isProcessLoading"
                                    text="購入後にクレジットカード情報を保存する"
                                    trueValue="1"
                                    falseValue="2"
                                  >
                                  </ComponentsItemsCheckBox>
                                </v-col>
                                ---->
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="pa-1" no-gutters>
                    <v-col cols=12 class="ActiveColor pa-5">
                      <v-row >
                        <v-col cols=12>
                          <span>お支払い方法を選択ください</span>
                        </v-col>
                        <v-col cols=12>
                          <v-radio-group dense v-model="edit.credit_method">
                            <v-row no-gutters>
                              <v-col cols=12 class="py-1" v-for="item of CreditPaymentMethods" :key="item.code">
                                  <v-radio color="R2MainColorLight" :value="item.code">
                                    <template v-slot:label>
                                      <v-row no-gutters>
                                        <v-col cols=12 class="ec-font-size12">
                                          <span v-text="item.name"></span>
                                        </v-col>
                                      </v-row>
                                    </template>
                                  </v-radio>
                              </v-col>
                            </v-row>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                      <v-row v-show="edit.credit_method === '2'">
                        <v-col cols=12>
                          <span>お支払い回数を選択ください</span>
                        </v-col>
                        <v-col cols=12>
                          <v-radio-group dense v-model="edit.credit_times">
                            <v-row no-gutters>
                              <v-col cols=12 class="py-1" v-for="item of CreditPaymentTimes" :key="item.code">
                                  <v-radio color="R2MainColorLight" :value="item.code">
                                    <template v-slot:label>
                                      <v-row no-gutters>
                                        <v-col cols=12 class="ec-font-size12">
                                          <span v-text="item.name"></span>
                                        </v-col>
                                      </v-row>
                                    </template>
                                  </v-radio>
                              </v-col>
                            </v-row>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>

          <!-- 掛売 -->
          <v-col cols=12 v-if="isAR(payments.code)">
            <v-row>
              <v-col cols=12>
                <ComponentsLayoutSection1>
                  <v-radio color="R2MainColorLight" :value="payments.code">
                    <template v-slot:label>
                      <v-row no-gutters >
                        <v-col cols=12 class="ec-font-size12 font-weight-bold">
                          <span v-text="payments.name"></span>
                        </v-col>
                      </v-row>
                    </template>
                  </v-radio>
                </ComponentsLayoutSection1>
              </v-col>
            </v-row>
          </v-col>

          <!-- 代金引換 -->
          <v-col cols=12 v-if="isCash(payments.code)">
            <v-row>
              <v-col cols=12>
                <ComponentsLayoutSection1>
                  <v-radio color="R2MainColorLight" :value="payments.code">
                    <template v-slot:label>
                      <v-row no-gutters >
                        <v-col cols=12 class="ec-font-size12 font-weight-bold">
                          <span v-text="payments.name"></span>
                        </v-col>
                      </v-row>
                    </template>
                  </v-radio>
                </ComponentsLayoutSection1>
              </v-col>
              <!--
              <v-col cols=12 v-show="(edit.payment_id == payments.code)">
                <v-row class="pa-1" no-gutters>
                  <v-col cols=12 class="ActiveColor pa-5">
                    <v-row>
                      <v-col cols=12 class="ec-font-size10">
                        <span>※¥11,000以上のお買いもので手数料無料<br />商品を受け取るときに、ドライバーに直接代金をお支払いください。</span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              --->
            </v-row>
          </v-col>

        </v-row>
      </v-radio-group>
    </v-col>

    <v-col :cols="(windowMiddleSize) ? 12 : 4" :style="(windowMiddleSize) ? {} : { marginTop: '16px', paddingTop: '24px' }">
      <v-row >
        <v-col cols=12>
          <ComponentsItemsButton
            block
            large
            color="R2MainColorLight"
            :loading="isProcessLoading"
            text="支払い方法を変更する"
            @click="changePayment()"
          >
          </ComponentsItemsButton>
        </v-col>
        <v-col cols=12>
          <ComponentsItemsButton
            block
            large
            outlined
            :loading="isProcessLoading"
            text="変更せずに戻る"
            @click="pageTransition('/shopping/confirm')"
          >
          </ComponentsItemsButton>
        </v-col>
      </v-row>
    </v-col>

  </v-row>
</ComponentsLayoutPage1>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutPage1 from '@/components/layout/Page1'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsLayoutSection2 from '@/components/layout/Section2'
import ComponentsItemsTextField from '@/components/items/TextField'
// import ComponentsItemsCheckBox from '@/components/items/CheckBox'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ShoppingPaymentPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true,
    credit_type: '1',
    edit: {},
    cardObject: {
      cardno: '',
      expire: '',
      securitycode: '',
      holdername: ''
    }
  }),

  computed: {
    CreditPaymentMethods: {
      get () {
        return this.commonCreditPaymentMethods()
      }
    },
    CreditPaymentTimes: {
      get () {
        return this.commonCreditPaymentTimes()
      }
    },
    Cart: {
      get () {
        return this.$store.getters['modShopping/Cart']
      }
    },
    CartSelectablePayments: {
      get () {
        return this.$store.getters['modShopping/CartSelectablePayments']
      }
    },
    credits: {
      get () {
        return this.$store.getters['modStorage/PaymentsCredit']
      }
    }
  },

  mounted: function () {
    if (!this.isSignIn) {
      this.pageTransition('/')
    }
    this.goLoading()
  },

  methods: {
    goLoading () {
      (async () => {
        await this.$store.dispatch('modStorage/LoadPaymentsCredit')
        this.edit = this.clone(this.Cart)
        this.isProcessLoading = false
      })().catch((e) => {
        this.isProcessLoading = false
      })
    },
    isCash (code) {
      return (code === process.env.VUE_APP_PAYMENTS_CASH)
    },
    isCredits (code) {
      return (code === process.env.VUE_APP_PAYMENTS_CREDITS)
    },
    isAR (code) {
      return (code === process.env.VUE_APP_PAYMENTS_AR)
    },
    changePayment () {
      (async () => {
        // クレジット払い
        if (this.isCredits(this.edit.payment_id)) {
          if (['1', '2'].includes(this.credit_type) === false) {
            return this.$confirm({ title: 'Error', message: 'クレジットカードを選択してください', button: { yes: 'OK' } })
          }
          // 登録済みのカード
          if (this.credit_type === '1') {
            if (this.credits.some(item => item.CardSeq === this.edit.credit_seq) === false) {
              return this.$confirm({ title: 'Error', message: 'クレジットカードを選択してください', button: { yes: 'OK' } })
            }
          }
          // お支払い方法
          if (this.CreditPaymentMethods.some(item => item.code === this.edit.credit_method) === false) {
            return this.$confirm({ title: 'Error', message: 'クレジットカードのお支払い方法を選択してください', button: { yes: 'OK' } })
          }
          // お支払い回数
          if (this.edit.credit_method === '2') {
            if (this.CreditPaymentTimes.some(item => item.code === this.edit.credit_times) === false) {
              return this.$confirm({ title: 'Error', message: 'クレジットカードのお支払い回数を選択してください', button: { yes: 'OK' } })
            }
          }
          // 新しいカード
          if (this.credit_type === '2') {
            return this.newCredit()
          }
        }
        this.isProcessLoading = true
        await this.$store.dispatch('modShopping/UpdateShoppingCart', this.edit)
        this.pageTransition('/shopping/confirm')
        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    },
    newCredit () {
      (async () => {
        // 新しいカード
        this.isProcessLoading = true
        window.Multipayment.getToken(
          {
            cardno: this.cardObject.cardno.replaceAll('-', ''),
            expire: this.cardObject.expire.replaceAll('/', ''),
            securitycode: this.cardObject.securitycode,
            holdername: this.cardObject.holdername
          },
          (result) => {
            (async () => {
              if (result.resultCode === '000') {
                this.edit.credit_seq = await APILib.postShoppingPaymentsCredit(result.tokenObject.token)
                await this.$store.dispatch('modShopping/UpdateShoppingCart', this.edit)
                await this.$store.dispatch('modStorage/LoadPaymentsCredit')
                this.pageTransition('/shopping/confirm')
              } else {
                this.$confirm({ title: 'Error', message: 'CODE-' + result.resultCode, button: { yes: 'OK' } })
              }
              this.isProcessLoading = false
            })().catch((e) => {
              this.isProcessLoading = false
              this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
            })
          }
        )
      })().catch((e) => {
        this.isProcessLoading = false
      })
    }
  },

  components: {
    ComponentsLayoutPage1,
    ComponentsLayoutHeading1,
    ComponentsLayoutSection1,
    ComponentsLayoutSection2,
    ComponentsItemsTextField,
    // ComponentsItemsCheckBox,
    ComponentsItemsButton
  }
}
</script>
