<template>
<v-card class="mx-auto ec-extension-footer" flat tile color="R2ExtensionFooterColor">
  <v-card-text class="mx-auto" style="max-width: 1024px;">
    <v-row align="start" justify="start" no-gutters v-if="(!windowSpSize)">

      <v-col cols=3 md=6 lg=3 xl=3 class="pb-5 text-left">
        <span class="ec-secHeading">ショッピングガイド</span>
        <v-list dense color="transparent">
          <v-list-item-group>
            <v-list-item @click="pageTransition('/guide?id=' + guide.id)" :key="'i-' + index" v-for="(guide, index) in list">
              <v-list-item-content>
                <v-list-item-title class="caption font-weight-bold"><span :style="{ color: buttonColor }" v-text="guide.text"></span></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>

      <v-col cols=4 md=6 lg=4 xl=4 class="pb-5 px-5 text-left">
        <span class="ec-secHeading">このサイトについて</span>
        <v-list dense color="transparent">
          <v-list-item-group>
            <v-list-item @click="pageTransition(about.route)" :key="'i-' + index" v-for="(about, index) in aboutSite">
              <v-list-item-content>
                <v-list-item-title class="caption font-weight-bold"><span :style="{ color: buttonColor }" v-text="about.text"></span></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>

      <v-col cols=5 md=12 lg=5 xl=5 class="pb-5 text-left">
        <span class="ec-secHeading">営業日カレンダー</span>
        <v-row align="center" justify="center" class="py-3" no-gutters>
          <v-col cols="6" class="ec-calendar text-center">
            <div v-html="getCalendar(0)"></div>
          </v-col>
          <v-col cols="6" class="ec-calendar text-center">
            <div v-html="getCalendar(1)"></div>
          </v-col>

          <v-col cols="12" class="ec-font-size10 text-left">
            <span style="color: #E0C240">■</span><span>印の日：電話・メール・Faxでのご注文・お問い合わせはお休み</span>
          </v-col>
          <v-col cols="12" class="ec-font-size10 text-left">
            <span style="color: #737373">■</span><span>印の日：全てお休み</span>
          </v-col>
          <v-col cols="12" class="ec-font-size10 text-left">
            <span>※ウェブからのご注文は365日承っております。</span>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols=12>
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <v-row align="center" justify="center" no-gutters>
      <v-col cols=12 class="pt-5">
        <v-row align="center" justify="center" class="mx-auto" no-gutters>
          <v-col cols=12 :class="(windowSpSize) ? 'pt-1 pb-5 text-center' : 'pr-5 pt-1 pb-5 text-right'">
            <v-img :src="require('@/assets/logo.png')" :class="(windowSpSize) ? 'mx-auto' : 'ml-auto'" style="max-width: 200px; height: auto;"></v-img>
          </v-col>
          <v-col cols=12 class="pr-5 text-center" v-if="(windowSpSize)">
            <v-icon color="R2MainColorLight" left>mdi-phone-in-talk</v-icon><span class="R2MainColorLight--text ec-font-size18 font-weight-black" v-text="Company.tel"></span><br />
            <span class="caption pl-5" v-text="`FAX: ${Company.fax}`"></span><br />
            <span class="caption" v-text="companyProfile.comment1"></span><br />
            <span class="caption" v-text="companyProfile.comment2"></span>
          </v-col>
          <v-col cols=12 class="pr-5 text-right" v-else>
            <v-icon color="R2MainColorLight" left>mdi-phone-in-talk</v-icon><span class="R2MainColorLight--text ec-font-size18 font-weight-black" v-text="Company.tel"></span><span class="caption pl-5" v-text="`FAX: ${Company.fax}`"></span><br />
            <span class="caption" v-text="companyProfile.comment1"></span><br />
            <span class="caption" v-text="companyProfile.comment2"></span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card-text>
</v-card>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

export default {
  name: 'ComponentsExtensionFooter',

  mixins: [MixinLib],

  data: () => ({
    list: [],
    aboutSite: [
      {
        route: '/company',
        text: '会社概要'
      },
      {
        route: '/policy',
        text: 'プライバシーポリシー'
      },
      {
        route: '/trade-law',
        text: '特定商取引法に基づく表記'
      },
      {
        route: '/contact',
        text: 'お問い合わせ'
      }
    ],
    /*
    shopGuide: [
      {
        route: '/guide',
        text: 'ご注文について'
      },
      {
        route: '/guide',
        text: 'お届けについて'
      },
      {
        route: '/guide',
        text: 'お支払いについて'
      },
      {
        route: '/guide',
        text: '返品・交換・キャンセルについて'
      },
      {
        route: '/guide',
        text: '商品について'
      },
      {
        route: '/guide',
        text: 'その他'
      }
    ],
    */
    companyProfile: {
      comment1: 'お電話受付：10:00～18:00(土日祝を除く)',
      comment2: 'ウェブからのご注文は営業日時外も承っております'
    }
  }),

  computed: {
    Company: {
      get () {
        return this.$store.getters['modStorage/Company']
      }
    }
  },

  mounted: function () {
    this.goLoading()
  },

  methods: {
    goLoading: function () {
      (async () => {
        this.list = []
        this.isProcessLoading = true

        const resultRows = await APILib.getGuideList()
        this.list = resultRows.map(item => {
          return {
            id: item.guide_id,
            text: item.contents.title
          }
        })

        this.isProcessLoading = false
      })().catch((e) => {
        console.log(e)
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    },

    getCalendar: function (addMonth) {
      const calendarCode1 = '<iframe src="https://calendar.google.com/calendar/embed?'
      const calenderCode2 = 'showTitle=0&amp;showNav=0&amp;showPrint=0&amp;showTabs=0&amp;showCalendars=0&amp;showTz=0&amp;height=600&amp;wkst=1&amp;bgcolor=%23FFFFFF&amp;src=6p7ov12f3fc6luvifo22hqv3po%40group.calendar.google.com&amp;color=%23875509&amp;ctz=Asia%2FTokyo" style="border-width:0" width="800" height="600" frameborder="0" scrolling="no"></iframe>'
      const month = this.getDate(this.addDateTime([], addMonth, 'months'), 'YYYYMM')
      const dates = 'dates=' + month + '01/' + month + '01&amp;'
      return calendarCode1 + dates + calenderCode2
    },

    pageTransition: function (url) {
      if (url.startsWith('https://')) {
        window.open(url, '_blank')
      } else {
        this.$router.push({ path: url }, () => {})
      }
    }
  }
}
</script>
<style scope>
.ec-extension-footer .ec-calendar iframe {
    width: 98%;
    height: 250px;
    margin-right: 2%;
}
.ec-extension-footer .ec-secHeading {
    font-size: clamp(12px, 1.2vw, 14px);
    font-weight: bold;
    letter-spacing: .2em;
}
</style>
