<template>
<v-card flat color="transparent" class="mx-2" :width="imageWidth" @click="$emit('click')">

  <v-card flat color="white" class="pa-2" :width="imageWidth" :height="imageHeight">
    <v-row align="center" justify="center" style="height: 100%" no-gutters>
      <v-col cols="12" >
        <v-img :src="imgSrc" style="background-position:center" contain :height="imageHeight">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="grey lighten-5" ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
  </v-card>

  <v-card-text class="text-left">
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12" class="text-center font-weight-bold" :style="{ color: buttonColor }">
        <span :class="fontSize" v-text="label"></span>
      </v-col>
    </v-row>
  </v-card-text>
</v-card>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

export default {
  name: 'ComponentsViewFormsSquareBrand',

  mixins: [MixinLib],

  data: () => ({
  }),

  computed: {
    imageWidth: {
      get () {
        if (this.small) {
          return (this.$vuetify.breakpoint.mdAndDown) ? 96 : 120
        } else {
          return (this.$vuetify.breakpoint.mdAndDown) ? 120 : 160
        }
      }
    },
    imageHeight: {
      get () {
        if (this.small) {
          return (this.$vuetify.breakpoint.mdAndDown) ? 48 : 60
        } else {
          return (this.$vuetify.breakpoint.mdAndDown) ? 60 : 80
        }
      }
    },
    fontSize: {
      get () {
        if (this.small) {
          return 'ec-font-size10'
        } else {
          return 'ec-font-size12'
        }
      }
    }
  },

  props: {
    imgSrc: {
      type: String,
      require: false,
      default: () => ''
    },
    label: {
      type: String,
      require: false,
      default: () => ''
    },
    small: {
      type: Boolean,
      require: false,
      default: () => ''
    }
  }
}
</script>
<style scoped>
.v-image__image {
  background-position: center;
}
</style>
