<template>
<v-card flat color="transparent" class="mx-1 pt-3" :width="width" v-if="loading">
  <v-card flat color="grey lighten-2" class="pa-0 ma-0" :width="width" :height="height"></v-card>
  <v-card-text class="text-left pa-1">
    <v-row align="center" justify="start" no-gutters>
      <v-col :cols="(windowSpSize) ? 2 : 3" class="d-flex child-flex pa-1" v-for="(imgSrc, index) in [...Array(8)]" :key="index">
        <v-card flat color="grey lighten-2 text-center" :style="`height: calc((${width}px / 4) - 20px); width: calc((${width}px / 4) - 20px);`"></v-card>
      </v-col>
    </v-row>
  </v-card-text>
</v-card>
<v-card flat color="transparent" class="mx-1 pt-3" :width="width" v-else>
  <v-card flat color="grey lighten-2" class="pa-0 ma-0" :width="width" :height="height">
    <v-row align="center" justify="center" style="height: 100%" no-gutters>
      <v-col cols="12">
        <v-img :src="(isUndefined(images)) ? '' : images[imgSrcIndex].img_src" class="mx-auto" style="background-position:center"></v-img>
      </v-col>
    </v-row>
  </v-card>
  <v-card-text class="text-left pa-1">
    <v-row align="center" justify="start" no-gutters>
      <v-col :cols="(windowSpSize) ? 2 : 3" class="d-flex child-flex pa-1" v-for="(imgSrc, index) in images" :key="index">
        <v-img class="grey lighten-2" aspect-ratio="1" :src="(isUndefined(imgSrc)) ? '' : imgSrc.img_src" @click="imgSrcIndex = index">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
  </v-card-text>
</v-card>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

export default {
  name: 'ComponentsViewFormsProductsImage',

  mixins: [MixinLib],

  data: () => ({
    imgSrcIndex: 0
  }),

  methods: {
  },

  props: {
    images: {
      type: Array,
      require: true,
      default: () => {
        return []
      }
    },
    loading: {
      type: Boolean,
      require: false,
      default: () => false
    },
    width: [String, Number],
    height: [String, Number]
  }
}
</script>
