<template>
<v-row v-if="loading">
  <v-col cols=12>
    <v-row align="center" justify="center" no-gutters>
      <v-col cols=12 class="pb-3">
        <v-skeleton-loader class="mx-auto" type="text" height="80"></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-col>
</v-row>
<v-row v-else>
  <v-col cols=12>
    <v-row align="center" justify="center" no-gutters>
      <v-col cols=12 class="pb-3">
        <span class="ec-font-size14 font-weight-bold" v-html="products.catch_copy"></span>
      </v-col>
      <v-col cols=12>
        <pre :style="{ whiteSpace: 'pre-wrap' }" class="ec-font-size12" v-html="products.outline"></pre>
      </v-col>
    </v-row>
  </v-col>
</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

export default {
  name: 'ComponentsViewFormsProductsDescription',

  mixins: [MixinLib],

  data: () => ({
    showSupplyAmount: false,
    showRemarks: false
  }),

  props: {
    products: {
      type: Object,
      require: true,
      default: () => {
        return {
          brand_id: '',
          calorie: '',
          catch_copy: '',
          component_value: '',
          country: '',
          goods_explain: '',
          information: '',
          normal_freeze_status: '',
          outline: '',
          products_name: '',
          remarks: '',
          set_contents: '',
          size_details: '',
          supply_amount: '',
          use_target_status: ''
        }
      }
    },
    loading: {
      type: Boolean,
      require: false,
      default: () => false
    }
  }
}
</script>
