<template>
<ComponentsLayoutPage1>
  <v-row align="start" justify="start" :style="{ paddingBottom: '100px' }">

    <!-- 商品タイトル -->
    <v-col cols=12 class="py-5" v-if="windowSpSize">
      <ComponentsViewFormsProductsTitle
        :products="contents.products"
        :products_categories="contents.category"
        :loading="isProcessLoading"
      >
      </ComponentsViewFormsProductsTitle>
    </v-col>
    <v-col cols=12 class="pb-5" v-if="windowSpSize">
      <!-- 商品基本情報 -->
      <ComponentsViewFormsProductsDescription
        :products="contents.products"
        :loading="isProcessLoading"
      >
      </ComponentsViewFormsProductsDescription>
    </v-col>

    <!-- 商品画像 -->
    <v-col cols=12 sm=12 md=4 lg=4 xl=4 class="pb-5">
      <ComponentsViewFormsProductsImage
        :images="contents.images"
        :width="imageWidthSize"
        :height="imageHeightSize"
        :loading="isProcessLoading"
        class="mx-auto"
      >
      </ComponentsViewFormsProductsImage>
    </v-col>

    <v-col cols=8 v-if="!windowSpSize">
      <v-col cols=12>
        <!-- 商品タイトル -->
        <ComponentsViewFormsProductsTitle
          :products="contents.products"
          :products_categories="contents.category"
          :loading="isProcessLoading"
        >
        </ComponentsViewFormsProductsTitle>
      </v-col>
      <v-col cols=12>
        <!-- 商品基本情報 -->
        <ComponentsViewFormsProductsDescription
          :products="contents.products"
          :loading="isProcessLoading"
        >
        </ComponentsViewFormsProductsDescription>
      </v-col>
      <v-col cols=12>
        <!-- 購買リスト追加ボタン -->
        <ComponentsViewFormsProductsSetOrderButton
          :loading="isProcessLoading"
          :products-id="ProductCode"
          @click-add="addFavorites()"
          @click-remove="removeFavorites()"
        >
        </ComponentsViewFormsProductsSetOrderButton>
      </v-col>
    </v-col>

    <!-- 商品サイズ -->
    <v-col cols=12 v-if="!isSignIn">
      <ComponentsViewFormsProductsApply
        warningMessage="商品の購入にはお取引のご契約が必要です"
      >
      </ComponentsViewFormsProductsApply>
    </v-col>
    <v-col cols=12 v-else>
      <ComponentsViewFormsProductsCollection
        :products-id="contents.products_id"
        :loading="isProcessLoading"
      >
      </ComponentsViewFormsProductsCollection>
    </v-col>

    <!-- 購買リスト追加ボタン -->
    <v-col cols=12 class="pb-5" v-if="windowSpSize">
      <ComponentsViewFormsProductsSetOrderButton
        :loading="isProcessLoading"
        :products-id="ProductCode"
        @click-add="addFavorites()"
        @click-remove="removeFavorites()"
      >
      </ComponentsViewFormsProductsSetOrderButton>
    </v-col>

    <!-- 商品説明 -->
    <v-col cols=12>
      <ComponentsViewFormsProductsContents
        :products="contents.products"
        :materials="contents.materials"
        :loading="isProcessLoading"
      >
      </ComponentsViewFormsProductsContents>
    </v-col>

    <!-- セールスポイント -->
    <v-col cols=12 class="py-3" v-show="isUndefined(getValue('sales_point', getValue('sales_point', contents) || {} )) === false">
      <ComponentsViewFormsProductsSalePoint
        :products-sales-point="contents.sales_point"
        :loading="isProcessLoading"
      >
      </ComponentsViewFormsProductsSalePoint>
    </v-col>

    <!-- カスタマーQ/A -->
    <v-col cols=12 class="py-3" v-show="(getValue('qa', contents) || []).length > 0">
      <ComponentsViewFormsProductsQA
        :products-qa="contents.qa"
        :loading="isProcessLoading"
      >
      </ComponentsViewFormsProductsQA>
    </v-col>
  </v-row>
</ComponentsLayoutPage1>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutPage1 from '@/components/layout/Page1'
import ComponentsViewFormsProductsTitle from '@/components/view-forms/ProductsTitle'
import ComponentsViewFormsProductsDescription from '@/components/view-forms/ProductsDescription'
import ComponentsViewFormsProductsImage from '@/components/view-forms/ProductsImage'
import ComponentsViewFormsProductsContents from '@/components/view-forms/ProductsContents'
import ComponentsViewFormsProductsSetOrderButton from '@/components/view-forms/ProductsSetOrderButton'
import ComponentsViewFormsProductsApply from '@/components/view-forms/ProductsApply'
import ComponentsViewFormsProductsSalePoint from '@/components/view-forms/ProductsSalePoint'
import ComponentsViewFormsProductsQA from '@/components/view-forms/ProductsQA'
import ComponentsViewFormsProductsCollection from '@/components/view-forms/ProductsCollection'

export default {
  name: 'ProductsPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true,
    contents: {}
  }),

  computed: {
    ProductCode: {
      get () {
        return this.$route.params.productCode
      }
    },
    imageWidthSize: {
      get () {
        const widthSize = (this.windowWidthSize >= 960) ? 960 : this.windowWidthSize
        if (!this.$vuetify.breakpoint.smAndDown) {
          return parseInt((widthSize / 3) - 30)
        }
        return (this.$vuetify.breakpoint.md) ? parseInt((widthSize / 2) - 30) : parseInt(widthSize - 30)
      }
    },
    imageHeightSize: {
      get () {
        return this.imageWidthSize
      }
    }
  },

  mounted: function () {
    this.goLoading()
  },

  methods: {
    // 初期処理
    goLoading: function () {
      (async () => {
        this.isProcessLoading = true
        this.contents = await APILib.getProducts(this.ProductCode)
        this.isProcessLoading = false
      })().catch((e) => {
        this.isProcessLoading = false
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    },
    addFavorites: function () {
      (async () => {
        this.isProcessLoading = true
        const contents = await APILib.postFavorites(this.ProductCode)
        this.$store.dispatch('modStorage/SetUsersFavorites', contents)
        this.$confirm({ title: 'Success', message: '購買リストに追加しました', button: { yes: 'OK' } })
        this.isProcessLoading = false
      })().catch((e) => {
        this.isProcessLoading = false
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    },
    removeFavorites: function () {
      (async () => {
        this.isProcessLoading = true
        const contents = await APILib.deleteFavorites(this.ProductCode)
        this.$store.dispatch('modStorage/SetUsersFavorites', contents)
        this.$confirm({ title: 'Success', message: '購買リストから除外しました', button: { yes: 'OK' } })
        this.isProcessLoading = false
      })().catch((e) => {
        this.isProcessLoading = false
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    }
  },

  components: {
    ComponentsLayoutPage1,
    ComponentsViewFormsProductsTitle,
    ComponentsViewFormsProductsDescription,
    ComponentsViewFormsProductsImage,
    ComponentsViewFormsProductsContents,
    ComponentsViewFormsProductsSalePoint,
    ComponentsViewFormsProductsQA,
    ComponentsViewFormsProductsSetOrderButton,
    ComponentsViewFormsProductsApply,
    ComponentsViewFormsProductsCollection
  }
}
</script>
