<template>
<v-row align="center" justify="center">

  <v-col cols=12>
    <v-alert
      text
      type="info"
    >
      <span :class="(windowSpSize) ? 'ec-font-size12' : 'ec-font-size14'">
        登録情報のご変更については、大変お手数ですが info@r2-pet.com 宛にご連絡下さい
      </span>
    </v-alert>
  </v-col>

  <v-col cols=12>
    <ComponentsLayoutHeading1 text="基本情報"></ComponentsLayoutHeading1>
  </v-col>

  <v-col cols=12>
    <ComponentsLayoutSection1>
      <v-row align="center" justify="center">
        <v-col cols=12>
          <ComponentsLayoutHeading3 text="お名前(漢字)" ></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12>
          <ComponentsLayoutSection2>
            <span v-text="Users.dealings.dealings_name"></span>
          </ComponentsLayoutSection2>
        </v-col>

        <v-col cols=12>
          <ComponentsLayoutHeading3 text="お名前(漢字)" ></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12>
          <ComponentsLayoutSection2>
            <span v-text="Users.dealings.dealings_name"></span>
          </ComponentsLayoutSection2>
        </v-col>

        <v-col cols=12>
          <ComponentsLayoutHeading3 text="お名前(カナ)" ></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12>
          <ComponentsLayoutSection2>
            <span v-text="Users.dealings.dealings_name_kana"></span>
          </ComponentsLayoutSection2>
        </v-col>

        <v-col cols=12>
          <ComponentsLayoutHeading3 text="所在地" ></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12>
          <ComponentsLayoutSection2>
            <span v-text="Users.dealings.post_no"></span>
            <span v-text="getPrefectureName(Users.dealings.prefecture_id)"></span>
            <span v-text="Users.dealings.address"></span>
          </ComponentsLayoutSection2>
        </v-col>

        <v-col cols=12>
          <ComponentsLayoutHeading3 text="代表者名" ></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12>
          <ComponentsLayoutSection2>
            <span v-text="Users.dealings.director_name"></span>
          </ComponentsLayoutSection2>
        </v-col>

        <v-col cols=12>
          <ComponentsLayoutHeading3 text="電話番号" ></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12>
          <ComponentsLayoutSection2>
            <span v-text="Users.dealings.tel"></span>
          </ComponentsLayoutSection2>
        </v-col>

        <v-col cols=12>
          <ComponentsLayoutHeading3 text="FAX番号" ></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12>
          <ComponentsLayoutSection2>
            <span v-text="Users.dealings.fax"></span>
          </ComponentsLayoutSection2>
        </v-col>

        <v-col cols=12>
          <ComponentsLayoutHeading3 text="URL" ></ComponentsLayoutHeading3>
        </v-col>
        <v-col cols=12>
          <ComponentsLayoutSection2>
            <span v-text="Users.dealings.url"></span>
          </ComponentsLayoutSection2>
        </v-col>
      </v-row>
    </ComponentsLayoutSection1>
  </v-col>
</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsLayoutSection2 from '@/components/layout/Section2'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsLayoutHeading3 from '@/components/layout/Heading3'

export default {
  name: 'MyPageProfilePage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false
  }),

  computed: {
    Users: {
      get () {
        return this.$store.getters['modStorage/Users']
      }
    }
  },

  mounted: function () {
    if (!this.isSignIn) {
      this.pageTransition('/login')
    }
  },

  methods: {
  },

  components: {
    ComponentsLayoutSection1,
    ComponentsLayoutSection2,
    ComponentsLayoutHeading1,
    ComponentsLayoutHeading3
  }
}
</script>
