<template>
<ComponentsLayoutPage1>
  <v-row align="center" justify="center" class="mx-auto" :style="{ maxWidth: '680px', paddingTop: '30px', paddingBottom: '50px' }">
    <v-col cols=12 >
      <ComponentsLayoutSection1>
        <v-row align="center" justify="center" class="pb-3">
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <v-chip x-small label color="grey lighten-1" class="mr-2 white--text">会社名</v-chip>
          </v-col>
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <span class="ec-font-size18" v-text="Company.company_name"></span>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row align="center" justify="center" class="pb-3 pt-5">
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <v-chip x-small label color="grey lighten-1" class="mr-2 white--text">本社</v-chip>
          </v-col>
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <span class="ec-font-size12" v-text="'〒 ' + Company.post_no"></span><br />
            <span class="ec-font-size12" v-text="getPrefectureName(Company.prefecture_id)"></span>
            <span class="ec-font-size12" v-text="Company.address"></span>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row align="center" justify="center" class="pb-3 pt-5">
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <v-chip x-small label color="grey lighten-1" class="mr-2 white--text">TEL</v-chip>
          </v-col>
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <span class="ec-font-size12" v-text="Company.tel"></span>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row align="center" justify="center" class="pb-3 pt-5">
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <v-chip x-small label color="grey lighten-1" class="mr-2 white--text">FAX</v-chip>
          </v-col>
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <span class="ec-font-size12" v-text="Company.fax"></span>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row align="center" justify="center" class="pb-3 pt-5">
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <v-chip x-small label color="grey lighten-1" class="mr-2 white--text">設立年月日</v-chip>
          </v-col>
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <span class="ec-font-size12" v-text="formatDate(Company.founding_date,'YYYY年MM月DD日')"></span>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row align="center" justify="center" class="pb-3 pt-5">
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <v-chip x-small label color="grey lighten-1" class="mr-2 white--text">役員</v-chip>
          </v-col>
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <span class="ec-font-size12" v-text="Company.director_name"></span>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row align="center" justify="center" class="pb-3 pt-5">
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <v-chip x-small label color="grey lighten-1" class="mr-2 white--text">事業内容</v-chip>
          </v-col>
          <v-col :cols="(windowSpSize) ? 12 : 6">
            <span style="white-space: pre-line;" class="ec-font-size12" v-html="Company.content"></span>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row align="center" justify="center" class="pb-3 pt-5">
          <v-col cols=12>
            <v-chip x-small label color="grey lighten-1" class="mr-2 white--text">主要取扱いブランド</v-chip>
          </v-col>
          <v-col cols=12>
            <v-row align="start" justify="start" no-gutters>
              <v-col cols=6 sm=4 md=3 lg=3 xl=3 v-for="item in CompanyBrand" :key="item.brand_id">
                <ComponentsViewFormsSquareBrand
                  :img-src="item.brand.img_src"
                  :label="item.brand.brand_name"
                  @click="pageTransition('/productsList?brand=' + item.brand_id)"
                >
                </ComponentsViewFormsSquareBrand>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row align="center" justify="center" class="pb-3 pt-5">
          <v-col cols=12>
            <v-chip x-small label color="grey lighten-1" class="mr-2 white--text">グループ会社のご紹介</v-chip>
          </v-col>
          <v-col cols=12>
            <v-row align="start" justify="start" class="pb-5" no-gutters>
              <v-col cols=12 class="pa-3">
                <v-img :src="imgSrc" style="background-position:center" contain :height="80">
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="grey lighten-5" ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
              <v-col cols=12 class="text-center">
                <span>株式会社R2は株式会社カラーズの100%出資子会社となります。<br />親会社であるカラーズではペット業界において様々な事業を展開しております。<br /><br /><a href="https://www.colorzoo.com/" target="_blank">詳しくはこちら</a></span>
              </v-col>
            </v-row>
            <v-row align="start" justify="start" no-gutters>
              <v-col :cols="(windowSpSize) ? 12 : 6" v-for="(item, index) in Groups" :key="index">
                <v-card flat outlined class=" ma-2" :href="item.links" target="_blank">
                  <v-row align="center" justify="center" style="height: 100%" no-gutters>
                    <v-col cols="12">
                      <v-img :src="item.imgSrc" style="background-position:center" contain :height="groupImageHeight">
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="grey lighten-5" ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
                  <v-card-text class="text-left">
                    <v-row align="center" justify="center" no-gutters>
                      <v-col cols="12" class="text-center font-weight-bold" :style="{ color: buttonColor }">
                        <span :class="groupFontSize" v-text="item.label"></span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </ComponentsLayoutSection1>
    </v-col>
  </v-row>
</ComponentsLayoutPage1>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutPage1 from '@/components/layout/Page1'
import ComponentsLayoutSection1 from '@/components/layout/Section2'
import ComponentsViewFormsSquareBrand from '@/components/view-forms/SquareBrand'

export default {
  name: 'InformationCompanyPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true,
    imgSrc: require('@/assets/logo/about-czl.svg')
  }),

  computed: {
    Company: {
      get () {
        return this.$store.getters['modStorage/Company']
      }
    },
    CompanyBrand: {
      get () {
        return this.$store.getters['modStorage/CompanyBrand']
      }
    },
    groupImageWidth: {
      get () {
        return (this.$vuetify.breakpoint.mdAndDown) ? 200 : 200
      }
    },
    groupImageHeight: {
      get () {
        return (this.$vuetify.breakpoint.mdAndDown) ? 122 : 122
      }
    },
    groupFontSize: {
      get () {
        return 'ec-font-size14'
      }
    },
    Groups: {
      get () {
        return [
          {
            imgSrc: require('@/assets/logo/about-logo-greendog.jpg'),
            links: 'http://www.green-dog.com/',
            label: '通販・実店舗事業'
          },
          {
            imgSrc: require('@/assets/logo/about-logo-vec.jpg'),
            links: 'http://www.vec-j.com/',
            label: '動物病院事業'
          },
          {
            imgSrc: require('@/assets/logo/about-logo-cze.jpg'),
            links: 'http://www.colorzoo-edu.jp/',
            label: '人材育成事業'
          },
          {
            imgSrc: require('@/assets/logo/about-logo-czp.jpg'),
            links: '',
            label: '商品製造事業'
          }
        ]
      }
    }
  },

  components: {
    ComponentsLayoutPage1,
    ComponentsLayoutSection1,
    ComponentsViewFormsSquareBrand
  }
}
</script>
