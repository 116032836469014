import UtilLib from '@/libs/UtilLib'
import APILib from '@/libs/APILib'

import axios from 'axios'
import xmljs from 'xml-js'

const state = {
  UseTargetStatus: [
    { id: '1', name: '犬用品' },
    { id: '2', name: '猫用品' },
    { id: '9', name: '犬猫兼用' }
  ],
  News: [],
  Categories: [],
  Maker: [],
  Brand: [],
  BrandCategory: [],
  Prefecture: [],
  OrderStatus: [],
  NewRelease: [],
  Introduce: [],
  Recommend: [],
  Ranking: [],
  Pamphlet: [],
  blogFeeds: [],
  Users: [],
  UsersAddress: [],
  UsersFavorites: [],
  UsersFavoritesOriginals: [],
  Company: {},
  CompanyBrand: [],
  Policy: [],
  Tradelaw: [],
  ShopForm: [],
  RouteMethod: [],
  PickupBrand: [],
  ContactMethod: [],
  EntryFormItems: [],
  CampaignList: [],
  CampaignListFuture: [],
  CampaignListProducts: [],
  MaintenanceMode: false,
  PaymentsCredit: [],
  SalesManagerStatus: [
    { code: '1', name: '有' },
    { code: '2', name: '無' }
  ],
  LicenseStatus: [
    { code: '1', name: '保持' },
    { code: '2', name: '未保持' }
  ]
}

const getters = {
  use_target_status: (state) => {
    return state.Categories
  },
  News: (state) => {
    return state.News
  },
  Categories: (state) => {
    return state.Categories
  },
  Maker: (state) => {
    return state.Maker
  },
  Brand: (state) => {
    return state.Brand
  },
  BrandCategory: (state) => {
    return state.BrandCategory
  },
  Prefecture: (state) => {
    return state.Prefecture
  },
  OrderStatus: (state) => {
    return state.OrderStatus
  },
  NewRelease: (state) => {
    return state.NewRelease
  },
  Introduce: (state) => {
    return state.Introduce
  },
  Recommend: (state) => {
    return state.Recommend
  },
  Ranking: (state) => {
    return state.Ranking
  },
  Pamphlet: (state) => {
    return state.Pamphlet
  },
  blogFeeds: (state) => {
    return state.blogFeeds
  },
  Users: (state) => {
    return state.Users
  },
  UsersFees: (state) => {
    return (UtilLib.exists(state.Users, 'dealings_fee')) ? state.Users.dealings_fee || [] : []
  },
  postage: (state) => (price) => {
    if (UtilLib.exists(state.Users, 'dealings_fee') === false) {
      return 0
    }
    const fees = state.Users.dealings_fee.filter(item => parseInt(price) >= parseInt(item.price))
    if (fees.length === 0) {
      return 0
    }
    if (fees.length === 1) {
      return parseInt(fees[0].fee)
    }
    const SortFees = fees.sort((a, b) => { return (parseInt(a.price) > parseInt(b.price)) ? -1 : 1 })
    return parseInt(SortFees[0].fee)
  },
  postageFreeLine: (state) => {
    if (UtilLib.exists(state.Users, 'dealings_fee') === false) {
      return -1
    }
    const index = state.Users.dealings_fee.findIndex(item => parseInt(item.fee) === 0)
    if (index >= 0) {
      return parseInt(state.Users.dealings_fee[index].price)
    } else {
      return -1
    }
  },
  UsersAddress: (state) => {
    return state.UsersAddress
  },
  UsersFavorites: (state) => {
    return state.UsersFavorites
  },
  UsersFavoritesOriginals: (state) => {
    return state.UsersFavoritesOriginals
  },
  Company: (state) => {
    return state.Company
  },
  CompanyBrand: (state) => {
    return state.CompanyBrand
  },
  Policy: (state) => {
    return state.Policy
  },
  Tradelaw: (state) => {
    return state.Tradelaw
  },
  ShopForm: (state) => {
    return state.ShopForm
  },
  RouteMethod: (state) => {
    return state.RouteMethod
  },
  PickupBrand: (state) => {
    return state.PickupBrand
  },
  ContactMethod: (state) => {
    return state.ContactMethod
  },
  EntryFormItems: (state) => {
    return state.EntryFormItems
  },
  CampaignList: (state) => {
    return state.CampaignList
  },
  CampaignListFuture: (state) => {
    return state.CampaignListFuture
  },
  CampaignListProducts: (state) => {
    return state.CampaignListProducts
  },
  MaintenanceMode: (state) => {
    return state.MaintenanceMode
  },
  PaymentsCredit: (state) => {
    return state.PaymentsCredit
  },
  SalesManagerStatus: (state) => {
    return state.SalesManagerStatus
  },
  LicenseStatus: (state) => {
    return state.LicenseStatus
  },
  /* --------------------------------- */
  /*                                   */
  /* --------------------------------- */
  UseTargetStatusName: (state) => (code) => {
    const index = state.UseTargetStatus.findIndex(item => item.id === code)
    return (index >= 0) ? state.UseTargetStatus[index].name : ''
  },
  BrandName: (state) => (code) => {
    const index = state.Brand.findIndex(item => item.brand_id === code)
    return (index >= 0) ? state.Brand[index].brand.brand_name : ''
  },
  CategoriesName: (state) => (code) => {
    const index = state.Categories.findIndex(item => item.categories_id === code)
    return (index >= 0) ? state.Categories[index].categories_name.split('>').map(v => v.trim()).pop() : ''
  },
  PrefectureName: (state) => (code) => {
    const index = state.Prefecture.findIndex(item => item.code === code)
    return (index >= 0) ? state.Prefecture[index].name : ''
  },
  /* --------------------------------- */
  /*                                   */
  /* --------------------------------- */
  viewCategories: (state) => {
    const results = state.UseTargetStatus.map(item => { return { ...item, categories: [] } })
    for (const item of state.Categories) {
      const index1 = results.findIndex(v => v.id === item.categories_parent_id)
      if (index1 >= 0) {
        const categories = item.categories_name.split('>').map(v => v.trim())
        if (categories.length !== 3) {
          continue
        }
        const index2 = results[index1].categories.findIndex(v => v.name === categories[1])
        if (index2 >= 0) {
          results[index1].categories[index2].categories.push({ id: item.categories_id, name: categories[2] })
        } else {
          results[index1].categories.push({ id: 0, name: categories[1], categories: [{ id: item.categories_id, name: categories[2] }] })
        }
      }
    }
    return results
  },
  viewBrands: (state) => {
    return state.Brand.filter(v => v.brand.status === '1').map(v => { return { ...v.brand, id: v.brand_id } })
  },
  viewBrandCategories: (state) => {
    return state.BrandCategory.filter(v => v.contents.status === '1').map(v => { return { id: v.brand_category_id, name: v.contents.brand_category_name } })
  }
}

const mutations = {
  News (state, contents) {
    state.News = contents
  },
  Categories (state, contents) {
    state.Categories = contents
  },
  Maker (state, contents) {
    state.Maker = contents
  },
  Brand (state, contents) {
    state.Brand = contents
  },
  BrandCategory (state, contents) {
    state.BrandCategory = contents
  },
  Prefecture (state, contents) {
    state.Prefecture = contents
  },
  OrderStatus (state, contents) {
    state.OrderStatus = contents
  },
  NewRelease (state, contents) {
    state.NewRelease = contents
  },
  Introduce (state, contents) {
    state.Introduce = contents
  },
  Recommend (state, contents) {
    state.Recommend = contents
  },
  Ranking (state, contents) {
    state.Ranking = contents
  },
  Pamphlet (state, contents) {
    state.Pamphlet = contents
  },
  blogFeeds (state, jsonData) {
    const articles = jsonData.elements.filter(v => v.name === 'item')
    for (const article of articles) {
      const title = article.elements.find(v => v.name === 'title')
      const link = article.elements.find(v => v.name === 'link')
      const thumb = article.elements.find(v => v.name === 'thumb')
      const category = article.elements.filter(v => v.name === 'category')
      const pubDate = article.elements.find(v => v.name === 'pubDate')
      const blogFeeds = {
        title: (title) ? title.elements[0].text : '',
        link: (link) ? link.elements[0].text : '',
        thumb: (thumb) ? thumb.elements[0].text : '',
        category: (category) ? category.map(v => v.elements[0].cdata) : [],
        pubDate: (pubDate) ? pubDate.elements[0].text : ''
      }
      state.blogFeeds.push(blogFeeds)
    }
  },
  Users (state, contents) {
    state.Users = contents
  },
  UsersAddress (state, contents) {
    state.UsersAddress = contents
  },
  UsersFavorites (state, contents) {
    state.UsersFavorites = contents
  },
  UsersFavoritesOriginals (state, contents) {
    state.UsersFavoritesOriginals = contents
  },
  Company (state, contents) {
    state.Company = UtilLib.getValue('company', contents) || {}
    state.CompanyBrand = UtilLib.getValue('focus_brand', contents) || []
  },
  Policy (state, contents) {
    state.Policy = contents.contents
  },
  Tradelaw (state, contents) {
    state.Tradelaw = contents.contents
  },
  ShopForm (state, contents) {
    state.ShopForm = contents
  },
  RouteMethod (state, contents) {
    state.RouteMethod = contents
  },
  PickupBrand (state, contents) {
    state.PickupBrand = contents
  },
  ContactMethod (state, contents) {
    state.ContactMethod = contents
  },
  EntryFormItems (state, contents) {
    state.EntryFormItems = contents
  },
  CampaignList (state, contents) {
    const nowTime = UtilLib.formatDate([], 'YYYY-MM-DD')
    state.CampaignList = contents.filter(item => {
      const strTime = UtilLib.formatDate(item.campaign.sales_str_date, 'YYYY-MM-DD')
      const endTime = UtilLib.formatDate(item.campaign.sales_end_date, 'YYYY-MM-DD')
      return (nowTime >= strTime && endTime >= nowTime)
    })
    state.CampaignListFuture = contents.filter(item => {
      const strTime = UtilLib.formatDate(item.campaign.sales_str_date, 'YYYY-MM-DD')
      return (strTime > nowTime)
    })
  },
  CampaignListProducts (state, contents) {
    state.CampaignListProducts = contents
  },
  MaintenanceMode (state, contents) {
    state.MaintenanceMode = contents
  },
  PaymentsCredit (state, contents) {
    state.PaymentsCredit = contents
  }
}

const actions = {
  LoadMaster ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      return Promise.all(
        dispatch('LoadNews'),
        dispatch('LoadCategories'),
        dispatch('LoadPrefecture'),
        dispatch('LoadOrderStatus'),
        dispatch('LoadMaker'),
        dispatch('LoadBrandCategory'),
        dispatch('LoadBrand'),
        dispatch('LoadNewRelease'),
        dispatch('LoadIntroduce'),
        dispatch('LoadRecommend'),
        dispatch('LoadRanking'),
        dispatch('refreshBlogFeeds'),
        dispatch('LoadCompany'),
        dispatch('LoadPolicy'),
        dispatch('LoadTradelaw'),
        dispatch('LoadPamphlet'),
        dispatch('LoadShopForm'),
        dispatch('LoadRouteMethod'),
        dispatch('LoadPickupBrand'),
        dispatch('LoadContactMethod'),
        dispatch('LoadEntryFormItems'),
        dispatch('LoadCampaignList'),
        dispatch('LoadCampaignListProducts'),
        dispatch('LoadMaintenance')
      ).then(() => {
        return resolve(true)
      }).catch((e) => {
        return reject(e)
      })
    })
  },
  LoadCompany ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        commit('Company', await APILib.getCompany())
        resolve()
      })().catch((e) => {
        reject(e)
      })
    })
  },
  LoadPolicy ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        commit('Policy', await APILib.getPolicy())
        resolve()
      })().catch((e) => {
        reject(e)
      })
    })
  },
  LoadTradelaw ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        commit('Tradelaw', await APILib.getTradelaw())
        resolve()
      })().catch((e) => {
        reject(e)
      })
    })
  },
  LoadNews ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        const contents = await APILib.getNews()
        commit('News', contents)
        resolve(contents)
      })().catch((e) => {
        reject(e)
      })
    })
  },
  LoadCategories ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        const contents = await APILib.getCategoriesList()
        commit('Categories', contents)
        resolve(contents)
      })().catch((e) => {
        reject(e)
      })
    })
  },
  LoadMaker ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        const contents = await APILib.getMakerList()
        commit('Maker', contents)
        resolve(contents)
      })().catch((e) => {
        reject(e)
      })
    })
  },
  LoadBrand ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        const contents = await APILib.getBrandList()
        commit('Brand', contents)
        resolve(contents)
      })().catch((e) => {
        reject(e)
      })
    })
  },
  LoadBrandCategory ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        const contents = await APILib.getBrandCategories()
        commit('BrandCategory', contents)
        resolve(contents)
      })().catch((e) => {
        reject(e)
      })
    })
  },
  LoadPrefecture ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        commit('Prefecture', await APILib.getComponentPrefecture())
        resolve()
      })().catch((e) => {
        reject(e)
      })
    })
  },
  LoadOrderStatus ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        commit('OrderStatus', await APILib.getComponentOrderStatus())
        resolve()
      })().catch((e) => {
        reject(e)
      })
    })
  },
  LoadNewRelease ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        commit('NewRelease', await APILib.getNewRelease())
        resolve()
      })().catch((e) => {
        reject(e)
      })
    })
  },
  LoadIntroduce ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        commit('Introduce', await APILib.getIntroduce())
        resolve()
      })().catch((e) => {
        reject(e)
      })
    })
  },
  LoadRecommend ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        // commit('Recommend', await APILib.getIntroduce())
        resolve()
      })().catch((e) => {
        reject(e)
      })
    })
  },
  LoadRanking ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        console.log('LoadRanking')
        commit('Ranking', await APILib.getRanking())
        resolve()
      })().catch((e) => {
        reject(e)
      })
    })
  },
  LoadPamphlet ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        commit('Pamphlet', await APILib.getPamphletList())
        resolve()
      })().catch((e) => {
        reject(e)
      })
    })
  },
  refreshBlogFeeds ({ commit, dispatch, state }) {
    axios.get(process.env.VUE_APP_R2BLOG_FEED_URL)
      .then(function (response) {
        state.blogFeeds = []
        const json = xmljs.xml2json(response.data, { ignoreComment: true, alwaysChildren: true })
        dispatch('analysisBlogFeeds', JSON.parse(json))
      })
  },
  analysisBlogFeeds ({ commit, dispatch, state }, jsonData) {
    if (jsonData.name && jsonData.name === 'channel') {
      if (jsonData.elements) {
        commit('blogFeeds', jsonData)
      }
    } else {
      if (jsonData.elements) {
        for (const elements of jsonData.elements) {
          dispatch('analysisBlogFeeds', elements)
        }
      }
    }
  },
  LoadSignIn ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        await dispatch('LoadUsers')
        await dispatch('LoadUsersAddress')
        await dispatch('LoadUsersFavorites')
        await dispatch('LoadUsersFavoritesOriginals')
        await dispatch('LoadPaymentsCredit')
        resolve(true)
      })().catch((e) => {
        reject(e)
      })
    })
  },
  LoadUsers ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        const contents = await APILib.getUsers()
        commit('Users', contents)
        resolve(contents)
      })().catch((e) => {
        reject(e)
      })
    })
  },
  LoadUsersAddress ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        const contents = await APILib.getUsersAddress()
        commit('UsersAddress', contents)
        resolve(contents)
      })().catch((e) => {
        reject(e)
      })
    })
  },
  LoadUsersFavorites ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        const contents = await APILib.getFavorites()
        commit('UsersFavorites', contents)
        resolve(contents)
      })().catch((e) => {
        reject(e)
      })
    })
  },
  SetUsersFavorites ({ commit, dispatch, state }, contents) {
    commit('UsersFavorites', contents)
  },
  LoadUsersFavoritesOriginals ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        const contents = await APILib.getFavoritesOriginals()
        commit('UsersFavoritesOriginals', contents)
        resolve(contents)
      })().catch((e) => {
        reject(e)
      })
    })
  },
  SetUsersFavoritesOriginals ({ commit, dispatch, state }, contents) {
    commit('UsersFavoritesOriginals', contents)
  },
  LoadShopForm ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        commit('ShopForm', await APILib.getComponentShopForm())
        resolve()
      })().catch((e) => {
        reject(e)
      })
    })
  },
  LoadRouteMethod ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        commit('RouteMethod', await APILib.getComponentRouteMethod())
        resolve()
      })().catch((e) => {
        reject(e)
      })
    })
  },
  LoadPickupBrand ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        commit('PickupBrand', await APILib.getComponentPickupBrand())
        resolve()
      })().catch((e) => {
        reject(e)
      })
    })
  },
  LoadContactMethod ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        commit('ContactMethod', await APILib.getComponentContactMethod())
        resolve()
      })().catch((e) => {
        reject(e)
      })
    })
  },
  LoadEntryFormItems ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        commit('EntryFormItems', await APILib.getComponentEntryFormItems())
        resolve()
      })().catch((e) => {
        reject(e)
      })
    })
  },
  LoadCampaignList ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        commit('CampaignList', await APILib.getCampaignList())
        resolve()
      })().catch((e) => {
        reject(e)
      })
    })
  },
  LoadCampaignListProducts ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        commit('CampaignListProducts', await APILib.getCampaignListProducts())
        resolve()
      })().catch((e) => {
        reject(e)
      })
    })
  },
  LoadMaintenance ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        commit('MaintenanceMode', await APILib.getComponentMaintenance())
        resolve()
      })().catch((e) => {
        reject(e)
      })
    })
  },
  LoadPaymentsCredit ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        commit('PaymentsCredit', await APILib.getShoppingPaymentsCredit())
        resolve()
      })().catch((e) => {
        reject(e)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
