<template>
<v-row align="center" justify="center">
  <v-col :cols="(isSignIn) ? 8 : 12" @click="$emit('click')">
    <v-row :id="text" align="center" justify="center">
      <v-col cols="12" class="py-1">
        <v-chip x-small color="BrandLabelColor" dark label>R2限定</v-chip>
      </v-col>
      <v-col cols="12" class='text-left py-1 font-weight-bold' :style="{ color: buttonColor }">
        <span class="searchName" v-text="text"></span>
      </v-col>
    </v-row>
  </v-col>
  <v-col cols="4" class="text-right" v-if="isSignIn">
    <ComponentsItemsButton
      x-small
      :outlined="(isFavorites)"
      :color="(isFavorites) ? '' : 'R2MainColorLight'"
      :text="(isFavorites) ? 'リストから除外' : 'リストに追加'"
      @click="(isFavorites) ? removeFavorites() : addFavorites()"
    >
    </ComponentsItemsButton>
  </v-col>
</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ComponentsViewFormsLimitedSimpleProducts',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false
  }),

  computed: {
    UsersFavorites: {
      get () {
        return this.$store.getters['modStorage/UsersFavoritesOriginals']
      }
    },
    isFavorites: {
      get () {
        return this.UsersFavorites.some(item => item.original_products_id === this.originalProductsId)
      }
    },
    SetStatusTypeChoice: {
      get () {
        return process.env.VUE_APP_SET_STATUS_CHOICE
      }
    }
  },

  methods: {
    addFavorites: function () {
      (async () => {
        this.isProcessLoading = true
        const contents = await APILib.postFavoritesOriginals(this.originalProductsId)
        this.$store.dispatch('modStorage/SetUsersFavoritesOriginals', contents)
        this.$confirm({ title: 'Success', message: '購買リストに追加しました', button: { yes: 'OK' } })
        this.isProcessLoading = false
      })().catch((e) => {
        this.isProcessLoading = false
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    },
    removeFavorites: function () {
      (async () => {
        this.isProcessLoading = true
        await APILib.deleteFavoritesOriginals(this.dealingsFavoritesOriginalSetId)
        setTimeout(this.callReload, 1000)
        // const contents = await APILib.deleteFavoritesOriginals(this.dealingsFavoritesOriginalSetId)
        // this.$store.dispatch('modStorage/SetUsersFavoritesOriginals', contents)
        this.$confirm({ title: 'Success', message: '購買リストから除外しました', button: { yes: 'OK' } })
        this.isProcessLoading = false
      })().catch((e) => {
        this.isProcessLoading = false
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    },
    callReload: function () {
      console.log('callReload')
      this.$emit('reload')
    }
  },

  components: {
    ComponentsItemsButton
  },

  props: {
    originalProductsId: {
      type: String,
      require: false,
      default: () => ''
    },
    dealingsFavoritesOriginalSetId: {
      type: String,
      require: false,
      default: () => ''
    },
    label: {
      type: String,
      require: false,
      default: () => ''
    },
    categories: {
      type: Array,
      require: false,
      default: () => []
    },
    text: {
      type: String,
      require: false,
      default: () => ''
    }
  }
}
</script>
