<template>
<v-row align="center" justify="center">

  <v-col cols=12>
    <ComponentsLayoutHeading1 text="注文履歴"></ComponentsLayoutHeading1>
  </v-col>

  <v-col cols=12 class="text-center" v-show="maxPage > 0">
    <v-pagination
      v-model="page"
      :length="maxPage"
      :total-visible="totalVisible"
      :color="buttonColor"
    >
    </v-pagination>
  </v-col>

  <v-col cols=12 v-for="item of orders" :key="item.order_id">
    <ComponentsLayoutSection1>

      <!-- SP -->
      <v-row no-gutters v-if="(windowMiddleSize)">

        <v-col cols=12>
          <v-row align="center" justify="center" no-gutters>
            <v-col cols=4 class="text-left">
              <v-chip x-small label color="grey lighten-1" class="mr-2 white--text">注文日</v-chip>
            </v-col>
            <v-col cols=8 class="text-left">
              <span v-text="getDate(item.orders.order_date, 'YYYY/MM/DD')"></span>
            </v-col>
            <v-col cols=4 class="text-left">
              <v-chip x-small label color="grey lighten-1" class="mr-2 white--text">注文番号</v-chip>
            </v-col>
            <v-col cols=8 class="text-left">
              <span v-text="item.order_id"></span>
            </v-col>
            <v-col cols=4 class="text-left">
              <v-chip x-small label color="grey lighten-1" class="mr-2 white--text">注文状況</v-chip>
            </v-col>
            <v-col cols=8 class="text-left">
              <span v-text="onViewStatus(item.orders.order_status)"></span>
            </v-col>
            <v-col cols=4 class="text-left">
              <v-chip x-small label color="grey lighten-1" class="mr-2 white--text">支払方法</v-chip>
            </v-col>
            <v-col cols=8 class="text-left">
              <span v-text="item.orders.payment_method"></span>
            </v-col>
            <v-col cols=4 class="text-left">
              <v-chip x-small label color="grey lighten-1" class="mr-2 white--text">納品希望</v-chip>
            </v-col>
            <v-col cols=8 class="text-left">
              <span v-text="deliveryName(item.order_shippings)"></span>
            </v-col>
            <v-col cols=4 class="text-left">
              <v-chip x-small label color="grey lighten-1" class="mr-2 white--text">納品先</v-chip>
            </v-col>
            <v-col cols=8 class="text-left text-truncate">
              <span v-text="item.order_shippings.address"></span>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols=12 class="py-3">
          <v-divider></v-divider>
        </v-col>

        <v-col cols=6 class="pa-1" v-show="showCancel(item.orders.order_status)">
          <ComponentsItemsButton
            block
            small
            color="R2MainColorLight"
            text="注文のキャンセル"
            @click="confirmCancel(item.order_id)"
          >
          </ComponentsItemsButton>
        </v-col>
        <v-col cols=6 class="pa-1">
          <ComponentsItemsButton
            block
            small
            text="再注文"
            @click="confirmReset(item.order_id)"
          >
          </ComponentsItemsButton>
        </v-col>
        <v-col cols=6 class="pa-1" v-show="showDelivery(item.orders.order_status, item.order_tracking_Numbers)">
          <ComponentsItemsButton
            block
            small
            icon="mdi-truck"
            text="配送状況"
            @click="tracking(item.order_tracking_Numbers)"
          >
          </ComponentsItemsButton>
        </v-col>

        <v-col cols="12" class="pt-5" v-for="(products, index) in item.order_items.filter(v => isProducts(v))" :key="index">
          <ComponentsLayoutSection2>
            <v-row no-gutters>
              <v-col cols=12>
                <v-row align="center" justify="center" no-gutters>
                  <v-col cols=12 class='caption text-left py-1'>
                    <v-chip x-small label outlined color="grey lighten-1" class="mr-2 white--text"><span v-text="chipName(products)"></span></v-chip><span class="font-weight-bold" :style="{ color: buttonColor }" v-text="products.products_name"></span>
                  </v-col>
                  <v-col cols=12 class="caption text-left py-1" v-show="products.order_item_type_status === '1'">
                    <v-chip x-small label outlined color="grey lighten-1" class="mr-2 white--text">規格</v-chip><span v-text="products.size"></span><span v-text="products.color"></span>
                  </v-col>
                  <v-col cols=12 class="caption text-left py-1" v-show="products.order_item_type_status === '2' || products.set_status === '2' || products.set_status === '3'">
                    <v-row no-gutters>
                      <v-col cols=12>
                        <v-chip x-small label outlined color="grey lighten-1" class="mr-2 white--text">セット内容</v-chip>
                      </v-col>
                      <v-col cols=12 v-for="original in item.order_items_set.filter(v => v.order_item_id === products.order_item_id)" :key="original.products_class_id">
                        <v-row align="center" justify="center" class="pl-3" no-gutters>
                          <v-col cols=9>
                            <v-row no-gutters>
                              <v-col cols=12 class="ec-font-size10">
                                <span v-text="getValue('products_name', original)"></span>
                                <span v-text="' ' + getValue('size', original)" v-if="isUndefined(getValue('size', original)) === false"></span>
                                <span v-text="' ' + getValue('color', original)" v-if="isUndefined(getValue('color', original)) === false"></span>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols=3 class="text-center ec-font-size10">
                            &nbsp;&times;&nbsp;<span v-text="getValue('quantity', original)"></span>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols=12>
                <v-row align="center" justify="center" no-gutters>
                  <v-col cols=6 class="caption text-left py-1" v-show="isBuyProducts(products)">
                    <v-chip x-small label outlined color="grey lighten-1" class="mr-2 white--text">卸価格 (税込)</v-chip>
                  </v-col>
                  <v-col cols=6 class="caption text-right py-1" v-show="isBuyProducts(products)">
                    &yen;<span v-text="' ' + parseInt(products.price).toLocaleString()"></span>
                  </v-col>
                  <v-col cols=6 class="caption text-left py-1">
                    <v-chip x-small label outlined color="grey lighten-1" class="mr-2 white--text">数量</v-chip>
                  </v-col>
                  <v-col cols=6 class="caption text-right py-1">
                    <span v-text="parseInt(products.quantity)"></span>
                  </v-col>
                  <v-col cols=6 class="caption text-left py-1" v-show="isBuyProducts(products)">
                    <v-chip x-small label outlined color="grey lighten-1" class="mr-2 white--text">小計</v-chip>
                  </v-col>
                  <v-col cols=6 class="caption text-right py-1" v-show="isBuyProducts(products)">
                    &yen;<span v-text="' ' + parseInt(products.price * products.quantity).toLocaleString()"></span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </ComponentsLayoutSection2>
        </v-col>

        <v-col cols="12" class="py-5 px-3">
            <v-row justify="end" no-gutters>
              <v-col cols=12>
                <v-row align="center" justify="center" no-gutters>
                  <v-col cols=6 class="ec-font-size10 text-left py-1">
                    <span>小計(内税)</span>
                  </v-col>
                  <v-col cols=6 class="ec-font-size10 text-right py-1">
                    &yen;&nbsp;<span v-text="priceSubTotal(item.order_items).toLocaleString()"></span>
                  </v-col>
                  <v-col cols=6 class="ec-font-size10 text-left py-1">
                    <span>送料(内税)</span>
                  </v-col>
                  <v-col cols=6 class="ec-font-size10 text-right py-1">
                    &yen;&nbsp;<span v-text="priceDeliveryFee(item.order_items).toLocaleString()"></span>
                  </v-col>
                  <v-col cols=6 class="ec-font-size10 text-left py-1">
                    <span>手数料(内税)</span>
                  </v-col>
                  <v-col cols=6 class="ec-font-size10 text-right py-1">
                    &yen;&nbsp;<span v-text="priceCharge(item.order_items).toLocaleString()"></span>
                  </v-col>
                  <v-col cols=6 class="ec-font-size10 text-left py-1">
                    <span>値引き(内税)</span>
                  </v-col>
                  <v-col cols=6 class="ec-font-size10 text-right py-1">
                    &yen;&nbsp;<span v-text="priceDiscount(item.order_items).toLocaleString()"></span>
                  </v-col>
                  <v-col cols=12 class="py-1">
                    <v-divider></v-divider>
                  </v-col>
                  <v-col cols=6 class="ec-font-size12 warning--text text-left py-1">
                    <span>お支払い合計</span>
                  </v-col>
                  <v-col cols=6 class="ec-font-size12 warning--text text-right py-1">
                    &yen;&nbsp;<span v-text="paymentTotal(item.order_items).toLocaleString()"></span>
                  </v-col>
                  <v-col cols=6 class="ec-font-size10 text-left py-1">
                    <span>税額</span>
                  </v-col>
                  <v-col cols=6 class="ec-font-size10 text-right py-1">
                    &yen;&nbsp;<span v-text="priceTaxPaymentTotal(item.order_items).toLocaleString()"></span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
        </v-col>
      </v-row>

      <!-- PC -->
      <v-row align="center" justify="center" no-gutters v-else>
        <v-col cols=12>
          <v-row align="center" justify="center" no-gutters>
            <v-col cols=4 class="text-left">
              <v-chip x-small label color="grey lighten-1" class="mr-2 white--text">注文日</v-chip>
            </v-col>
            <v-col cols=4 class="text-left">
              <v-chip x-small label color="grey lighten-1" class="mr-2 white--text">注文番号</v-chip>
            </v-col>
            <v-col cols=4 class="text-left">
              <v-chip x-small label color="grey lighten-1" class="mr-2 white--text">注文状況</v-chip>
            </v-col>
            <v-col cols=4 class="text-left">
              <span v-text="getDate(item.orders.order_date, 'YYYY/MM/DD')"></span>
            </v-col>
            <v-col cols=4 class="text-left">
              <span v-text="item.order_id"></span>
            </v-col>
            <v-col cols=4 class="text-left">
              <span v-text="onViewStatus(item.orders.order_status)"></span>
            </v-col>
          </v-row>

          <v-row align="center" justify="center" class="pt-3" no-gutters>
            <v-col cols=4 class="text-left">
              <v-chip x-small label color="grey lighten-1" class="mr-2 white--text">支払方法</v-chip>
            </v-col>
            <v-col cols=4 class="text-left">
              <v-chip x-small label color="grey lighten-1" class="mr-2 white--text">納品希望</v-chip>
            </v-col>
            <v-col cols=4 class="text-left">
              <v-chip x-small label color="grey lighten-1" class="mr-2 white--text">納品先</v-chip>
            </v-col>
            <v-col cols=4 class="text-left">
              <span v-text="item.orders.payment_method"></span>
            </v-col>
            <v-col cols=4 class="text-left">
              <span v-text="deliveryName(item.order_shippings)"></span>
            </v-col>
            <v-col cols=4 class="text-left text-truncate">
              <span v-text="item.order_shippings.address"></span>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" class="pt-5">
          <v-row align="start" justify="center" no-gutters>
            <v-col cols=9>
              <v-row no-gutters>
                <v-col cols=12 class="pb-1" v-for="(products, index) in item.order_items.filter(v => isProducts(v))" :key="index">
                  <ComponentsLayoutSection2>
                    <v-row no-gutters>
                      <v-col cols=8>
                        <v-row align="center" justify="center" no-gutters>
                          <v-col cols=12 class='caption text-left py-1'>
                            <v-chip x-small label outlined color="grey lighten-1" class="mr-2 white--text"><span v-text="chipName(products)"></span></v-chip><span class="font-weight-bold" :style="{ color: buttonColor }" v-text="products.products_name"></span>
                          </v-col>
                          <v-col cols=12 class="caption text-left py-1" v-show="products.order_item_type_status === '1'">
                            <v-chip x-small label outlined color="grey lighten-1" class="mr-2 white--text">規格</v-chip><span v-text="products.size"></span><span v-text="products.color"></span>
                          </v-col>
                          <v-col cols=12 class="caption text-left py-1" v-show="products.order_item_type_status === '2' || products.set_status === '2' || products.set_status === '3'">
                            <v-row no-gutters>
                              <v-col cols=12>
                                <v-chip x-small label outlined color="grey lighten-1" class="mr-2 white--text">セット内容</v-chip>
                              </v-col>
                              <v-col cols=12 v-for="original in item.order_items_set.filter(v => v.order_item_id === products.order_item_id)" :key="original.products_class_id">
                                <v-row align="center" justify="center" class="pl-3" no-gutters>
                                  <v-col cols=9>
                                    <v-row no-gutters>
                                      <v-col cols=12 class="ec-font-size10">
                                        <span v-text="getValue('products_name', original)"></span>
                                        <span v-text="' ' + getValue('size', original)" v-if="isUndefined(getValue('size', original)) === false"></span>
                                        <span v-text="' ' + getValue('color', original)" v-if="isUndefined(getValue('color', original)) === false"></span>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                  <v-col cols=3 class="text-center ec-font-size10">
                                    &nbsp;&times;&nbsp;<span v-text="getValue('quantity', original)"></span>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols=4>
                        <v-row align="center" justify="center" no-gutters>
                          <v-col cols=6 class="caption text-left py-1" v-show="isBuyProducts(products)">
                            <v-chip x-small label outlined color="grey lighten-1" class="mr-2 white--text">卸価格 (税込)</v-chip>
                          </v-col>
                          <v-col cols=6 class="caption text-right py-1" v-show="isBuyProducts(products)">
                            &yen;<span v-text="' ' + parseInt(products.price).toLocaleString()"></span>
                          </v-col>
                          <v-col cols=6 class="caption text-left py-1">
                            <v-chip x-small label outlined color="grey lighten-1" class="mr-2 white--text">数量</v-chip>
                          </v-col>
                          <v-col cols=6 class="caption text-right py-1">
                            <span v-text="parseInt(products.quantity)"></span>
                          </v-col>
                          <v-col cols=6 class="caption text-left py-1" v-show="isBuyProducts(products)">
                            <v-chip x-small label outlined color="grey lighten-1" class="mr-2 white--text">小計</v-chip>
                          </v-col>
                          <v-col cols=6 class="caption text-right py-1" v-show="isBuyProducts(products)">
                            &yen;<span v-text="' ' + parseInt(products.price * products.quantity).toLocaleString()"></span>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </ComponentsLayoutSection2>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols=3 class="pl-1">
              <ComponentsLayoutSection2>
                <v-row no-gutters>
                  <v-col cols=12>
                    <v-row align="center" justify="center" no-gutters>
                      <v-col cols=6 class="ec-font-size10 text-left py-1">
                        <span>小計(内税)</span>
                      </v-col>
                      <v-col cols=6 class="ec-font-size10 text-right py-1">
                        &yen;&nbsp;<span v-text="priceSubTotal(item.order_items).toLocaleString()"></span>
                      </v-col>
                      <v-col cols=6 class="ec-font-size10 text-left py-1">
                        <span>送料(内税)</span>
                      </v-col>
                      <v-col cols=6 class="ec-font-size10 text-right py-1">
                        &yen;&nbsp;<span v-text="priceDeliveryFee(item.order_items).toLocaleString()"></span>
                      </v-col>
                      <v-col cols=6 class="ec-font-size10 text-left py-1">
                        <span>手数料(内税)</span>
                      </v-col>
                      <v-col cols=6 class="ec-font-size10 text-right py-1">
                        &yen;&nbsp;<span v-text="priceCharge(item.order_items).toLocaleString()"></span>
                      </v-col>
                      <v-col cols=6 class="ec-font-size10 text-left py-1">
                        <span>値引き(内税)</span>
                      </v-col>
                      <v-col cols=6 class="ec-font-size10 text-right py-1">
                        &yen;&nbsp;<span v-text="priceDiscount(item.order_items).toLocaleString()"></span>
                      </v-col>
                      <v-col cols=12 class="py-1">
                        <v-divider></v-divider>
                      </v-col>
                      <v-col cols=6 class="ec-font-size12 warning--text text-left py-1">
                        <span>お支払い合計</span>
                      </v-col>
                      <v-col cols=6 class="ec-font-size12 warning--text text-right py-1">
                        &yen;&nbsp;<span v-text="paymentTotal(item.order_items).toLocaleString()"></span>
                      </v-col>
                      <v-col cols=6 class="ec-font-size10 text-left py-1">
                        <span>税額</span>
                      </v-col>
                      <v-col cols=6 class="ec-font-size10 text-right py-1">
                        &yen;&nbsp;<span v-text="priceTaxPaymentTotal(item.order_items).toLocaleString()"></span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-5">
                  <v-col cols=12 class="py-1">
                    <ComponentsItemsButton
                      block
                      small
                      icon="mdi-repeat"
                      text="再注文"
                      @click="confirmReset(item.order_id)"
                    >
                    </ComponentsItemsButton>
                  </v-col>
                  <v-col cols=12 class="py-1" v-show="showDelivery(item.orders.order_status, item.order_tracking_Numbers)">
                    <ComponentsItemsButton
                      block
                      small
                      icon="mdi-truck"
                      text="配送状況の確認"
                      @click="tracking(item.order_tracking_Numbers)"
                    >
                    </ComponentsItemsButton>
                  </v-col>
                  <v-col cols=12 class="py-1" v-show="showDeliverySlip(item.orders.order_status)">
                    <ComponentsItemsButton
                      block
                      small
                      outlined
                      text="納品書"
                      @click="getInvoice(item.order_id)"
                    >
                    </ComponentsItemsButton>
                  </v-col>
                  <v-col cols=12 class="py-1" v-show="showReceipt(item)">
                    <ComponentsItemsButton
                      block
                      small
                      outlined
                      text="領収書"
                      @click="confirmReceipt(item.order_id)"
                      :disabled="(item.orders.receipt_status !== '1')"
                    >
                    </ComponentsItemsButton>
                  </v-col>
                  <v-col cols=12 class="py-1" v-show="showCancel(item.orders.order_status)">
                    <ComponentsItemsButton
                      block
                      small
                      color="R2MainColorLight"
                      text="注文をキャンセルする"
                      @click="confirmCancel(item.order_id)"
                    >
                    </ComponentsItemsButton>
                  </v-col>
                </v-row>
              </ComponentsLayoutSection2>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

    </ComponentsLayoutSection1>
  </v-col>

  <v-col cols=12 class="text-center" v-show="maxPage > 0">
    <v-pagination
      v-model="page"
      :length="maxPage"
      :total-visible="totalVisible"
      :color="buttonColor"
    >
    </v-pagination>
  </v-col>
</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'
import Axios from 'axios'

import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsLayoutSection2 from '@/components/layout/Section2'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
// import ComponentsLayoutHeading3 from '@/components/layout/Heading3'
// ComponentsPieceOrderHistoryBlock
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'MyPageOrderHistoryPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false,
    OrderID: '',
    orders: [],
    page: 1,
    limit: 5,
    maxPage: 0,
    totalVisible: 5 // Page最大数
  }),

  watch: {
    page () {
      this.goLoading()
    }
  },

  computed: {
    CartSelectableDeliveries: {
      get () {
        return this.$store.getters['modShopping/CartSelectableDeliveries']
      }
    },
    CartSelectableDeliveryTime: {
      get () {
        return this.$store.getters['modShopping/CartSelectableDeliveryTime']
      }
    },
    OrderStatus: {
      get () {
        return this.$store.getters['modStorage/OrderStatus']
      }
    }
  },

  mounted: function () {
    if (!this.isSignIn) {
      this.pageTransition('/login')
    }
    this.goLoading()
  },

  methods: {
    goLoading: function () {
      (async () => {
        this.isProcessLoading = true
        const resultRows = await APILib.postOrderHistorySearch({}, this.page, this.limit)
        this.maxPage = Math.ceil((this.getValue('count', resultRows) || 0) / this.limit)
        this.orders = this.getValue('data', resultRows) || []
        console.log(this.orders)
        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    },

    isProducts: function (item) {
      return (parseInt(item.order_item_type_status) === parseInt(process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_PRODUCTS) ||
        parseInt(item.order_item_type_status) === parseInt(process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_LIMITED) ||
        parseInt(item.order_item_type_status) === parseInt(process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_PAMPHLET) ||
        parseInt(item.order_item_type_status) === parseInt(process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_BUNDLE))
    },

    isBuyProducts: function (item) {
      return (parseInt(item.order_item_type_status) === parseInt(process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_PRODUCTS) ||
        parseInt(item.order_item_type_status) === parseInt(process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_LIMITED))
    },

    chipName: function (item) {
      if (parseInt(item.order_item_type_status) === parseInt(process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_PRODUCTS)) {
        return '商品'
      }
      if (parseInt(item.order_item_type_status) === parseInt(process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_LIMITED)) {
        return 'R2限定'
      }
      if (parseInt(item.order_item_type_status) === parseInt(process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_PAMPHLET)) {
        return '販促物'
      }
      if (parseInt(item.order_item_type_status) === parseInt(process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_BUNDLE)) {
        return 'キャンペーン商品'
      }
      return ''
    },

    deliveryName: function (data) {
      const index = this.CartSelectableDeliveries.findIndex(item => item.code === data.delivery_type)
      if (this.CartSelectableDeliveries[index].code !== '3') {
        return this.CartSelectableDeliveries[index].name
      }
      const date = (this.isUndefined(data.delivery_date)) ? '' : this.getDate(data.delivery_date, 'YYYY/MM/DD')
      const timeIndex = this.CartSelectableDeliveryTime.findIndex(item => item.code === data.delivery_time)
      const time = (timeIndex >= 0) ? this.CartSelectableDeliveryTime[timeIndex].name : ''
      return date + ' ' + time
    },

    // ====================================
    // 各算出処理
    // ====================================
    onViewStatus: function (code) {
      const index = this.OrderStatus.findIndex(item => item.code === code)
      return (index >= 0) ? this.OrderStatus[index].options.view_name : '不明'
    },
    priceSubTotal: function (orderItems) {
      return orderItems
        .filter(item => parseInt(item.order_item_type_status) === parseInt(process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_PRODUCTS) || parseInt(item.order_item_type_status) === parseInt(process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_LIMITED))
        .reduce((pre, cur) => { return pre + (parseInt(cur.price) * parseInt(cur.quantity)) }, 0)
    },
    priceDeliveryFee: function (orderItems) {
      return orderItems
        .filter(item => item.order_item_type_status === process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_DELIVERY_FEE)
        .reduce((pre, cur) => { return pre + (parseInt(cur.price) * parseInt(cur.quantity)) }, 0)
    },
    priceCharge: function (orderItems) {
      return orderItems
        .filter(item => item.order_item_type_status === process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_CHARGE)
        .reduce((pre, cur) => { return pre + (parseInt(cur.price) * parseInt(cur.quantity)) }, 0)
    },
    priceDiscount: function (orderItems) {
      return orderItems
        .filter(item => item.order_item_type_status === process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_DISCOUNT || item.order_item_type_status === process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_CAMPAIGN)
        .reduce((pre, cur) => { return pre + (parseInt(cur.price) * parseInt(cur.quantity)) }, 0)
    },
    taxRate: function (orderItems) {
      return orderItems.reduce((prev, curr) => prev.includes(curr.tax_rate) ? prev : [...prev, curr.tax_rate], [])
    },
    priceTotal: function (orderItems) {
      return orderItems
        .reduce((pre, cur) => { return pre + (parseInt(cur.price) * parseInt(cur.quantity)) }, 0)
    },
    paymentTotal: function (orderItems) {
      return orderItems
        .reduce((pre, cur) => { return pre + (parseInt(cur.price) * parseInt(cur.quantity)) }, 0)
    },
    targetTaxPrice: function (orderItems, tax) {
      return orderItems
        .filter(item => item.tax_rate === tax)
        .reduce((pre, cur) => { return pre + (parseInt(cur.price) * parseInt(cur.quantity)) }, 0)
    },
    priceTaxTotal: function (orderItems, tax) {
      return orderItems
        .filter(item => item.tax_rate === tax)
        .reduce((pre, cur) => { return pre + (Math.ceil((parseFloat(cur.price) / (1 + (parseInt(cur.tax_rate) / 100))) * (cur.tax_rate / 100)) * parseInt(cur.quantity)) }, 0)
    },
    priceTaxPaymentTotal: function (orderItems) {
      return orderItems
        .reduce((pre, cur) => { return pre + (Math.ceil((parseFloat(cur.price) / (1 + (parseInt(cur.tax_rate) / 100))) * (cur.tax_rate / 100)) * parseInt(cur.quantity)) }, 0)
    },
    showDelivery: function (OrderStatus, orderTrackingNumbers) {
      return ([process.env.VUE_APP_ORDER_STATUS_SHIPPED].includes(OrderStatus) && (!this.isUndefined(orderTrackingNumbers)))
    },
    showDeliverySlip: function (OrderStatus) {
      return ([
        process.env.VUE_APP_ORDER_STATUS_SHIPPED
      ].includes(OrderStatus))
    },
    showReceipt: function (item) {
      return (
        ([
          process.env.VUE_APP_ORDER_STATUS_SHIPPED
        ].includes(item.orders.order_status)) &&
        // クレジット払い
        ([
          process.env.VUE_APP_PAYMENTS_CREDITS
        ].includes(item.orders.payment_method)) &&
        // 金額表記なし
        ([
          '2'
        ].includes(item.order_shippings.view_price))
      )
    },
    showCancel: function (OrderStatus) {
      return ([
        process.env.VUE_APP_ORDER_STATUS_PRODUCE,
        process.env.VUE_APP_ORDER_STATUS_SHIPPED,
        process.env.VUE_APP_ORDER_STATUS_CANCEL,
        process.env.VUE_APP_ORDER_STATUS_RETURNS
      ].includes(OrderStatus) === false)
    },
    // ====================================
    // 配送状況
    // ====================================
    tracking: function (orderTrackingNumbers) {
      if (!this.isUndefined(orderTrackingNumbers)) {
        const requestNos = {}
        for (let i = 0; i < orderTrackingNumbers.length; i++) {
          requestNos[`requestNo${i + 1}`] = orderTrackingNumbers[i].tracking_number
        }
        const queryString = Object.keys(requestNos).map(key => `${key}=${requestNos[key]}`).join('&') + '&search=追跡スタート'
        window.open('https://trackings.post.japanpost.jp/services/srv/search/?' + queryString, '_blank')
      }
    },
    // ====================================
    // 再注文
    // ====================================
    confirmReset: function (OrderID) {
      this.OrderID = OrderID
      this.$confirm({ title: '確認', message: '注文番号:' + OrderID + 'の購入内容で購買リストの購入数を再設定します。※現在設定中の購入数は初期化されます（現在購入不可の商品は購入できません）', button: { yes: '実行する', no: '閉じる' }, callback: this.commitReset })
    },
    commitReset: function (ask) {
      (async () => {
        if (ask) {
          const index = this.orders.findIndex(item => item.order_id === this.OrderID)
          if (index >= 0) {
            this.isProcessLoading = true
            await APILib.clearShoppingCartProductsSet()
            for (const item of this.orders[index].order_items.filter(item => parseInt(item.order_item_type_status) === parseInt(process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_PRODUCTS))) {
              await this.$store.dispatch('modShopping/SetShoppingCartProducts', [item.products_class_id, item.quantity])
            }
            for (const item of this.orders[index].order_items.filter(item => parseInt(item.order_item_type_status) === parseInt(process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_LIMITED))) {
              await this.$store.dispatch('modShopping/SetShoppingCartLimited', [item.products_class_id, item.quantity])
            }
            this.pageTransition('/shopping')
            this.isProcessLoading = false
          }
        }
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    },
    // ====================================
    // 注文キャンセル
    // ====================================
    confirmCancel: function (OrderID) {
      this.OrderID = OrderID
      this.$confirm({ title: '確認', message: '注文番号:' + OrderID + 'のご注文をキャンセルします。本当に宜しいですか？', button: { yes: '実行する', no: '閉じる' }, callback: this.commitCancel })
    },
    commitCancel: function (ask) {
      (async () => {
        if (ask) {
          const index = this.orders.findIndex(item => item.order_id === this.OrderID)
          if (index >= 0) {
            this.isProcessLoading = true
            const resultsRows = await APILib.deleteOrderHistory(this.OrderID)
            console.log(resultsRows)
            this.orders.splice(index, 1, resultsRows)
            this.orders = this.clone(this.orders)
            this.$confirm({ title: 'SUCCESS', message: 'ご注文をキャンセルしました', button: { yes: 'OK' } })
            this.isProcessLoading = false
          }
        }
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    },
    // ====================================
    // 納品書ダウンロード
    // ====================================
    getInvoice: function (OrderID) {
      (async () => {
        this.isProcessLoading = true
        const httpHeaders = await APILib.getRequestHeader({})
        const Res = await Axios.get(await APILib.__apiEndPoint('/orders/' + OrderID + '/invoice'), httpHeaders)
        const bin = atob(Res.data)
        const buffer = new Uint8Array(bin.length)
        for (let i = 0; i < bin.length; i++) {
          buffer[i] = bin.charCodeAt(i)
        }
        const blob = new Blob([buffer.buffer], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'invoice_' + OrderID + '.pdf'
        link.click()
        this.isProcessLoading = false
      })().catch((e) => {
        this.isProcessLoading = false
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    },

    confirmReceipt: function (OrderID) {
      this.OrderID = OrderID
      this.$confirm({ title: 'Confirm', message: '領収書の発行（ダウンロード）は、１回限りです。本当にダウンロードして宜しいですか？', button: { yes: 'OK', no: '閉じる' }, callback: this.getReceipt })
    },

    getReceipt: function (ask) {
      (async () => {
        if (ask) {
          this.isProcessLoading = true
          const httpHeaders = await APILib.getRequestHeader({})
          const Res = await Axios.get(await APILib.__apiEndPoint('/orders/' + this.OrderID + '/receipt'), httpHeaders)
          const bin = atob(Res.data)
          const buffer = new Uint8Array(bin.length)
          for (let i = 0; i < bin.length; i++) {
            buffer[i] = bin.charCodeAt(i)
          }
          const blob = new Blob([buffer.buffer], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'receipt_' + this.OrderID + '.pdf'
          link.click()
          this.goLoading()
          this.isProcessLoading = false
        }
      })().catch((e) => {
        this.isProcessLoading = false
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    }
  },

  components: {
    ComponentsLayoutSection1,
    ComponentsLayoutSection2,
    ComponentsLayoutHeading1,
    // ComponentsLayoutHeading3,
    ComponentsItemsButton
  }
}
</script>
