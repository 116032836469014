<template>
<ComponentsLayoutPage1>
<v-row align="start" justify="start" class="py-5" no-gutters>
  <v-col cols=12 class="pt-5">
    <span class="primary--text ec-font-size16 font-weight-bold">販売中の限定商品</span>
  </v-col>
  <v-col cols=12 class="py-1">
    <v-divider></v-divider>
  </v-col>
  <v-col cols=12 class="pb-5">
    <v-list dense two-line>
      <template v-for="(item, index) in opens">
        <v-list-item :key="item.original_products_id" @click="pageTransition('/campaign/limited/contents/' + item.original_products_id)">
          <v-list-item-avatar tile size="90">
            <v-img :src="item.original_products.img_src" class="grey lighten-2 mx-auto">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5" ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title :class="(windowSpSize) ? 'ec-font-size14 text-left font-weight-bold primary--text pb-3' : 'ec-font-size16 text-left font-weight-bold primary--text pb-3'" style="white-space: normal;">
              <span v-text="item.original_products.products_name"></span>
            </v-list-item-title>
            <v-list-item-subtitle :class="(windowSpSize) ? 'ec-font-size10 text-left' : 'ec-font-size12 text-left'">
              <v-chip x-small dark class="ma-auto mr-3" disabled label><span>発売期間</span></v-chip><span v-text="getDate(item.original_products.sales_str_date,'YYYY.MM.DD') + ' ～ '" v-if="!isUndefined(item.original_products.sales_str_date)"></span><span v-text="getDate(item.original_products.sales_end_date,'YYYY.MM.DD')" v-if="!isUndefined(item.original_products.sales_end_date)"></span>
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <ComponentsItemsButton
              icon-btn
              icon="mdi-chevron-right"
              :loading="isProcessLoading"
            >
            </ComponentsItemsButton>
          </v-list-item-action>
        </v-list-item>

        <v-divider :key="'d-' + item.original_products_id" v-if="opens.length >= (index + 1)"></v-divider>
      </template>
    </v-list>
  </v-col>

  <v-col cols=12 class="pt-5" v-if="features.length > 0">
    <v-row no-gutters>
      <v-col cols=12 class="pt-5">
        <span class="primary--text ec-font-size16 font-weight-bold">販売予定の限定商品</span>
      </v-col>
      <v-col cols=12 class="py-1">
        <v-divider></v-divider>
      </v-col>
      <v-col cols=12 class="pb-5">
        <v-list dense two-line>
          <template v-for="(item, index) in features">
            <v-list-item :key="item.original_products_id">
              <v-list-item-avatar tile size="90">
                <v-img :src="item.original_products.img_src" class="grey lighten-2 mx-auto">
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="grey lighten-5" ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title :class="(windowSpSize) ? 'ec-font-size14 text-left font-weight-bold primary--text pb-3' : 'ec-font-size16 text-left font-weight-bold primary--text pb-3'" style="white-space: normal;">
                  <span v-text="item.original_products.products_name"></span>
                </v-list-item-title>
                <v-list-item-subtitle :class="(windowSpSize) ? 'ec-font-size10 text-left' : 'ec-font-size12 text-left'">
                  <v-chip x-small dark class="ma-auto mr-3" disabled label><span>発売期間</span></v-chip><span v-text="getDate(item.original_products.sales_str_date,'YYYY.MM.DD') + ' ～ '" v-if="!isUndefined(item.original_products.sales_str_date)"></span><span v-text="getDate(item.original_products.sales_end_date,'YYYY.MM.DD')" v-if="!isUndefined(item.original_products.sales_end_date)"></span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider :key="'d-' + item.original_products_id" v-if="features.length >= (index + 1)"></v-divider>
          </template>
        </v-list>
      </v-col>
    </v-row>
  </v-col>
</v-row>
</ComponentsLayoutPage1>
</template>
<script>
import APILib from '@/libs/APILib'
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutPage1 from '@/components/layout/Page1'

import ComponentsItemsButton from '@/components/items/Button'

// import ComponentsPieceCardBlock from '@/components/piece/CardBlock'

export default {
  name: 'LimitedListPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false,
    opens: [],
    features: []
  }),

  mounted: function () {
    if (!this.isSignIn) {
      this.pageTransition('/login')
    }
    this.goLoading()
  },

  methods: {
    goLoading: function () {
      (async () => {
        this.isProcessLoading = true
        const results = await APILib.getOriginalProductsList()
        this.opens = results.filter(item => this.isOpenCampaign(item))
        this.features = results.filter(item => this.isFutureOpenCampaign(item))
        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    },
    isOpenCampaign: function (item) {
      const nowTime = this.formatDate([], 'YYYY-MM-DD HH:mm:ss')
      const strTime = this.formatDate(item.original_products.sales_str_date, 'YYYY-MM-DD 12:30:00')
      const endTime = this.formatDate(item.original_products.sales_end_date, 'YYYY-MM-DD 17:00:00')
      console.log(item.original_products)
      return (nowTime >= strTime && endTime >= nowTime)
    },
    isFutureOpenCampaign: function (item) {
      const nowTime = this.formatDate([], 'YYYY-MM-DD HH:mm:ss')
      const strTime = this.formatDate(item.original_products.sales_str_date, 'YYYY-MM-DD 12:30:00')
      return (strTime > nowTime)
    }
  },

  components: {
    ComponentsLayoutPage1,
    ComponentsItemsButton
  }
}
</script>
