<template>
<v-card flat tile color="transparent" :width="bannerWidthSize" @click="pageTransition(route)">
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-img :contain="contain" :src="imgSrc" class="grey lighten-2 mx-auto" :width="bannerWidthSize" :height="bannerHeightSize" v-bind="attrs" v-on="on">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="grey lighten-5" ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </template>
    <span v-text="alt"></span>
  </v-tooltip>
  <v-card-text class="text-left caption" v-if="view_title">
    <span v-text="getDate(date, 'YYYY.MM.DD')"></span><br />
    <span class="primary--text" v-text="alt"></span>
  </v-card-text>
</v-card>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

export default {
  name: 'ComponentsItemsBanner',

  mixins: [MixinLib],

  data: () => ({
  }),

  computed: {
    windowWidthSize: {
      get () {
        return this.$store.getters['modPage/windowWidthSize']
      }
    },
    bannerWidthSize: {
      get () {
        if (!this.windowMiddleSize) {
          return parseInt(this.width)
        }
        return (this.windowWidthSize / 1024) * parseInt(this.width)
      }
    },
    bannerHeightSize: {
      get () {
        if (!this.windowMiddleSize) {
          return parseInt(this.height)
        }
        return ((this.windowWidthSize) / 1024) * parseInt(this.height)
      }
    }
  },

  methods: {
  },

  props: {
    imgSrc: {
      type: String,
      require: false,
      default: () => ''
    },
    date: {
      type: String,
      require: false,
      default: () => ''
    },
    alt: {
      type: String,
      require: false,
      default: () => ''
    },
    route: {
      type: String,
      require: false,
      default: () => ''
    },
    width: {
      type: [String, Number],
      require: false,
      default: () => ''
    },
    height: {
      type: [String, Number],
      require: false,
      default: () => ''
    },
    view_title: {
      type: Boolean,
      require: false,
      default: () => false
    },
    contain: {
      type: Boolean,
      require: false,
      default: () => false
    }
  }
}
</script>
