import UtilLib from '@/libs/UtilLib'
import AuthLib from '@/libs/AuthLib'

export default {
  data: () => ({
    logoSrc: require('@/assets/logo.png'),

    customizeColor: 'rgba(109, 76, 65, 1)',
    backGroundColor: 'rgba(var(--b3f,244,247,252),1)',
    // backGroundColor: 'rgba(233, 234, 240, 1)',

    primaryColor: 'white',
    // secondaryColor: 'rgba(239, 235, 233, 1)',
    secondaryColor: 'rgba(245, 245, 245, 1)',

    // secondaryColor: 'rgba(var(--b3f,244,247,252),1)',
    tertiaryColor: 'rgba(var(--b3f,250,250,250),1)',

    buttonColor: '#42A5F5',
    labelColor: '#ddeddd',
    imageColor: '#DE5D50',

    primaryFontColor: 'rgba(95, 142, 158, 1)', // Heading1
    secondaryFontColor: 'rgba(142, 154, 158, 1)',
    // secondaryFontColor: 'rgba(188, 170, 164, 1)',
    // tertiaryFontColor: 'rgba(158, 132, 123, 1)',
    tertiaryFontColor: 'rgba(155, 128, 120, 1)',

    // primaryColor: 'white',
    // secondaryColor: 'rgba(var(--b3f,244,247,252),1)',
    // tertiaryColor: 'rgba(239,235,233,0.5)',
    subTitleClass: 'text-left pt-3 pb-1 font-weight-bold ec-font-size14',
    storageDataRefreshInterval: null,
    storageDataRefreshTime: 3600000, // 60分
    checkSessionInterval: null,
    checkSessionTime: 600000 // 10分
  }),

  /*
  watch: {
    '$route' (routeInstance) {
      this.createTitleDesc(routeInstance)
    }
  },
  */

  computed: {
    windowXsSize: {
      get () {
        return (this.$vuetify.breakpoint.xs)
      }
    },
    windowSpSize: {
      get () {
        return (this.$vuetify.breakpoint.smAndDown)
      }
    },
    windowMiddleSize: {
      get () {
        return (this.$vuetify.breakpoint.mdAndDown)
      }
    },
    isSignIn: {
      get () {
        return this.$store.getters['modSession/isSignIn']
      },
      set (v) {
        this.$store.dispatch('modSession/isSignIn', v)
      }
    },
    isInitialLoading: {
      get () {
        return this.$store.getters['modPage/isInitialLoading']
      },
      set (v) {
        this.$store.dispatch('modPage/isInitialLoading', v)
      }
    },
    isLoading: {
      get () {
        return this.$store.getters['modPage/isLoading']
      },
      set (v) {
        this.$store.dispatch('modPage/isLoading', v)
      }
    },
    routeURI: {
      get () {
        return this.$route.path
      }
    },
    windowWidthSize: {
      get () {
        return this.$store.getters['modPage/windowWidthSize']
      },
      set (v) {
        this.$store.dispatch('modPage/setWindowWidthSize', v)
      }
    },
    windowHeightSize: {
      get () {
        return this.$store.getters['modPage/windowHeightSize']
      },
      set (v) {
        this.$store.dispatch('modPage/setWindowHeightSize', v)
      }
    },
    pageBodyBackgroundColor: {
      get () {
        return {
          // backgroundColor: 'rgba(var(--b3f,244,247,252),1)'
          backgroundColor: this.$store.getters['modPage/pageBackgroundColor']
        }
      }
    },
    CommonContentsHeight: {
      get () {
        return this.windowHeightSize
      }
    },
    StorageCategories: {
      get () {
        return this.$store.getters['modStorage/Categories']
      }
    },
    StorageBrand: {
      get () {
        return this.$store.getters['modStorage/Brand']
      }
    },
    StorageBrandCategories: {
      get () {
        return this.$store.getters['modStorage/BrandCategory']
      }
    },
    StoragePrefecture: {
      get () {
        return this.$store.getters['modStorage/Prefecture']
      }
    }
  },

  methods: {
    ...UtilLib,

    getUseTargetStatusName: function (code) {
      return this.$store.getters['modStorage/UseTargetStatusName'](code)
    },

    getBrandName: function (code) {
      return this.$store.getters['modStorage/BrandName'](code)
    },

    getCategoriesName: function (code) {
      return this.$store.getters['modStorage/CategoriesName'](code)
    },

    getPrefectureName: function (code) {
      return this.$store.getters['modStorage/PrefectureName'](code)
    },

    // Page遷移
    pageTransition: function (url, basePath = null, query = null) {
      if (this.isUndefined(url)) {
        return false
      }
      if (this.routeURI === url && this.isUndefined(query)) {
        return true
      }
      if (url.startsWith('https://')) {
        window.open(url, '_blank')
      } else {
        const path = (url.startsWith('/'))
          ? url
          : basePath + '/' + url
        if (this.isUndefined(query)) {
          this.$router.push({ path: path }, () => {})
        } else {
          this.$router.push({ path: path, query: query }, () => {})
        }
      }
    },

    // AWS Amplify サインイン正常終了時に呼び出し
    SignInSuccess: function () {
      this.SignInLoading()
    },

    // AWS Amplify サインイン失敗時
    SignInError: function (error) {
      console.log('SignInError')
      console.log(error)
      this.$confirm({ title: 'Error', message: 'サインイン失敗', button: { yes: 'OK' } })
    },

    // Windowサイズのwatch
    refreshWindowSize: function () {
      if (this.windowWidthSize !== window.innerWidth) {
        this.windowWidthSize = window.innerWidth
      }
      if (this.windowHeightSize !== window.innerHeight) {
        this.windowHeightSize = window.innerHeight
      }
    },

    // サインイン正常終了後に呼び出し: Call SignInSuccess
    SignInLoading: function (uri = '/') {
      (async () => {
        console.log('SignInLoading')
        if (this.isDebug()) {
          // デバッグ用ログイン処理を記載

        } else {
          //
          // [[メンテナンスチェック処理をここに記載]]
          //

          // ログイン状態の確認
          const status = await AuthLib.isLogin()
          if (!status) {
            return this.SignOut()
          }

          //
          // [[最新アクセス日時更新]]
          //
        }

        // ログインユーザの情報を取得
        try {
          // await this.$store.dispatch('modStorage/LoadUsers')
          await this.$store.dispatch('modStorage/LoadSignIn')
        } catch {
          this.$confirm({ title: 'Error', message: 'メールアドレス、または、パスワードをご確認ください', button: { yes: 'OK' } })
          return this.SignOut()
        }
        await this.$store.dispatch('modShopping/LoadShoppingCart')

        // バックグラウンド処理の実行
        // this.StorageDataRefresh()
        this.storageDataRefreshInterval = setInterval(this.StorageDataRefresh, this.storageDataRefreshTime)
        this.checkSessionInterval = setInterval(this.checkSession, this.checkSessionTime)

        // サインインフラグON
        this.isLoading = false
        this.isSignIn = true
        const suffix = (this.routeURI.endsWith('/')) ? '/' : ''
        if (this.routeURI !== uri + suffix) {
          return this.pageTransition(uri)
        }
      })().catch((e) => {
        this.SignOut()
      })
    },

    // バックエンドデータ更新: Call SignInLoading
    StorageDataRefresh: function () {
      (async () => {
        console.log('StorageDataRefresh')
        // セッションチェック処理
        await this.checkSession()
        // ログイン後の定期ローディング情報を記述
        await this.$store.dispatch('modStorage/LoadSignIn')
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: '一部コンテンツの読み込みに失敗しました。自動でログアウトします。', button: { yes: 'OK' } })
        this.SignOut()
      })
    },

    // セッションチェック: Call StorageDataRefresh
    checkSession: function () {
      (async () => {
        console.log('checkSession')
        const status = await AuthLib.isLogin()
        if (!status) {
          this.SignOut()
        }
      })().catch((e) => {
        this.SignOut()
      })
    },

    // サインアウト
    SignOut: function (uri = '/') {
      (async () => {
        console.log('SignOut')
        clearInterval(this.storageDataRefreshInterval)
        clearInterval(this.checkSessionInterval)
        await AuthLib.SignOut()
        this.isLoading = false
        this.isSignIn = false
        // this.pageTransition(uri)
      })().catch((e) => {
        this.pageTransition(uri)
      })
    }

    // Loadingをfalseにしてページ遷移
    /*
    goTransition: function (uri) {
      this.isLoading = false
      this.pageTransition(uri)
    }
    */
  }
}
