import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  breakpoint: {
    thresholds: {
      xs: 420,
      sm: 600,
      md: 960,
      lg: 1200
    }
  },
  theme: {
    themes: {
      light: {
        ChipLabelColor: '#ddeddd',
        BrandLabelColor: '#1976d2',
        R2NormalButtonColor: '#90A4AE',
        R2ProductNameColor: '#616161',
        R2LabelTextColor: '#449d44',
        R2LabelColor: '#ddeddd',
        R2BlockColor: '#F5F5F5',
        R2FooterColor: '#EEE9E0',
        R2ExtensionFooterColor: '#F8F6F2',
        R2SystemBarColor: '#EEEEEE',
        R2MainColor: '#C5051B',
        R2MainColorLight: '#DE5D50',
        R2InfoColor: '#EFAF14',
        ActiveColor: '#FFF3E0'
      }
    }
  }
})
