<template>
<v-row v-if="loading">
  <v-col cols=12>
    <v-row align="start" justify="start" no-gutters>
      <v-col cols=12 class="pt-5 pb-1">
        <v-icon color="R2MainColorLight" left>mdi-check-circle</v-icon><span class="R2MainColorLight--text ec-font-size16 font-weight-bold">カスタマー Q&amp;A</span>
      </v-col>
      <v-col cols=12 class="py-1">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row align="start" justify="start" class="mx-3" no-gutters>
      <v-col cols=12 class="pa-1" v-if="isSignIn">
        <v-skeleton-loader class="mx-auto" type="text" height="40"></v-skeleton-loader>
      </v-col>
      <v-col cols=12 class="pa-1" v-else>
        <ComponentsViewFormsProductsApply
          warningMessage="表示するにはお取引のご契約が必要です"
        >
        </ComponentsViewFormsProductsApply>
      </v-col>
    </v-row>
  </v-col>
</v-row>
<v-row v-else>
  <v-col cols=12>
    <v-row align="center" justify="center" no-gutters>
      <v-col cols=12 class="pt-5 pb-1">
        <v-icon color="R2MainColorLight" left>mdi-check-circle</v-icon><span class="R2MainColorLight--text ec-font-size16 font-weight-bold">商品に関する Q&amp;A</span>
      </v-col>
      <v-col cols=12 class="py-1">
        <v-divider></v-divider>
      </v-col>
    </v-row>
  </v-col>
  <v-col cols=12 v-if="isSignIn">
    <v-row align="center" justify="center">
      <v-col cols=12 v-for="(item, index) in productsQa" :key="index">
        <ComponentsLayoutSection1>
          <v-row align="center" justify="center">
            <v-col cols=12>
              <ComponentsLayoutHeading1 text="質問" border-hidden></ComponentsLayoutHeading1>
            </v-col>
            <v-col cols=12>
              <span v-text="item.quastion"></span>
            </v-col>
            <v-col cols=12>
              <ComponentsLayoutHeading1 text="回答" border-hidden></ComponentsLayoutHeading1>
            </v-col>
            <v-col cols=12>
              <span v-text="item.answer"></span>
            </v-col>
          </v-row>
        </ComponentsLayoutSection1>
      </v-col>
    </v-row>
  </v-col>
  <v-col cols=12 v-else>
    <ComponentsViewFormsProductsApply
      warningMessage="表示するにはお取引のご契約が必要です"
    >
    </ComponentsViewFormsProductsApply>
  </v-col>
</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsViewFormsProductsApply from '@/components/view-forms/ProductsApply'

export default {
  name: 'ComponentsViewFormsProductsQA',

  mixins: [MixinLib],

  data: () => ({
  }),

  components: {
    ComponentsLayoutSection1,
    ComponentsLayoutHeading1,
    ComponentsViewFormsProductsApply
  },

  props: {
    loading: {
      type: Boolean,
      require: false,
      default: () => false
    },
    productsQa: {
      type: Array,
      require: false,
      default: () => []
    }
  }
}
</script>
