<template>
<ComponentsLayoutPage1>
  <v-row align="start" justify="start" no-gutters :style="{ paddingBottom: '100px' }">

    <!-- 商品タイトル -->
    <v-col cols=12 class="pt-5" v-if="windowSpSize">
      <ComponentsViewFormsLimitedProductsTitle
        :original-products="getValue('original_products', contents)"
        :loading="isLoading || isProcessLoading"
      >
      </ComponentsViewFormsLimitedProductsTitle>
    </v-col>
    <v-col cols=12 class="pb-5" v-if="windowSpSize">
      <!-- 商品基本情報 -->
      <ComponentsViewFormsLimitedProductsDescription
        :original-products="getValue('original_products', contents)"
        :loading="isLoading || isProcessLoading"
      >
      </ComponentsViewFormsLimitedProductsDescription>
    </v-col>

    <!-- 商品画像 -->
    <v-col cols=12 sm=12 md=4 lg=4 xl=4 class="pb-5">
      <ComponentsViewFormsLimitedProductsImage
        :original-products="getValue('original_products', contents)"
        :width="imageWidthSize"
        :height="imageHeightSize"
        :loading="isLoading || isProcessLoading"
        class="mx-auto"
      >
      </ComponentsViewFormsLimitedProductsImage>
    </v-col>

    <v-col cols=8 v-if="!windowSpSize">
      <v-row>
        <v-col cols=12>
          <!-- 商品タイトル -->
          <ComponentsViewFormsLimitedProductsTitle
            :original-products="getValue('original_products', contents)"
            :loading="isLoading || isProcessLoading"
          >
          </ComponentsViewFormsLimitedProductsTitle>
        </v-col>
        <v-col cols=12>
          <!-- 商品基本情報 -->
          <ComponentsViewFormsLimitedProductsDescription
            :original-products="getValue('original_products', contents)"
            :loading="isLoading || isProcessLoading"
          >
          </ComponentsViewFormsLimitedProductsDescription>
        </v-col>
        <v-col cols=12 v-if="getValue('set_status', getValue('original_products', contents) || {}) != SetStatusTypeChoice">
          <!-- 購買リスト追加ボタン -->
          <ComponentsViewFormsLimitedProductsSetOrderButton
            :loading="isLoading || isProcessLoading"
            :original-products-id="(getValue('set_status', getValue('original_products', contents) || {}) == SetStatusTypeChoice) ? '' : getValue('original_products_id', contents)"
            @click-add="addFavorites"
            @click-remove="removeFavorites"
          >
          </ComponentsViewFormsLimitedProductsSetOrderButton>
        </v-col>

        <v-col cols=12 v-else>
          <v-row v-if="!isProcessLoading">
            <v-col cols=12>
              <span class="primary--text font-weight-bold">お好きな組み合わせで商品をセットしてください</span>
            </v-col>
            <!-- セット組み -->
            <v-col cols=12 v-for="(item, index) of collection" :key="index">
              <ComponentsItemsSelectField
                name="original_products_set"
                :items="item.items"
                item-text="name"
                item-value="code"
                :value.sync="item.selected"
                :loading="isLoading || isProcessLoading"
              >
              </ComponentsItemsSelectField>
            </v-col>
            <!-- 購買リスト追加ボタン -->
            <v-col cols=12 class="pt-5">
              <ComponentsViewFormsLimitedProductsSetOrderButton
                :loading="isLoading || isProcessLoading"
                :original-products-id="(getValue('set_status', getValue('original_products', contents) || {}) == SetStatusTypeChoice) ? '' : getValue('original_products_id', contents)"
                :disabled="collection.some(item => isUndefined(item.selected))"
                @click-add="addFavorites"
              >
              </ComponentsViewFormsLimitedProductsSetOrderButton>
            </v-col>
          </v-row>
        </v-col>

      </v-row>
    </v-col>

    <v-col cols=12 class="pt-5" v-if="getValue('set_status', getValue('original_products', contents) || {}) == SetStatusTypeChoice">
      <v-row v-if="windowSpSize">
        <v-col cols=12>
          <span class="primary--text font-weight-bold">お好きな組み合わせで商品をセットしてください</span>
        </v-col>
        <!-- セット組み -->
        <v-col cols=12 v-for="(item, index) of collection" :key="index">
          <ComponentsItemsSelectField
            name="original_products_set"
            :items="item.items"
            item-text="name"
            item-value="code"
            :value.sync="item.selected"
            :loading="isLoading || isProcessLoading"
          >
          </ComponentsItemsSelectField>
        </v-col>
        <!-- 購買リスト追加ボタン -->
        <v-col cols=12 class="py-5">
          <ComponentsViewFormsLimitedProductsSetOrderButton
            :loading="isLoading || isProcessLoading"
            :original-products-id="(getValue('set_status', getValue('original_products', contents) || {}) == SetStatusTypeChoice) ? '' : getValue('original_products_id', contents)"
            :disabled="collection.some(item => isUndefined(item.selected))"
            @click-add="addFavorites"
          >
          </ComponentsViewFormsLimitedProductsSetOrderButton>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols=12>
          <span class="primary--text font-weight-bold">組み合わせ可能な商品一覧</span>
        </v-col>
        <v-col cols=12 v-for="groups in contents.original_products_set_group" :key="groups.set_group_id">
          <v-card outlined>
            <v-row align="center" justify="center">
              <!-- 商品画像 -->
              <v-col cols=12 class="mt-3 pl-5" v-if="(windowSpSize)">
                <span class="ec-font-size12" v-text="'以下のいずれかの商品から' + groups.set_count + '点'"></span>
              </v-col>
              <v-col :cols="(windowSpSize) ? 12 : 8">
                <v-row no-gutters>
                  <v-col cols=12 class="py-2" v-for="item in groups.original_products_set" :key="item.products_class_id">
                    <ComponentsViewFormsLimitedProductsSet
                      :images="item.products_image"
                      :products="item.products"
                      :products-class="item.products_class"
                      :loading="isLoading || isProcessLoading"
                      class="mx-auto"
                    >
                    </ComponentsViewFormsLimitedProductsSet>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols=4 class="fill-height" v-if="!(windowSpSize)">
                <v-row align="center" justify="center"  class="fill-height" no-gutters>
                  <span class="ec-font-size14 font-weight-bold" v-text="'× ' + groups.set_count + '個'"></span>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols=12 class="pt-5" v-else>
      <v-row v-if="!isProcessLoading">
        <v-col cols=12>
          <v-icon color="primary" left>mdi-package-variant-closed</v-icon><span class="primary--text ec-font-size16 font-weight-bold">セット内容</span>
        </v-col>
        <v-col cols=12 v-for="groups in contents.original_products_set_group" :key="groups.set_group_id">
          <v-row no-gutters v-for="item in groups.original_products_set" :key="item.products_class_id">
            <!-- 商品画像 -->
            <v-col cols=12 class="py-2">
              <ComponentsViewFormsLimitedProductsSet
                :images="item.products_image"
                :products="item.products"
                :products-class="item.products_class"
                :set-count="groups.set_count"
                :loading="isLoading || isProcessLoading"
                class="mx-auto"
              >
              </ComponentsViewFormsLimitedProductsSet>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols=12 v-if="windowSpSize">
          <!-- 購買リスト追加ボタン -->
          <ComponentsViewFormsLimitedProductsSetOrderButton
            :loading="isLoading || isProcessLoading"
            :original-products-id="(getValue('set_status', getValue('original_products', contents)) == SetStatusTypeChoice) ? '' : getValue('original_products_id', contents)"
            @click-add="addFavorites"
            @click-remove="removeFavorites"
          >
          </ComponentsViewFormsLimitedProductsSetOrderButton>
        </v-col>
      </v-row>
    </v-col>

  </v-row>
</ComponentsLayoutPage1>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutPage1 from '@/components/layout/Page1'

import ComponentsItemsSelectField from '@/components/items/SelectField'

import ComponentsViewFormsLimitedProductsTitle from '@/components/view-forms/LimitedProductsTitle'
import ComponentsViewFormsLimitedProductsDescription from '@/components/view-forms/LimitedProductsDescription'
import ComponentsViewFormsLimitedProductsImage from '@/components/view-forms/LimitedProductsImage'
import ComponentsViewFormsLimitedProductsSet from '@/components/view-forms/LimitedProductsSet'
import ComponentsViewFormsLimitedProductsSetOrderButton from '@/components/view-forms/LimitedProductsSetOrderButton'

export default {
  name: 'LimitedProductsContentsPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true,
    collection: [],
    selectDialog: false,
    collectionSetIndex: -1,
    collectionSet: [
      null,
      null,
      null,
      null,
      null,
      null
    ],
    contents: {}
  }),

  computed: {
    OriginalProductsID: {
      get () {
        return this.$route.params.OriginalProductsID
      }
    },
    UsersFavoritesOriginals: {
      get () {
        return this.$store.getters['modStorage/UsersFavoritesOriginals']
      }
    },
    imageWidthSize: {
      get () {
        const widthSize = (this.windowWidthSize >= 960) ? 960 : this.windowWidthSize
        if (!this.$vuetify.breakpoint.smAndDown) {
          return parseInt((widthSize / 3) - 30)
        }
        return (this.$vuetify.breakpoint.md) ? parseInt((widthSize / 2) - 30) : parseInt(widthSize - 30)
      }
    },
    imageHeightSize: {
      get () {
        return this.imageWidthSize
      }
    },
    SetStatusTypeChoice: {
      get () {
        return process.env.VUE_APP_SET_STATUS_CHOICE
      }
    }
  },

  mounted: function () {
    if (!this.isSignIn) {
      this.pageTransition('/login')
    }
    this.$store.dispatch('modStorage/LoadUsersFavoritesOriginals')
    this.goLoading()
  },

  methods: {

    goLoading: function () {
      (async () => {
        this.isProcessLoading = true
        this.contents = await APILib.getOriginalProducts(this.OriginalProductsID)
        console.log(this.UsersFavoritesOriginals)
        console.log(this.contents)
        console.log(this.contents.original_products.set_status)
        console.log(this.SetStatusTypeChoice)
        if (this.contents.original_products.set_status === this.SetStatusTypeChoice) {
          this.collection = []
          for (const groups of this.contents.original_products_set_group) {
            this.collection = [
              ...this.collection,
              ...[...Array(parseInt(groups.set_count))].map(() => {
                return {
                  selected: '',
                  items: groups.original_products_set.map(item => {
                    return {
                      code: item.products_class_id,
                      name: item.products.products_name + (this.isUndefined(item.products_class.color) ? '' : ' ' + item.products_class.color) + (this.isUndefined(item.products_class.size) ? '' : ' ' + item.products_class.size)
                    }
                  })
                }
              })
            ]
          }
        }
        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    },

    addFavorites: function () {
      (async () => {
        this.isProcessLoading = true
        if (this.contents.original_products.set_status === this.SetStatusTypeChoice) {
          // 選べるセット
          const results = await APILib.postFavoritesOriginals(
            this.OriginalProductsID,
            this.collection.reduce((results, curr) => {
              const index = results.findIndex(item => item.products_class_id === curr.selected)
              if (index >= 0) {
                results[index].quantity = results[index].quantity + 1
              } else {
                results.push({
                  products_class_id: curr.selected,
                  quantity: 1
                })
              }
              return results
            }, [])
          )
          // 保存
          this.$store.dispatch('modStorage/SetUsersFavoritesOriginals', results)
          // 初期化
          this.collection = this.collection.map(item => {
            item.selected = ''
            return item
          })
          this.$confirm({ title: 'Success', message: '購買リストに登録しました', button: { yes: 'OK' } })
        } else {
          // 選べるセット以外
          const results = await APILib.postFavoritesOriginals(this.OriginalProductsID)
          this.$store.dispatch('modStorage/SetUsersFavoritesOriginals', results)
        }
        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    },

    removeFavorites: function () {
      (async () => {
        if (this.contents.original_products.set_status !== this.SetStatusTypeChoice) {
          const index = this.UsersFavoritesOriginals.findIndex(item => item.original_products_id === this.OriginalProductsID)
          if (index >= 0) {
            this.isProcessLoading = true
            const results = await APILib.deleteFavoritesOriginals(this.UsersFavoritesOriginals[index].dealings_favorites_original_set_id)
            this.$store.dispatch('modStorage/SetUsersFavoritesOriginals', results)
            this.isProcessLoading = false
          }
        }
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    },

    openCollection: function (index) {
      this.collectionSetIndex = index
      this.selectDialog = true
    },

    setCollection: function (collection) {
      this.collectionSet.splice(this.collectionSetIndex, 1, collection)
      this.selectDialog = false
    }
  },

  components: {
    ComponentsLayoutPage1,
    ComponentsItemsSelectField,
    ComponentsViewFormsLimitedProductsTitle,
    ComponentsViewFormsLimitedProductsDescription,
    ComponentsViewFormsLimitedProductsImage,
    ComponentsViewFormsLimitedProductsSet,
    ComponentsViewFormsLimitedProductsSetOrderButton
  }
}
</script>
