<template>
<ComponentsLayoutPage1>
  <v-row align="start" justify="start" no-gutters>
    <v-col cols=12 class="py-3" v-for="target in list" :key="target.id">
      <v-icon left>mdi-paw</v-icon><span class="font-weight-bold ec-font-size16" v-text="target.name"></span>

      <v-divider></v-divider>

      <v-row align="start" justify="start" no-gutters>
        <v-col cols=12 sm=12 md=6 lg=4 xl=4 class="py-3 px-5" v-for="(genre, index) in target.categories" :key="index">

          <v-list dense color="transparent">
            <v-list-item >
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold ec-font-size14">
                  <span class="font-weight-bold" v-text="genre.name"></span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-list color="transparent">
              <v-list-item
                v-for="category in genre.categories"
                :key="category.id"
                @click="pageMove('/productsList?category=' + category.id)"
                >
                <v-list-item-content>
                  <v-list-item-title class="caption">
                    <span :style="{ color: buttonColor }" class="font-weight-bold" v-text="category.name"></span>
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon :color="buttonColor">mdi-chevron-right</v-icon>
                </v-list-item-action>
              </v-list-item>
          </v-list>

        </v-col>
      </v-row>

    </v-col>
  </v-row>
</ComponentsLayoutPage1>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutPage1 from '@/components/layout/Page1'

export default {
  name: 'CategoryPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: true
  }),

  computed: {
    list: {
      get () {
        return this.$store.getters['modStorage/viewCategories']
      }
    },
    isSearchValid: {
      get () {
        return this.$store.getters['modSearch/isSearchValid']
      },
      set (v) {
        this.$store.dispatch('modSearch/isSearchValid', v)
      }
    },
    isSaveValid: {
      get () {
        return this.$store.getters['modSearch/isSaveValid']
      },
      set (v) {
        this.$store.dispatch('modSearch/isSaveValid', v)
      }
    },
    Category: {
      get () {
        return this.$store.getters['modSearch/Category']
      },
      set (v) {
        this.$store.dispatch('modSearch/Category', v)
      }
    },
    Brand: {
      get () {
        return this.$store.getters['modSearch/Brand']
      },
      set (v) {
        this.$store.dispatch('modSearch/Brand', v)
      }
    },
    Keyword: {
      get () {
        return this.$store.getters['modSearch/Keyword']
      },
      set (v) {
        this.$store.dispatch('modSearch/Keyword', v)
      }
    }
  },

  mounted: function () {
  },

  methods: {
    pageMove (v) {
      this.isSearchValid = 'true'
      this.isSaveValid = ''
      this.Category = ''
      this.Brand = ''
      this.Keyword = ''
      this.isSearchValid = ''
      this.pageTransition(v)
    }
  },

  components: {
    ComponentsLayoutPage1
  }
}
</script>
