<template>
<v-row class="pb-5" v-if="loading">
  <v-col cols="12">
    <v-row align="start" justify="start" no-gutters>
      <v-col cols=12 class="pt-5 pb-1">
        <v-icon color="primary" left>mdi-package-variant-closed</v-icon><span class="primary--text ec-font-size16 font-weight-bold">取り扱い規格</span>
      </v-col>
    </v-row>

    <v-row align="start" justify="start" no-gutters>
      <v-col cols=12 sm=6 md=4 lg=3 xl=3 class="pa-1" v-for="productsClass in collection" :key="productsClass.product_id">
        <v-card outlined class="pa-2">

          <v-row align="center" justify="end" no-gutters>
            <v-col cols=12 class="ec-font-size12 primary--text py-3">
              <v-skeleton-loader class="mx-auto" type="text" ></v-skeleton-loader>
            </v-col>
            <v-col cols=12 class="py-2">
              <v-divider></v-divider>
            </v-col>
            <v-col cols=4 class="py-1 ec-font-size10 text-left">
              <span>JANコード</span>
            </v-col>
            <v-col cols=8 class="py-1 ec-font-size10 text-right">
              <v-skeleton-loader class="mx-auto" type="text" ></v-skeleton-loader>
            </v-col>
            <v-col cols=4 class="py-1 ec-font-size10 text-left">
              <span>賞味期限</span>
            </v-col>
            <v-col cols=8 class="py-1 ec-font-size10 text-right">
              <v-skeleton-loader class="mx-auto" type="text" ></v-skeleton-loader>
            </v-col>
            <v-col cols=4 class="py-1 ec-font-size10 text-left">
              <span>入荷予定</span>
            </v-col>
            <v-col cols=8 class="py-1 ec-font-size10 text-right">
              <v-skeleton-loader class="mx-auto" type="text" ></v-skeleton-loader>
            </v-col>
            <v-col cols=12 class="py-2">
              <v-divider></v-divider>
            </v-col>
            <v-col cols=6 sm=6 md=6 lg=6 xl=6 class="py-1 ec-font-size10 text-left">
              <span>小売価格<br />(税込)</span>
            </v-col>
            <v-col cols=6 sm=6 md=6 lg=6 xl=6 class="py-1 ec-font-size10 text-right primary--text">
              <v-skeleton-loader class="mx-auto" type="text" ></v-skeleton-loader>
            </v-col>
            <v-col cols=6 sm=6 md=6 lg=6 xl=6 class="py-1 ec-font-size10 text-left">
              <span>卸価格<br />(税込)</span>
            </v-col>
            <v-col cols=6 sm=6 md=6 lg=6 xl=6 class="py-1 ec-font-size10 text-right primary--text">
              <v-skeleton-loader class="mx-auto" type="text" ></v-skeleton-loader>
            </v-col>
          </v-row>

        </v-card>
      </v-col>
    </v-row>
  </v-col>
</v-row>
<v-row class="pb-5" v-else>
  <v-col cols="12">
    <v-row align="start" justify="start" no-gutters>
      <v-col cols=12 class="pt-5 pb-1">
        <v-icon color="primary" left>mdi-package-variant-closed</v-icon><span class="primary--text ec-font-size16 font-weight-bold">取り扱い規格</span>
      </v-col>
    </v-row>

    <v-row align="start" justify="start" no-gutters>
      <template v-for="item in collection">
        <template v-if="item.products_class_ws_price.price >= 0">
          <v-col cols=12 sm=6 md=4 lg=3 xl=3 class="pa-1" :key="item.products_class_id">
            <v-card outlined class="pa-2">
              <v-row align="center" justify="end" no-gutters>
                <v-col cols=12 class="ec-font-size12 primary--text py-3">
                  <span v-text="item.products_class.size" v-if="(item.products_class.size)"></span><span v-text="item.products_class.color" v-if="(item.products_class.color)"></span>
                </v-col>
                <v-col cols=12 class="py-2">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols=4 class="py-1 ec-font-size10 text-left">
                  <span>JANコード</span>
                </v-col>
                <v-col cols=8 class="py-1 ec-font-size10 text-right">
                  <span v-text="item.products_class.product_code"></span>
                </v-col>
                <v-col cols=4 class="py-1 ec-font-size10 text-left">
                  <span>賞味期限</span>
                </v-col>
                <v-col cols=8 class="py-1 ec-font-size10 text-right">
                  <span v-text="formatDate(item.products_class.expiration_date, 'YYYY年MM月DD日')"></span>
                </v-col>
                <v-col cols=4 class="py-1 ec-font-size10 text-left">
                  <span>入荷予定</span>
                </v-col>
                <v-col cols=8 class="py-1 ec-font-size10 text-right">
                  <span v-text="formatDate(item.products_class.back_in_stock_date, 'YYYY年MM月DD日')"></span>
                </v-col>
                <v-col cols=12 class="py-2">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols=6 sm=6 md=6 lg=6 xl=6 class="py-1 ec-font-size10 text-left">
                  <span>小売価格<br />(税込)</span>
                </v-col>
                <v-col cols=6 sm=6 md=6 lg=6 xl=6 class="py-1 ec-font-size10 text-right primary--text">
                  &yen;&nbsp;<span v-text="parseInt(item.products_class_price.price).toLocaleString()"></span>
                </v-col>
                <v-col cols=12 v-if="item.products_class_ws_price.price >= 0">
                  <v-row align="center" justify="center" no-gutters>
                    <v-col cols=6 sm=6 md=6 lg=6 xl=6 class="py-1 ec-font-size10 text-left">
                      <span>卸価格<br />(税込)</span>
                    </v-col>
                    <v-col cols=6 sm=6 md=6 lg=6 xl=6 class="py-1 ec-font-size10 text-right primary--text">
                      &yen;&nbsp;<span v-text="parseInt(item.products_class_ws_price.price).toLocaleString()"></span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols=12 class="pt-1 text-center" v-else>
                  <v-alert class="ec-font-size10" dense dark color="info"><span>取引対象外商品</span></v-alert>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </template>
      </template>
    </v-row>
  </v-col>

</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

export default {
  name: 'ComponentsViewFormsProductsCollection',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false,
    showDate: false,
    collection: []
  }),

  watch: {
    productsId () {
      this.goLoading()
    }
  },

  mounted () {
    this.goLoading()
  },

  methods: {
    // 初期処理
    goLoading: function () {
      (async () => {
        this.isProcessLoading = true
        this.collection = []
        if (this.isUndefined(this.productsId) === false) {
          this.collection = (this.isSignIn)
            ? await APILib.getUsersProductsClassList(this.productsId)
            : await APILib.getProductsClassList(this.productsId)
        }
        this.isProcessLoading = false
      })().catch((e) => {
        this.isProcessLoading = true
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    }
  },

  props: {
    productsId: {
      type: String,
      require: true,
      default: () => ''
    },
    loading: {
      type: Boolean,
      require: false,
      default: () => false
    }
  }
}
</script>
