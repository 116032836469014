import Moment from 'moment'
Moment.locale('ja', {
  weekdays: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
  weekdaysShort: ['日', '月', '火', '水', '木', '金', '土']
})

const UtilLib = {

  clone: function (obj) {
    return JSON.parse(JSON.stringify(obj))
  },

  isDebug: function () {
    return (parseInt(process.env.VUE_APP_DEBUG_MODE) !== 0)
  },

  // =======================================================================
  //
  // オブジェクト操作系
  //
  //
  exists: function (obj, key) {
    return (UtilLib.isObject(obj)) ? (Object.keys(obj).some(v => v === key)) : false
  },

  isObject: function (o) {
    return (o instanceof Object && !(o instanceof Array))
  },

  isNumber: function (obj) {
    return ((typeof obj) === 'number')
  },

  isArray: function (obj) {
    return (obj instanceof Array)
  },

  isEmpty: function (obj) {
    return UtilLib.isUndefined(obj) || (obj == null) || (obj === '')
  },

  isUndefined: function (obj) {
    return (!obj) ? (obj !== 0 && obj !== false) : ((typeof obj === 'object') ? Object.keys(obj).length === 0 : false)
  },

  isPartialMatch: function (str, match) {
    var regex = RegExp(match)
    return regex.test(str)
  },

  getValue: function (s, obj) {
    if (s in obj) {
      return obj[s]
    }
    return null
  },

  // =======================================================================
  //
  // 日付操作系
  //
  //
  getStartWeekDate: function (dateTime, format, startWeek) {
    if (startWeek === 'sun') {
      return UtilLib.addDateTime(Moment(dateTime).startOf('isoWeek'), -1, 'days').format(format)
    }
    if (startWeek === 'mon') {
      return UtilLib.addDateTime(Moment(dateTime).startOf('isoWeek'), 0, 'days').format(format)
    }
    if (startWeek === 'sat') {
      return UtilLib.addDateTime(Moment(dateTime).startOf('isoWeek'), -2, 'days').format(format)
    }
    return UtilLib.addDateTime(Moment(dateTime).startOf('isoWeek'), -1, 'days').format(format)
  },

  formatDate: function (dateTime, format) {
    return (dateTime) ? Moment(dateTime).format(format) : ''
  },

  getDate: function (dateTime, format) {
    return (dateTime) ? Moment(dateTime).format(format) : Moment().format(format)
  },

  addDateTime (dateTime, add = 1, addType = 'days') {
    return Moment(dateTime).add(add, addType)
  },

  diffDateTime: function (s, e, f = 'HH:mm:ss', rf = 'hours') {
    return Moment(e, f).diff(Moment(s, f), rf)
  },

  isPastDate: function (dateTime, format = 'YYYY-MM-DD') {
    // 日付が過去の日付かどうかを判定
    console.log(Moment(dateTime).format(format))
    console.log(Moment().format(format))
    return Moment(dateTime).format(format) < Moment().format(format)
  },

  // =======================================================================
  //
  // 共通データ
  //
  //
  commonShippingStatus: function () {
    return [
      {
        code: '1',
        name: '自社負担'
      },
      {
        code: '2',
        name: '相手負担'
      }
    ]
  },

  commonTraderTaxStatus: function () {
    return [
      {
        code: '1',
        name: '課税業者'
      },
      {
        code: '2',
        name: '免税業者'
      },
      {
        code: '3',
        name: '輸入業者'
      }
    ]
  },
  commonTaxStatus: function () {
    return [
      {
        code: '1',
        name: '外'
      },
      {
        code: '2',
        name: '内'
      }
    ]
  },
  commonTaxCalcStatus: function () {
    return [
      {
        code: '1',
        name: '伝票明細'
      },
      {
        code: '2',
        name: '伝票合計'
      },
      {
        code: '3',
        name: '請求合計'
      }
    ]
  },
  commonTaxRoundingStatus: function () {
    return [
      {
        code: '1',
        name: '切捨て'
      },
      {
        code: '2',
        name: '四捨五入'
      },
      {
        code: '3',
        name: '切上げ'
      }
    ]
  },
  commonStatus: function () {
    return [
      {
        code: '1',
        name: '有効'
      },
      {
        code: '2',
        name: '無効'
      }
    ]
  },
  commonSalesPermissionStatus: function () {
    return [
      {
        code: '1',
        name: '不要'
      },
      {
        code: '2',
        name: '必要'
      }
    ]
  },
  commonNormalFreeze: function () {
    return [
      {
        code: '1',
        name: '常温'
      },
      {
        code: '2',
        name: '冷凍'
      }
    ]
  },
  commonTargetStatus: function () {
    return [
      {
        code: '1',
        name: '犬用品'
      },
      {
        code: '2',
        name: '猫用品'
      },
      {
        code: '9',
        name: '犬猫兼用'
      }
    ]
  },
  commonPamphletViewStatus: function () {
    return [
      {
        code: '1',
        name: '主表示'
      },
      {
        code: '2',
        name: '副表示'
      }
    ]
  },
  commonCreditPaymentMethods: function () {
    return [
      {
        code: '1',
        name: '一括払い'
      },
      {
        code: '2',
        name: '分割払い'
      },
      {
        code: '5',
        name: 'リボ払い'
      }
    ]
  },
  commonCreditPaymentTimes: function () {
    return [
      {
        code: '2',
        name: '2回払い'
      },
      {
        code: '3',
        name: '3回払い'
      },
      {
        code: '5',
        name: '5回払い'
      },
      {
        code: '6',
        name: '6回払い'
      },
      {
        code: '10',
        name: '10回払い'
      },
      {
        code: '12',
        name: '12回払い'
      }
    ]
  }
}

export default UtilLib
