<template>
<v-row align="center" justify="center" class="pt-3">
  <v-col :cols="(required || icon) ? 9 : 12" :class="(required || icon) ? 'pt-1 pb-0 ec-font-size12 font-weight-bold text-left' : 'pb-2 ec-font-size12 font-weight-bold text-left'">
    <v-row class="pl-3">
      <v-icon :color="buttonColor" class="pr-2" size="18" v-if="accordion" @click="$emit('click-accordion')">{{ (accordionStatus) ? 'mdi-minus-box' : 'mdi-plus-box' }}</v-icon>
      <span v-text="text"></span>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon size="18" right :color="buttonColor" v-if="!isUndefined(help)" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
        </template>
        <span v-text="help"></span>
      </v-tooltip>
    </v-row>
  </v-col>
  <v-col cols=3 class="pt-0 pb-0 text-right" v-if="(required)">
    <ComponentsItemsRequireLabel class="align-center"></ComponentsItemsRequireLabel>
  </v-col>
  <v-col cols=3 class="pt-0 pb-0 text-right" v-if="(icon)">
    <ComponentsItemsButton
      x-small
      icon-btn
      :icon="icon"
      @click="$emit('click')"
    >
    </ComponentsItemsButton>
  </v-col>
</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import ComponentsItemsRequireLabel from '@/components/items/RequireLabel'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ComponentsLayoutHeading3',

  mixins: [MixinLib],

  components: {
    ComponentsItemsButton,
    ComponentsItemsRequireLabel
  },

  props: {
    text: {
      type: String,
      required: false,
      default: () => ''
    },
    help: {
      type: String,
      required: false,
      default: () => ''
    },
    accordion: {
      type: Boolean,
      required: false,
      default: () => false
    },
    accordionStatus: {
      type: Boolean,
      required: false,
      default: () => false
    },
    icon: {
      type: String,
      required: false,
      default: () => ''
    },
    required: {
      type: Boolean,
      required: false,
      default: () => false
    }
  }
}
</script>
