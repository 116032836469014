<template>
<v-card flat color="transparent" class="mx-1" :width="parseInt(widthSize) + 10">

  <ComponentsViewFormsSquareProductsImage
    :img-src="imgSrc"
    :label="'R2限定'"
    :width-size="widthSize"
    @click="$emit('click')"
  >
  </ComponentsViewFormsSquareProductsImage>

  <v-card-text class="text-left pa-1">
    <v-row :id="text" align="center" justify="center" :style="{ cursor: 'pointer' }" @click="$emit('click')">
      <v-col cols="12" class="ec-row-h40">
        <v-chip x-small color="ChipLabelColor" class="mr-1" label v-for="(item, index) in categories" :key="index" v-text="item"></v-chip>
      </v-col>
      <v-col cols="12" class='ec-row-h80 text-left py-2 font-weight-bold' :style="{ color: buttonColor }">
        <span class="searchName" v-text="text"></span>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" v-if="isSignIn">
      <v-col cols="12">
        <ComponentsViewFormsLimitedProductsSetOrderButton
          :small-size="smallSize"
          :loading="isProcessLoading"
          :original-products-id="originalProductsId"
          @click-add="addFavorites()"
          @click-remove="removeFavorites()"
        >
        </ComponentsViewFormsLimitedProductsSetOrderButton>
      </v-col>
    </v-row>
  </v-card-text>
</v-card>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsViewFormsSquareProductsImage from '@/components/view-forms/SquareProductsImage'
import ComponentsViewFormsLimitedProductsSetOrderButton from '@/components/view-forms/LimitedProductsSetOrderButton'

export default {
  name: 'ComponentsViewFormsLimitedSquareProducts',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false
  }),

  computed: {
    windowWidthSize: {
      get () {
        return this.$store.getters['modPage/windowWidthSize']
      }
    },
    imageWidthSize: {
      get () {
        const widthSize = (this.windowWidthSize >= 1024) ? 1024 : this.windowWidthSize
        if (!this.$vuetify.breakpoint.smAndDown) {
          return 160
        }
        return parseInt(160 * (widthSize / 600))
      }
    },
    imageHeightSize: {
      get () {
        return this.imageWidthSize
      }
    },
    SetStatusTypeChoice: {
      get () {
        return process.env.VUE_APP_SET_STATUS_CHOICE
      }
    }
  },

  methods: {
    addFavorites: function () {
      (async () => {
        this.isProcessLoading = true
        const contents = await APILib.postFavoritesOriginals(this.originalProductsId)
        this.$store.dispatch('modStorage/SetUsersFavoritesOriginals', contents)
        this.$confirm({ title: 'Success', message: '購買リストに追加しました', button: { yes: 'OK' } })
        this.isProcessLoading = false
      })().catch((e) => {
        this.isProcessLoading = false
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    },
    removeFavorites: function () {
      (async () => {
        this.isProcessLoading = true
        await APILib.deleteFavoritesOriginals(this.dealingsFavoritesOriginalSetId)
        setTimeout(this.callReload, 1000)
        // const contents = await APILib.deleteFavoritesOriginals(this.dealingsFavoritesOriginalSetId)
        // this.$store.dispatch('modStorage/SetUsersFavoritesOriginals', contents)
        this.$confirm({ title: 'Success', message: '購買リストから除外しました', button: { yes: 'OK' } })
        this.isProcessLoading = false
      })().catch((e) => {
        this.isProcessLoading = false
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
      })
    },
    callReload: function () {
      console.log('callReload')
      this.$emit('reload')
    }
  },

  components: {
    ComponentsViewFormsSquareProductsImage,
    ComponentsViewFormsLimitedProductsSetOrderButton
  },

  props: {
    imgSrc: {
      type: String,
      require: false,
      default: () => ''
    },
    originalProductsId: {
      type: String,
      require: false,
      default: () => ''
    },
    dealingsFavoritesOriginalSetId: {
      type: String,
      require: false,
      default: () => ''
    },
    label: {
      type: String,
      require: false,
      default: () => ''
    },
    categories: {
      type: Array,
      require: false,
      default: () => []
    },
    text: {
      type: String,
      require: false,
      default: () => ''
    },
    widthSize: {
      type: [String, Number],
      require: false,
      default: () => '140'
    },
    smallSize: {
      type: Boolean,
      require: false,
      default: () => false
    }
  }
}
</script>
