<template>
<ComponentsLayoutSection1 v-show="isSignIn">
  <v-row align="start" justify="start" no-gutters>
    <v-col cols="12" class="text-left ec-secHeading pt-3">
      <span class="ec-secHeading__en">INFO</span>
      <span class="ec-secHeading__line"></span>
      <span class="ec-secHeading__ja">お知らせ</span>
    </v-col>

    <v-col cols=6 md=3 lg=3 xl=3 v-for="feeds in feeds_information" :key="feeds.link">
      <ComponentsItemsBanner
        contain
        :imgSrc="feeds.thumb"
        :alt="feeds.title"
        :date="feeds.pubDate"
        :route="feeds.link"
        :view_title="true" :width="(windowSpSize) ? 400 : 200"
        :height="(windowSpSize) ? 144 : 72"
      >
      </ComponentsItemsBanner>
    </v-col>
    <!---
    <v-col cols=6 md=3 lg=3 xl=3 v-for="feeds in feeds_products" :key="feeds.link">
      <ComponentsItemsBanner
        contain
        :imgSrc="feeds.thumb"
        :alt="feeds.title"
        :date="feeds.pubDate"
        :route="feeds.link"
        :view_title="true" :width="(windowSpSize) ? 400 : 200"
        :height="(windowSpSize) ? 144 : 72"
      >
      </ComponentsItemsBanner>
    </v-col>

    <v-col cols="12" class="py-3">
      <v-card outlined color="#EEE9E0">
        <v-list three-line dense nav>
          <template v-for="(feeds, index) in feeds_information">
            <v-divider v-if="index > 0" :key="'d-' + index"></v-divider>

            <v-list-item :key="feeds.link" @click="pageTransition(feeds.link)">
              <v-list-item-content>
                <v-list-item-title v-text="getDate(feeds.pubDate, 'YYYY.MM.DD')"></v-list-item-title>
                <v-list-item-subtitle class="primary--text" v-text="feeds.title"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-card>
    </v-col>
    --->
  </v-row>
</ComponentsLayoutSection1>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsItemsBanner from '@/components/items/Banner'

export default {
  name: 'ComponentsViewFormsInformation',

  mixins: [MixinLib],

  data: () => ({
  }),

  computed: {
    blogFeeds: {
      get () {
        return this.$store.getters['modStorage/blogFeeds']
      }
    },
    /*
    feeds_products: {
      get () {
        return this.blogFeeds.filter(v => v.category.some(cate => cate === '商品情報')).slice(0, 4)
      }
    },
    */
    feeds_information: {
      get () {
        return this.blogFeeds.filter(v => v.category.some(cate => cate === 'お知らせ')).slice(0, 4)
      }
    }
  },

  methods: {
  },

  components: {
    ComponentsLayoutSection1,
    ComponentsItemsBanner
  }
}
</script>
