<template>
<v-card rounded flat class="rounded-lg" :color="(isUndefined(color)) ? '' : color" @click="$emit('click')">
  <v-list-item :two-line="(twoLine)">
    <v-list-item-avatar v-if="avatar">
      <v-img :src="avatarImgSrc" contain>
        <v-icon :color="buttonColor" large>mdi-account-circle</v-icon>
      </v-img>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        <span  class="ec-font-size14 font-weight-bold" :style="{ color: buttonColor }" v-text="text"></span>
      </v-list-item-title>
      <v-list-item-subtitle>
        <span class="ec-font-size12" :style="{ color: buttonColor }" v-text="text2" v-if="(twoLine)"></span>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-icon :color="buttonColor" v-text="icon"></v-icon>
    </v-list-item-action>
  </v-list-item>
</v-card>
</template>
<script>
import MixinLib from '@/libs/MixinLib'

export default {
  name: 'ComponentsItemsSimpleSoleList',

  mixins: [MixinLib],

  props: {
    loading: {
      type: [Boolean, String],
      required: false,
      default: () => false
    },
    color: {
      type: String,
      required: false,
      default: () => ''
    },
    avatar: {
      type: Boolean,
      required: false,
      default: () => false
    },
    avatarImgSrc: {
      type: String,
      required: false,
      default: () => ''
    },
    twoLine: {
      type: Boolean,
      required: false,
      default: () => false
    },
    icon: {
      type: String,
      required: false,
      default: () => 'mdi-chevron-right'
    },
    text: {
      type: String,
      required: false,
      default: () => ''
    },
    text2: {
      type: String,
      required: false,
      default: () => ''
    }
  }
}
</script>
