import UtilLib from '@/libs/UtilLib'
// import Cookies from 'js-cookie'
import APILib from '@/libs/APILib'

const state = {
  Cart: {},
  CartItems: [],
  CartPamphlet: [],
  Calculation: {},
  CartSelectablePamphlet: [],
  CartSelectableDeliveryDate: [],
  CartSelectableDeliveryTime: [],
  CartSelectablePayments: [],
  CartSelectableDeliveries: [],
  CommitOrderID: ''
  // ShoppingAddress: {},
  // ShoppingDeliveryType: '1',
  // ShoppingDeliveryDate: '',
  // ShoppingDeliveryTime: '',
  // ShoppingFavorites: []
  // ShoppingPamphlet: []
}

const getters = {
  Cart: (state) => {
    return state.Cart || {}
  },
  CartItems: (state) => {
    return state.CartItems || []
  },
  CartPamphlet: (state) => {
    return state.CartPamphlet.map(item => item.pamphlet_id) || []
  },
  CartCalculation: (state) => {
    return state.Calculation || {}
  },
  CartSelectablePamphlet: (state) => {
    return state.CartSelectablePamphlet || []
  },
  CartSelectableDeliveryDate: (state) => {
    return state.CartSelectableDeliveryDate || []
  },
  CartSelectableDeliveryTime: (state) => {
    return state.CartSelectableDeliveryTime || []
  },
  CartSelectablePayments: (state) => {
    return state.CartSelectablePayments || []
  },
  CartSelectableDeliveries: (state) => {
    return state.CartSelectableDeliveries || []
  },
  CommitOrderID: (state) => {
    return state.CommitOrderID || ''
  }
  /*
  ShoppingAddress: (state) => {
    return state.ShoppingAddress
  },
  ShoppingDeliveryType: (state) => {
    return state.ShoppingDeliveryType
  },
  ShoppingDeliveryDate: (state) => {
    return state.ShoppingDeliveryDate
  },
  ShoppingDeliveryTime: (state) => {
    return state.ShoppingDeliveryTime
  },
  ShoppingFavorites: (state) => {
    return state.ShoppingFavorites
  }
  */
  /*
  ShoppingCart: (state) => {
    return state.ShoppingFavorites
  },
  ShoppingPamphlet: (state) => {
    return state.ShoppingPamphlet
  }
  */
}

const mutations = {
  ShoppingCart (state, contents) {
    state.Cart = (UtilLib.exists(contents, 'dealings_cart')) ? contents.dealings_cart : {}
    state.CartItems = (UtilLib.exists(contents, 'items')) ? contents.items : []
    state.CartPamphlet = (UtilLib.exists(contents, 'dealings_cart_pamphlet')) ? contents.dealings_cart_pamphlet : []
    state.Calculation = (UtilLib.exists(contents, 'calculation')) ? contents.calculation : {}

    state.CartSelectablePamphlet = (UtilLib.exists(contents, 'pamphlet')) ? contents.pamphlet : []
    state.CartSelectableDeliveryTime = (UtilLib.exists(contents, 'delivery_times')) ? contents.delivery_times : []
    state.CartSelectablePayments = (UtilLib.exists(contents, 'payments')) ? contents.payments : []
    state.CartSelectableDeliveries = (UtilLib.exists(contents, 'deliveries')) ? contents.deliveries : []
  },
  CartSelectableDeliveryDate (state, contents) {
    state.CartSelectableDeliveryDate = contents
  },
  SetComplete (state, contents) {
    state.CommitOrderID = contents
  }
  /*
  ShoppingAddress (state, contents) {
    state.ShoppingAddress = contents
  },
  ShoppingDeliveryType (state, contents) {
    state.ShoppingDeliveryType = contents
  },
  ShoppingDeliveryDate (state, contents) {
    state.ShoppingDeliveryDate = contents
  },
  */
  /*
  ShoppingDeliveryTime (state, contents) {
    state.ShoppingDeliveryTime = contents
  },
  */
  /*
  ShoppingFavorites (state, contents) {
    state.ShoppingFavorites = contents
  }
  ShoppingPamphlet (state, contents) {
    state.ShoppingPamphlet = contents
  }
  */
}

const actions = {
  LoadShoppingCart ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        commit('ShoppingCart', await APILib.getShoppingCart())
        commit('CartSelectableDeliveryDate', await APILib.getShoppingCartDeliveryDates())
        resolve()
      })().catch((e) => {
        reject(e)
      })
    })
  },
  UpdateShoppingCart ({ commit, dispatch, state }, contents) {
    return new Promise((resolve, reject) => {
      (async () => {
        commit('ShoppingCart', await APILib.putShoppingCart(contents))
        commit('CartSelectableDeliveryDate', await APILib.getShoppingCartDeliveryDates())
        resolve()
      })().catch((e) => {
        reject(e)
      })
    })
  },
  UpdateShoppingCartProducts ({ commit, dispatch, state }, contents) {
    return new Promise((resolve, reject) => {
      (async () => {
        commit('ShoppingCart', await APILib.putShoppingCartProducts(contents))
        resolve()
      })().catch((e) => {
        reject(e)
      })
    })
  },
  UpdateShoppingCartPamphlet ({ commit, dispatch, state }, contents) {
    return new Promise((resolve, reject) => {
      (async () => {
        commit('ShoppingCart', await APILib.putShoppingCartPamphlet(contents.map(PamphletID => { return { pamphlet_id: PamphletID } })))
        resolve()
      })().catch((e) => {
        reject(e)
      })
    })
  },
  SetShoppingCartProducts ({ commit, dispatch, state }, [ProductsClassID, Quantity]) {
    return new Promise((resolve, reject) => {
      (async () => {
        commit('ShoppingCart', await APILib.putShoppingCartProductsSet(process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_PRODUCTS, ProductsClassID, Quantity))
        resolve()
      })().catch((e) => {
        // お気に入り未登録時にエラーを発生させない
        resolve()
        // reject(e)
      })
    })
  },
  SetShoppingCartLimited ({ commit, dispatch, state }, [ProductsClassID, Quantity]) {
    return new Promise((resolve, reject) => {
      (async () => {
        commit('ShoppingCart', await APILib.putShoppingCartProductsSet(process.env.VUE_APP_ORDER_ITEM_TYPE_STATUS_LIMITED, ProductsClassID, Quantity))
        resolve()
      })().catch((e) => {
        // お気に入り未登録時にエラーを発生させない
        resolve()
        // reject(e)
      })
    })
  },
  Buy ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      (async () => {
        commit('SetComplete', await APILib.postShoppingCartComplete())
        resolve()
      })().catch((e) => {
        reject(e)
      })
    })
  }
  /*
  ShoppingAddress ({ commit, dispatch, state }, contents) {
    commit('ShoppingAddress', contents)
  },
  ShoppingDeliveryType ({ commit, dispatch, state }, contents) {
    commit('ShoppingDeliveryType', contents)
  },
  ShoppingDeliveryDate ({ commit, dispatch, state }, contents) {
    commit('ShoppingDeliveryDate', contents)
  },
  */
  /*
  ShoppingDeliveryTime ({ commit, dispatch, state }, contents) {
    commit('ShoppingDeliveryTime', contents)
  },
  ShoppingFavorites ({ commit, dispatch, state }, contents) {
    commit('ShoppingFavorites', contents)
  }
  */
  /*
  ShoppingPamphlet ({ commit, dispatch, state }, contents) {
    commit('ShoppingPamphlet', contents)
  }
  */
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
