<template>
<v-row align="center" justify="center">

  <v-col cols=12>
    <ComponentsLayoutHeading1 text="パスワード変更"></ComponentsLayoutHeading1>
  </v-col>

  <v-col cols=12>
    <v-row align="center" justify="center" class="mx-auto px-5" :style="{ maxWidth: '720px', paddingTop: '30px', paddingBottom: '50px' }" no-gutters>
      <v-col cols=12>
        <span>再設定するパスワードをご入力下さい</span>
      </v-col>
      <v-col cols="12" class="py-5">
        <ComponentsLayoutSection1>
          <v-row align="center" justify="center">
            <v-col cols=12>
              <ComponentsLayoutHeading3 text="変更後のパスワード" required></ComponentsLayoutHeading3>
            </v-col>
            <v-col cols="12">
              <ComponentsItemsTextFieldTypePassword
                name="password"
                :value.sync="password"
                :loading="isProcessLoading"
                autocomplete="current-password"
                ref="password"
              ></ComponentsItemsTextFieldTypePassword>
            </v-col>
            <v-col cols=12>
              <ComponentsLayoutHeading3 text="変更後のパスワード(確認)" required></ComponentsLayoutHeading3>
            </v-col>
            <v-col cols="12">
              <ComponentsItemsTextFieldTypePassword
                name="password"
                :value.sync="re_password"
                :loading="isProcessLoading"
                autocomplete="current-password"
                ref="re_password"
              ></ComponentsItemsTextFieldTypePassword>
            </v-col>
          </v-row>
        </ComponentsLayoutSection1>
      </v-col>
      <v-col cols="12" class="text-center py-5">
        <ComponentsItemsButton
          block
          text="パスワードを変更する"
          @click="ReSetPassword()"
        >
        </ComponentsItemsButton>
      </v-col>
    </v-row>
  </v-col>

</v-row>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutSection1 from '@/components/layout/Section1'
import ComponentsLayoutHeading1 from '@/components/layout/Heading1'
import ComponentsLayoutHeading3 from '@/components/layout/Heading3'

import ComponentsItemsTextFieldTypePassword from '@/components/items/TextFieldTypePassword'
import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'MyPageProfilePage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false,
    password: '',
    re_password: ''
  }),

  computed: {
    Users: {
      get () {
        return this.$store.getters['modStorage/Users']
      }
    }
  },

  mounted: function () {
    if (!this.isSignIn) {
      this.pageTransition('/login')
    }
  },

  methods: {
    ReSetPassword: function () {
      (async () => {
        let error = false
        const forms = [
          'password',
          're_password'
        ]
        for (const name of forms) {
          if (this.$refs[name]) {
            await this.$refs[name].confirm()
            if (this.$refs[name].error) {
              error = true
            }
          }
        }
        if (error) {
          return false
        }
        if (this.password !== this.re_password) {
          this.$confirm({ title: 'Error', message: 'パスワードが一致していません', button: { yes: 'OK' } })
          return false
        }
        this.isProcessLoading = true
        await APILib.putUsers(this.password)
        this.$confirm({ title: 'Success', message: 'パスワードを変更しました', button: { yes: 'OK' } })
        this.isProcessLoading = false
      })().catch((e) => {
        this.isProcessLoading = false
        this.$confirm({ title: 'Error', message: e, button: { yes: 'OK' } })
      })
    }
  },

  components: {
    ComponentsLayoutSection1,
    ComponentsLayoutHeading1,
    ComponentsLayoutHeading3,
    ComponentsItemsTextFieldTypePassword,
    ComponentsItemsButton
  }
}
</script>
