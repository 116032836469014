<template>
<ComponentsLayoutPage1>
  <v-row align="center" justify="center" class="mx-auto" :style="{ paddingTop: '50px', paddingBottom: '100px' }">

    <v-col cols=12 v-for="(item, index) of forms" :key="index">
      <v-row align="start" justify="center">
        <v-col :cols="(windowMiddleSize) ? 12: 4">
          <span v-text="item.title"></span>
        </v-col>
        <v-col :cols="(windowMiddleSize) ? 12: 8">
          <v-row >
            <v-col cols=12 v-for="cols of item.columns" :key="cols.name">
              <v-row align="center" justify="center" no-gutters>
                <v-col cols=12 class="pb-3" v-if="isUndefined(cols.subtitle) === false">
                  <span v-text="cols.subtitle"></span>
                </v-col>
                <v-col cols=12 class="pb-3" v-if="isUndefined(cols.description) === false">
                  <span v-html="cols.description"></span>
                </v-col>
                <v-col cols=10 v-if="cols.type === 'text'">
                  <ComponentsLayoutSection1>
                    <span v-text="getValue(cols.name, data)"></span>
                  </ComponentsLayoutSection1>
                </v-col>
                <v-col cols=10 v-if="cols.type === 'text_area'">
                  <ComponentsLayoutSection1>
                    <span v-text="getValue(cols.name, data)"></span>
                  </ComponentsLayoutSection1>
                </v-col>
                <v-col cols=10 v-if="cols.type === 'select' && cols.multiple === true">
                  <ComponentsLayoutSection1>
                    <v-row no-gutters>
                      <v-col cols=12 v-for="(val, index) of getValue(cols.name, data) || []" :key="index">
                        <span v-text="val"></span>
                      </v-col>
                    </v-row>
                  </ComponentsLayoutSection1>
                </v-col>
                <v-col cols=10 v-if="cols.type === 'select' && cols.multiple === false">
                  <ComponentsLayoutSection1>
                    <span v-text="getValue(cols.name, data)"></span>
                  </ComponentsLayoutSection1>
                </v-col>
                <v-col cols=10 v-if="cols.type === 'list'">
                  <ComponentsLayoutSection1>
                    <span v-text="getValue(cols.name, data)"></span>
                  </ComponentsLayoutSection1>
                </v-col>
                <v-col cols=2>
                  <v-chip class="ml-3" color="error" x-small v-if="(cols.require)">必須</v-chip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols=12 v-if="forms.length > (index + 1)">
          <v-divider></v-divider>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols=12 :style="{ paddingTop: '50px' }">
      <v-row align="center" justify="center">
        <v-col :cols="(windowSpSize) ? 12 : (windowMiddleSize) ? 6 : 4">
          <ComponentsItemsButton
            block
            :loading="isProcessLoading"
            text="お申込みを行う"
            @click="goNext()"
          >
          </ComponentsItemsButton>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col :cols="(windowSpSize) ? 12 : (windowMiddleSize) ? 6 : 4">
          <ComponentsItemsButton
            block
            outlined
            :loading="isProcessLoading"
            text="戻る"
            @click="pageTransition('/entry/apply')"
          >
          </ComponentsItemsButton>
        </v-col>
      </v-row>
    </v-col>

  </v-row>
</ComponentsLayoutPage1>
</template>
<script>
import MixinLib from '@/libs/MixinLib'
import APILib from '@/libs/APILib'

import ComponentsLayoutPage1 from '@/components/layout/Page1'
import ComponentsLayoutSection1 from '@/components/layout/Section1'

import ComponentsItemsButton from '@/components/items/Button'

export default {
  name: 'ConsoleEntryConfirmPage',

  mixins: [MixinLib],

  data: () => ({
    isProcessLoading: false
  }),

  watch: {
    isSignIn () {
      if (this.isSignIn) {
        this.pageTransition('/')
      }
    }
  },

  mounted () {
    if (this.isSignIn) {
      this.pageTransition('/')
    }
  },

  computed: {
    forms: {
      get () {
        return this.$store.getters['modStorage/EntryFormItems']
      }
    },
    data: {
      get () {
        return this.$store.getters['modSession/entryData']
      },
      set (v) {
        this.$store.dispatch('modSession/entryData', v)
      }
    }
  },

  methods: {
    goNext () {
      (async () => {
        this.isProcessLoading = true
        // console.log(this.data)
        await APILib.postUsersApply(this.data)
        this.pageTransition('/entry/complete')
        this.isProcessLoading = false
      })().catch((e) => {
        this.$confirm({ title: 'Error', message: e.message, button: { yes: 'OK' } })
        this.isProcessLoading = false
      })
    }
  },

  components: {
    ComponentsLayoutPage1,
    ComponentsLayoutSection1,
    ComponentsItemsButton
  }
}
</script>
